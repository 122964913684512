import { homeApi } from '../lib/request';
import { AxiosResponse } from 'axios';

export interface ICouponPopUp {
    couponInfo: {
        idx: number;
        condition: number;
        couponIdx: number;
        endDate: number[];
        name: string;
        type: number;
        price: number;
        discountRate: number;
        discountType: number;
    };
    isCouponView: boolean;
}

export function getHomeData(platform: string) {
    return homeApi
        .get(`/home/v1/component/${platform}`)
        .then((res) => {
            return res.data;
        })
        .catch((_err) => []);
}

export interface PromotionBanner {
    bannerImageUrl: string;
    commercePromotionIdx: number | null;
    imageUrl: string;
    itemIdx: number;
    itemType: string;
    landingUrl: string;
    name: string;
}

export function getHomeBannerList() {
    return homeApi
        .get('/home/v1/promotionBanner/pc')
        .then((res) => {
            return res.data;
        })
        .catch((_err) => {});
}

export function getHomeSalesList() {
    return homeApi
        .get('/home/v1/todaySale/pc')
        .then((res) => {
            return res.data;
        })
        .catch((_err) => []);
}

export function getHomeYoutubeList() {
    return homeApi
        .get('/home/v1/youtube')
        .then((res) => {
            return res.data;
        })
        .catch((_err) => []);
}

export function getHomeYoutubeDetail(idx: number | string) {
    return homeApi
        .get(`/home/v1/youtube/${idx}`)
        .then((res) => {
            return res.data;
        })
        .catch((_err) => []);
}

export function getHomePromotion(idx: number) {
    return homeApi
        .get(`/home/v1/promotions/${idx}`)
        .then((res) => {
            return res.data;
        })
        .catch((_err) => {});
}

export function getHomePopupStore(idx: number) {
    return homeApi
        .get(`/home/v1/popupstore/${idx}`)
        .then((res) => {
            return res.data;
        })
        .catch((_err) => {});
}

export function getHomePhoto() {
    return homeApi
        .get('/home/v1/photo')
        .then((res) => {
            return res.data;
        })
        .catch((_err) => []);
}

export function getHomeStory() {
    return homeApi
        .get('/home/v1/article/pc')
        .then((res) => {
            return res.data;
        })
        .catch((_err) => {});
}

export function getAppPopup() {
    return homeApi
        .get('/home/v1/app/popup')
        .then((res) => {
            return res.data;
        })
        .catch((_err) => {});
}

export async function getAppCouponPopUp() {
    try {
        const response: AxiosResponse<ICouponPopUp> = await homeApi.get('/home/v1/couponPopUp');
        return response.data;
    } catch (e) {
        return false;
    }
}

export async function getNoticeList() {
    return homeApi
        .get('/home/v1/notice')
        .then((res) => {
            return res.data;
        })
        .catch((_err) => []);
}
