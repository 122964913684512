import styled from 'styled-components';
import COLOR from '../../lib/styles/colors';
import { Body2 } from './Text';
import Button from './Button';

interface IFlexBoxProps {
    display?: string;
    position?: string;
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
    zIndex?: number;
    width?: number | string;
    height?: number | string;
    minWidth?: number | string;
    maxWidth?: number | string;
    minHeight?: number | string;
    maxHeight?: number | string;
    margin?: number | string;
    padding?: number | string;
    border?: string;
    borderRadius?: string;
    borderBottom?: string;
    boxShadow?: string;
    direction?: string;
    flex?: number | string;
    wrap?: string;
    justify?: string;
    align?: string;
    grow?: number | string;
    textAlign?: string;
    cursor?: string;
    background?: string;
    overflow?: string;
}

export const FlexBox = styled.div<IFlexBoxProps>`
    display: ${({ display }) => display || `flex`};
    ${({ position }) => position && `position: ${position};`}
    ${({ top }) => top && `top: ${top};`}
    ${({ left }) => left && `left: ${left};`}
    ${({ right }) => right && `right: ${right};`}
    ${({ bottom }) => bottom && `bottom: ${bottom};`}
    ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
    ${({ width }) => width && `width: ${width};`}
    ${({ height }) => height && `height: ${height};`}
    ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
    ${({ margin }) => margin && `margin: ${margin};`}
    ${({ padding }) => padding && `padding: ${padding};`}
    ${({ border }) => border && `border: ${border};`}
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}
    ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom};`}
    ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow};`}
    ${({ flex }) => flex && `flex: ${flex};`}
    ${({ direction }) => direction && `flex-direction: ${direction};`}
    ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
    ${({ justify }) => justify && `justify-content: ${justify};`}
    ${({ align }) => align && `align-items: ${align};`}
    ${({ grow }) => grow && `flex-grow: ${grow};`}
    ${({ cursor }) => cursor && `cursor: ${cursor};`}
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    ${({ background }) => background && `background: ${background};`}
    ${({ overflow }) => overflow && `overflow: ${overflow};`}
`;

export const Row = styled(FlexBox)`
    flex-direction: row;
`;

export const Col = styled(FlexBox)`
    flex-direction: column;
`;

const StyledTabBar = styled(Row)`
    z-index: 100;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
`;

const CheckCircle = styled.div`
    position: absolute;
    right: -8px;
    width: 4px;
    height: 4px;
    background-color: ${COLOR.CORAL6};
    border-radius: 100%;
`;

export function TabBar({
    labelList,
    activeIdx,
    checkedIdxList,
    onClick,
}: {
    labelList: string[];
    activeIdx: number;
    checkedIdxList: number[];
    onClick: (idx: number) => void;
}) {
    return (
        <StyledTabBar>
            {labelList.map((label, idx) => {
                const active: boolean = activeIdx === idx;
                return (
                    <Button
                        key={`tab-bar-label-${idx}`}
                        width={`calc(100% / ${labelList.length})`}
                        padding={'10px 4px'}
                        focusBackground={COLOR.GREY1}
                        borderRadius={0}
                        borderBottom={active ? `2px solid ${COLOR.GREY10}` : 'none'}
                        onClick={() => onClick(idx)}
                    >
                        <Body2
                            position={'relative'}
                            width={'fit-content'}
                            color={active ? COLOR.GREY10 : COLOR.GREY4}
                            fontWeight={'medium'}
                        >
                            {checkedIdxList.includes(idx) && <CheckCircle />}
                            {label}
                        </Body2>
                    </Button>
                );
            })}
        </StyledTabBar>
    );
}
