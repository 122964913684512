import { ReactNode, createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import { Text, Body2, Caption1, Col, Display5, Body1, Row, Heading1 } from '../../layout';
import { COLOR_V4 } from '../../../lib/styles/colors';
import moment from 'moment';
import { StarIcon } from '../../layout/StarList';
import Link from 'next/link';
import { TProductTag } from '../../../api/commerce';
import { ViewType } from './ProductCardXLarge';

export const PRODUCT_HORIZONTAL_GAP = 20;
export const PRODUCT_VERTICAL_GAP = 40;
export const PRODUCT_GAP = `${PRODUCT_VERTICAL_GAP}px ${PRODUCT_HORIZONTAL_GAP}px`;

interface ProductContextType {
    isHover: boolean;
    viewType?: ViewType;
}
const ProductContext = createContext<ProductContextType>({ isHover: false });

interface ProductRootProps {
    href: string;
    useNextLink?: boolean;
    viewType?: ViewType;
    children?: React.ReactNode | React.ReactNode[];
    handleClickProductCard?: () => void;
}

function ProductRoot(props: ProductRootProps) {
    const { href, viewType, children, useNextLink = true, handleClickProductCard } = props;
    const [isHover, setIsHover] = useState(false);
    return (
        <ProductContext.Provider value={{ isHover, viewType }}>
            {useNextLink ? (
                <Link href={href}>
                    <a
                        onClick={() => {
                            handleClickProductCard && handleClickProductCard();
                        }}
                        onMouseOver={() => setIsHover(true)}
                        onMouseOut={() => setIsHover(false)}
                    >
                        {children}
                    </a>
                </Link>
            ) : (
                <a
                    href={href}
                    onClick={() => {
                        handleClickProductCard && handleClickProductCard();
                    }}
                    onMouseOver={() => setIsHover(true)}
                    onMouseOut={() => setIsHover(false)}
                >
                    {children}
                </a>
            )}
        </ProductContext.Provider>
    );
}

/* 상품 사진 */
interface ProductImageProps {
    src: string;
    width?: string;
    radius?: string;
    isSoldout?: boolean;
    isHotdeal?: boolean;
    rank?: number;
    useRank?: boolean;
    useTimer?: boolean;
    startDateTime?: string;
    endDateTime?: string;
    customContent?: string;
}

const ProductImageStyle = styled.div<{
    width?: string;
    radius?: string;
}>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => (width ? width : '100%')};
    border-radius: ${({ radius }) => (radius ? radius : '4px')};
    overflow: hidden;

    aspect-ratio: 1/1;

    img {
        object-fit: cover;
        transition: all 0.2s ease-in-out;

        &:hover {
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
        }
    }
`;

const ImageSoldout = styled.div<{ radius?: string; customContent?: string }>`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${COLOR_V4.DIM1};
    border-radius: ${({ radius }) => (radius ? radius : '4px')};
    text-align: center;

    ${({ customContent }) =>
        customContent
            ? customContent
            : `&:before {
                    content: '현재 품절인';
                    white-space: pre;
                }
                &:after {
                    content: '상품이에요';
                    white-space: pre;
                }
                &::before,
                &::after {
                    color: ${COLOR_V4.WHITE};
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 26px;
                }`};
`;

const ImageHover = styled.div<{ isHover: boolean }>`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    ${(props) => (props.isHover ? 'transform: scale(1.15)' : '')};
    transition: all ease 0.15s 0s;
`;

const ImageCover = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.02);
`;

const UseTimer = styled.div`
    position: absolute;
    z-index: 9;
    left: 6px;
    bottom: 6px;
    height: 28px;
    padding: 6px 8px;
    color: ${COLOR_V4.WHITE};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 15px;
    border: solid 1px ${COLOR_V4.GRAY200};
    background: ${COLOR_V4.DIM1};
    span {
        font-weight: bold;
    }
`;

export const Hotdeal = styled.span`
    position: absolute;
    z-index: 9;
    top: 6px;
    left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 29px;
    background: ${COLOR_V4.PINK500};
    border-radius: 4px;
`;

const Rank = styled.span`
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: ${COLOR_V4.DIM1};
    border-radius: 0 4px 0 0;
`;

function ProductImage(props: ProductImageProps) {
    const {
        src,
        width,
        radius,
        isSoldout,
        isHotdeal,
        rank,
        useRank = false,
        useTimer,
        startDateTime,
        endDateTime,
        customContent,
    } = props;
    const { isHover, viewType } = useContext(ProductContext);

    const format = 'YYYY-MM-DD HH:mm:ss';

    const now = moment();
    const start = moment(startDateTime, format);
    const end = moment(endDateTime, format);
    const diffSeconds = moment.duration(end.diff(now)).asSeconds();
    const diffBase = diffSeconds < 0 ? 0 : diffSeconds;

    if (now.diff(start) < 0 || now.diff(end) > 0) {
        return null;
    }

    let timerString = '';
    if (useTimer) {
        if (diffBase === 0) {
            timerString = '마감';
        } else if (
            now.year() === start.year() &&
            now.month() === start.month() &&
            now.date() === start.date()
        ) {
            timerString = '오늘오픈';
        } else if (diffBase < 60) {
            timerString = `${Math.floor(diffBase)}초`;
        } else if (diffBase < 3600) {
            timerString = `${Math.floor(diffBase / 60)}분`;
        } else if (diffBase < 86400) {
            timerString = `${Math.floor(diffBase / 3600)}시간`;
        } else {
            timerString = `${Math.floor(diffBase / 86400)}일`;
        }
    }

    return (
        <ProductImageStyle
            width={width}
            radius={viewType === ViewType.GRID ? '4px 4px 0 0' : radius}
        >
            {isSoldout && <ImageSoldout radius={radius} customContent={customContent} />}
            {!isSoldout && timerString && (
                <UseTimer>
                    <span>{timerString}</span>
                    {['마감', '오늘오픈'].includes(timerString) ? null : (
                        <div style={{ display: 'inline-block', marginLeft: 3 }}>남음</div>
                    )}
                </UseTimer>
            )}
            {isHotdeal && (
                <Hotdeal>
                    <Body2 fontWeight={'bold'} color={COLOR_V4.WHITE}>
                        집꾸핫딜
                    </Body2>
                </Hotdeal>
            )}
            {useRank && rank && (
                <Rank>
                    <Body1 fontWeight={'bold'} color={COLOR_V4.WHITE}>
                        {rank}
                    </Body1>
                </Rank>
            )}
            <ImageHover isHover={isHover}>
                <ImageCover />
                <img src={src} alt={'product-thumbnail'} width={'100%'} />
            </ImageHover>
        </ProductImageStyle>
    );
}

/* 브랜드명 */
interface ProductBrandNameProps {
    children: string | ReactNode;
    margin?: string;
}

function ProductBrandName(props: ProductBrandNameProps) {
    const { children, margin = '8px 0 0' } = props;
    return (
        <Body2 margin={margin} color={COLOR_V4.GRAY400} fontWeight={'medium'}>
            {children}
        </Body2>
    );
}

/*상품명*/
interface ProductNameProps {
    children: string | ReactNode;
    clamp?: number;
}

function ProductName(props: ProductNameProps) {
    const { children, clamp = 2 } = props;
    return (
        <Col>
            <Body1 color={COLOR_V4.GRAY900} fontWeight={'regular'} hideText={true} clamp={clamp}>
                {children}
            </Body1>
        </Col>
    );
}

/*할인 후 상품 가격*/
interface ProductPriceDiscountProps {
    discount: number;
    couponPrice: number | null;
    priceDiscount: number;
    priceOriginal?: number;
}

function ProductPriceDiscount(props: ProductPriceDiscountProps) {
    const { discount, couponPrice, priceDiscount, priceOriginal = 0 } = props;

    const isCouponExist = couponPrice !== null && couponPrice > 0;
    const price = isCouponExist ? couponPrice : priceDiscount;

    return (
        <Col margin={'4px 0 0'}>
            {priceOriginal > 0 && (
                <Text
                    margin={'0 0 2px'}
                    fontSize={'16px'}
                    lineHeight={'16px'}
                    fontWeight={'regular'}
                    color={COLOR_V4.GRAY300}
                >
                    <del>{priceOriginal.toLocaleString()}</del>
                </Text>
            )}

            <Row align={'center'}>
                {discount > 0 && !couponPrice && (
                    <Display5 margin={'0 4px 0 0'} color={COLOR_V4.PINK500} fontWeight={'bold'}>
                        {`${discount}%`}
                    </Display5>
                )}
                {isCouponExist && (
                    <Heading1 color={COLOR_V4.PINK500} fontWeight={'bold'} margin={'0 4px 0 0'}>
                        쿠폰가
                    </Heading1>
                )}
                <Display5 color={COLOR_V4.GRAY900} fontWeight={'bold'}>
                    {price.toLocaleString()}
                </Display5>
            </Row>
        </Col>
    );
}

/*상품 별점 및 리뷰*/
interface ProductStarProps {
    reviewScore: number;
    reviewCount: number;
    displayReviewScore: string;
    margin?: string;
}

function ProductStar(props: ProductStarProps) {
    const { reviewScore, reviewCount, displayReviewScore, margin = '4px 0 0' } = props;
    return reviewScore > 0 || reviewCount > 0 ? (
        <Row margin={margin}>
            <Row align={'center'}>
                <StarIcon width={'14px'} fill={'#ffc800'} />
                <Body2 margin={'0 2px 0 4px'} color={COLOR_V4.GRAY900} fontWeight={'bold'}>
                    {displayReviewScore}
                </Body2>
                <Body2 color={COLOR_V4.GRAY400} fontWeight={'bold'}>
                    ({reviewCount.toLocaleString()})
                </Body2>
            </Row>
        </Row>
    ) : (
        <></>
    );
}

/* 상품 태그 */
interface ProductTagListProps {
    margin?: string;
    tagList: TProductTag[] | [];
}
const TagStyle = styled.div<{ color: string; backgroundColor: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 4px 5px;
    margin: 0 6px 0 0;
    color: ${(props) => props.color};
    background: ${(props) => props.backgroundColor};
`;

function ProductTagList(props: ProductTagListProps) {
    const { tagList, margin = '6px 0 0' } = props;
    return tagList.length > 0 ? (
        <Row margin={margin}>
            {tagList.map((tag, index) => (
                <TagStyle
                    color={tag.color || COLOR_V4.GRAY500}
                    backgroundColor={tag.backgroundColor || COLOR_V4.GRAY100}
                    key={index}
                >
                    <Caption1 fontWeight={'medium'}>{tag.tag}</Caption1>
                </TagStyle>
            ))}
        </Row>
    ) : (
        <></>
    );
}

const Root = ProductRoot;
const Image = ProductImage;
const BrandName = ProductBrandName;
const Name = ProductName;
const PriceDiscount = ProductPriceDiscount;
const Star = ProductStar;
const TagList = ProductTagList;

export { Root, Image, BrandName, Name, PriceDiscount, Star, TagList };
