import styled from 'styled-components';

interface ISpacerProps {
    height?: string,
    padding?: string,
    margin?: string,
    background?: string,
    overscroll?: string,
}

export const Spacer = styled.div<ISpacerProps>`
    width: 100%;
    height: ${({ height }) => height ? `${height}` : '10px'};
    ${({ margin }) => margin ? `margin: ${margin};` : ''}
    ${({ padding }) => padding ? `padding: ${padding};` : ''}
    background: ${({ background }) => background ? `${background}` : '#f6f7f9'};
    ${({ overscroll }) => overscroll ? `overscroll-behavior: ${overscroll};` : ''}
`;
