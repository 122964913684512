import React from 'react';
import { IconProps } from '.';

interface PhotoProps extends IconProps {}

const Photo = ({ color, width, height, style, ...args }: PhotoProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <rect
                x="3.32153"
                y="3.32129"
                width="12"
                height="12"
                rx="1.25"
                stroke="white"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path
                d="M8.05444 7.02002C8.05444 7.67586 7.52278 8.20752 6.86694 8.20752C6.21111 8.20752 5.67944 7.67586 5.67944 7.02002C5.67944 6.36418 6.21111 5.83252 6.86694 5.83252C7.52278 5.83252 8.05444 6.36418 8.05444 7.02002Z"
                stroke="white"
            />
            <path
                d="M15.151 11.1624L12.0828 8.09424L4.5 15.1072"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Photo;
