import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Body3, Body2, Body4 } from '../layout';
import { COLOR_V4 } from '../../lib/styles/colors';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Slider = styled.div`
    position: relative;
    width: 320px;
`;

const SliderTrack = styled.div`
    position: absolute;
    border-radius: 4px;
    height: 5px;
    background: ${COLOR_V4.GRAY200};
    width: 100%;
    z-index: 1;
`;

const SliderRange = styled.div`
    position: absolute;
    border-radius: 4px;
    height: 5px;
    background: ${COLOR_V4.GRAY600};
    z-index: 2;
`;

const LeftValue = styled.div`
    position: absolute;
    margin-top: 15px;
    left: 6px;
`;

const RightValue = styled.div`
    position: absolute;
    margin-top: 15px;
    right: -4px;
`;

const ThumbLeft = styled.input`
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 320px;
    outline: none;

    &::-webkit-slider-thumb {
        position: relative;
        cursor: pointer;
        pointer-events: all;
        appearance: none;
        height: 18px;
        width: 18px;
        background: ${COLOR_V4.GRAY600};
        border-radius: 50%;
        margin-top: 4px;
        border: 0;
    }

    &::-moz-range-thumb {
        position: relative;
        cursor: pointer;
        appearance: none;
        height: 18px;
        width: 18px;
        background: ${COLOR_V4.GRAY600};
        border-radius: 50%;
        margin-top: 4px;
        border: 0;
    }
    z-index: 3;
`;

const ThumbRight = styled.input`
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 320px;
    outline: none;

    &::-webkit-slider-thumb {
        position: relative;
        cursor: pointer;
        pointer-events: all;
        appearance: none;
        height: 18px;
        width: 18px;
        background: ${COLOR_V4.GRAY600};
        border-radius: 50%;
        margin-top: 4px;
        border: 0;
    }

    &::-moz-range-thumb {
        position: relative;
        cursor: pointer;
        appearance: none;
        height: 18px;
        width: 18px;
        background: ${COLOR_V4.GRAY600};
        border-radius: 50%;
        margin-top: 4px;
        border: 0;
    }
    z-index: 4;
`;

interface MultiRangeSliderProps {
    min: number;
    max: number;
    minNow: number;
    maxNow: number;
    step: number;
    onChange: (props: { min: number; max: number }) => void;
}

export const MultiRangeSlider = ({
    min,
    max,
    minNow,
    maxNow,
    step,
    onChange,
}: MultiRangeSliderProps) => {
    const [minVal, setMinVal] = useState(minNow);
    const [maxVal, setMaxVal] = useState(maxNow);
    const minValRef = useRef(minNow);
    const maxValRef = useRef(maxNow);
    const range = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const minPercent = Math.round(((minValRef.current - min) / (max - min)) * 100);
        const maxPercent = Math.round(((maxValRef.current - min) / (max - min)) * 100);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [min, max, range]);

    return (
        <Col>
            <Row margin={'0 0 34px 0'} justify={'space-between'}>
                <Body3 fontWeight={'medium'} color={COLOR_V4.GRAY900}>
                    가격대
                </Body3>
                <Body2 fontWeight={'bold'} color={COLOR_V4.GRAY900}>
                    {minVal.toLocaleString()}~{maxVal.toLocaleString()}원
                </Body2>
            </Row>
            <Container>
                <ThumbLeft
                    type={'range'}
                    min={min}
                    max={max}
                    step={step}
                    value={minVal}
                    onChange={(event) => {
                        const value = Math.min(Number(event.target.value), maxVal - 10000);
                        setMinVal(value);
                        minValRef.current = value;

                        const minPercent = Math.round(((value - min) / (max - min)) * 100);
                        const maxPercent = Math.round(
                            ((maxValRef.current - min) / (max - min)) * 100,
                        );

                        if (range.current) {
                            range.current.style.left = `${minPercent}%`;
                            range.current.style.width = `${maxPercent - minPercent}%`;
                        }

                        onChange({ min: value, max: maxValRef.current });
                    }}
                />
                <ThumbRight
                    type={'range'}
                    min={min}
                    max={max}
                    step={step}
                    value={maxVal}
                    onChange={(event) => {
                        const value = Math.max(Number(event.target.value), minVal + 10000);
                        setMaxVal(value);
                        maxValRef.current = value;

                        const minPercent = Math.round(
                            ((minValRef.current - min) / (max - min)) * 100,
                        );
                        const maxPercent = Math.round(((value - min) / (max - min)) * 100);

                        if (range.current) {
                            range.current.style.width = `${maxPercent - minPercent}%`;
                        }

                        onChange({ min: minValRef.current, max: value });
                    }}
                />

                <Slider>
                    <SliderTrack />
                    <SliderRange ref={range} />
                    <LeftValue>
                        <Body4 fontWeight={'medium'} color={COLOR_V4.GRAY300}>
                            {minVal.toLocaleString()}원
                        </Body4>
                    </LeftValue>
                    <RightValue>
                        <Body4 fontWeight={'medium'} color={COLOR_V4.GRAY300}>
                            {maxVal.toLocaleString()}원
                        </Body4>
                    </RightValue>
                </Slider>
            </Container>
        </Col>
    );
};
