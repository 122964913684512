import _ from 'lodash';
import { Button, Text } from '../layout';

interface LoginButtonProps {
    title?: string;
    width: string;
    height: string;
    margin?: string;
    background: string;
    borderRadius: string;
    onClick: (e: any) => void;
    color: string;
    fontWeight: string;
    fontSize: string;
    lineHeight: string;
}

export const LoginButton = (props: LoginButtonProps) => {
    return (
        <Button
            width={props.width}
            height={props.height}
            margin={props.margin}
            background={props.background}
            borderRadius={props.borderRadius}
            onClick={props.onClick}
        >
            <Text
                fontWeight={props.fontWeight}
                fontSize={props.fontSize}
                lineHeight={props.lineHeight}
                color={props.color}
            >
                {props.title}
            </Text>
        </Button>
    );
};
