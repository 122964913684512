import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import _ from 'lodash';

export enum ReportReason {
    INSULT = 1,
    UNRELATED = 2,
    ADVERTISEMENT = 3,
    PORN = 4,
    INFRINGEMENT_OF_COPYRIGHT = 5,
    WRONG_CONTENTS = 6,
}
export enum CommentTypeEnum {
    star = 1, // 매거진
    story = 3, // 커뮤니티
    promotion = 5, // 기획전
}
export interface IComment {
    idx: number;
    type: CommentTypeEnum;
    memberIdx: number;
    linkIdx: number;
    likeCount: number;
    content: string;
    createdDateTime: string;
    name: string;
    nickname: string;
    recentProfile: string;
    website: string;
    recentShout: string;
    subCommentCount: number;
    isLike: boolean;
    subCommentList?: IComment[];
    isOpen?: boolean;
    isEdit?: boolean;
}
export interface ICommentState {
    commentCount: number;
    commentList: IComment[];
}

const initialState: ICommentState = {
    commentCount: 0,
    commentList: [],
};

const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
        initCommentCount: (state: ICommentState, action) => {
            state.commentCount = action.payload;
        },
        updateCommentList: (state: ICommentState, action) => {
            state.commentList = action.payload;
        },
        shiftCommentList: (state: ICommentState, action) => {
            // 새 댓글 등록

            const { commentList } = state;
            const newList: IComment[] = _.differenceBy(
                action.payload as IComment[],
                commentList,
                'idx',
            );
            state.commentList.unshift(...newList);
            state.commentCount += newList.length;
        },
        shiftSubCommentList: (state: ICommentState, action) => {
            // 새 대댓글 등록

            const { commentList } = state;
            const { list, commentIdx } = action.payload;
            const comment = _.find(commentList, (it) => it.idx === commentIdx) as IComment;
            if (!comment) {
                return;
            }

            const newList: IComment[] = _.differenceBy(
                list as IComment[],
                comment.subCommentList || [],
                'idx',
            );
            if (comment.subCommentList) {
                comment.subCommentList.unshift(...newList);
            } else {
                comment.subCommentList = list;
            }
            comment.subCommentCount += newList.length;
            state.commentCount += newList.length;
        },
        modifyComment: (state: ICommentState, action) => {
            const { commentList } = state;
            const { commentIdx, subCommentIdx, comment: content } = action.payload;
            const comment = _.find(commentList, (it) => it.idx === commentIdx);
            if (!comment) {
                return;
            }

            if (subCommentIdx) {
                const subComment = _.find(comment.subCommentList, (it) => it.idx === subCommentIdx);
                if (!subComment) {
                    return;
                }
                subComment.content = content as string;
            } else {
                comment.content = content;
            }
        },
        deleteComment: (state: ICommentState, action) => {
            const { commentList } = state;
            const { commentIdx, subCommentIdx } = action.payload;
            if (subCommentIdx) {
                const comment = _.find(commentList, (it) => it.idx === commentIdx);
                if (!comment || !comment.subCommentList) {
                    return;
                }

                _.remove(comment.subCommentList, (it) => it.idx === subCommentIdx);
                state.commentCount -= 1;
                comment.subCommentCount -= 1;
            } else {
                const comment = _.remove(commentList, (it) => it.idx === commentIdx);

                if (comment.length === 1) {
                    state.commentCount -= 1 + comment[0].subCommentCount;
                }
            }
        },
        likeComment: (state: ICommentState, action) => {
            const { commentList } = state;
            const { commentIdx, subCommentIdx } = action.payload;
            const comment = _.find(commentList, (it) => it.idx === commentIdx);
            if (!comment) {
                return;
            }

            const onLike = (it: IComment) => {
                if (it.isLike) {
                    it.isLike = false;
                    it.likeCount -= 1;
                } else {
                    it.isLike = true;
                    it.likeCount += 1;
                }
            };
            if (subCommentIdx) {
                const subComment = _.find(comment.subCommentList, (it) => it.idx === subCommentIdx);
                if (!subComment) {
                    return;
                }
                onLike(subComment);
            } else {
                onLike(comment);
            }
        },
        pushCommentList: (state: ICommentState, action) => {
            const { commentList } = state;
            const { list } = action.payload;
            state.commentList = _.concat(commentList, list);
        },
        pushSubCommentList: (state: ICommentState, action) => {
            const { commentList } = state;
            const { list, commentIdx } = action.payload;
            const comment = _.find(commentList, (it) => it.idx === commentIdx);
            if (!comment || !comment.subCommentList) {
                return;
            }
            comment.subCommentList.push(...list);
            comment.subCommentCount += 1;
        },
        toggleSubCommentList: (state: ICommentState, action) => {
            const { commentList } = state;
            const { list, commentIdx } = action.payload;
            const comment = _.find(commentList, (it) => it.idx === commentIdx);
            if (!comment) {
                return;
            }
            comment.isOpen = !comment.isOpen;
            if (list) {
                comment.subCommentList = list;
            }
        },
        editModeComment: (state: ICommentState, action) => {
            const { commentList } = state;
            const { commentIdx, subCommentIdx } = action.payload;
            const comment = _.find(commentList, (it) => it.idx === commentIdx);
            if (!comment) {
                return;
            }
            if (subCommentIdx) {
                const subComment = _.find(comment.subCommentList, (it) => it.idx === subCommentIdx);
                if (!subComment) {
                    return;
                }
                subComment.isEdit = !subComment.isEdit;
            } else {
                comment.isEdit = !comment.isEdit;
            }
        },
    },
    extraReducers: {
        [HYDRATE]: (state: ICommentState, action) => {
            return {
                ...state,
                ...action.payload.comment,
            };
        },
    },
});

export const commentActions = commentSlice.actions;
export default commentSlice;
