import React from 'react';
import { IconProps } from '.';

interface SpreadProps extends IconProps {}

const Spread = ({ color, width, height, style, ...args }: SpreadProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            width="9"
            height="6"
            viewBox="0 0 9 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <path
                d="M8.01855 1L4.67048 5.01769C4.5911 5.11295 4.44505 5.11376 4.36462 5.01938L1.01855 1.09333"
                stroke="#535B63"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default Spread;
