import { Body2, Col, Display5, Row } from '../../../../components/layout';
import COLOR, { COLOR_V4 } from '../../../styles/colors';
import { AskCS } from '../../../../components/signup';
import styled from 'styled-components';
import Image from 'next/image';
import Amplitude from 'amplitude-js';
import { useEffect } from 'react';
import { LoginModal } from '.';
import useChannelTalk from '../../../../lib/hooks/useChannelTalk';

interface InfoModalProps {
    close: () => void;
}

const TopFixed = styled.div`
    position: fixed;
    top: 16px;
    right: 16px;
    height: 24px;
    width: 24px;
    text-align: center;
    z-index: 30000;
`;

const Stress = styled.span`
    box-shadow: inset 0 -10px 0 rgba(169, 169, 240, 0.2);
    font-weight: 700;
`;

const CloseButton = styled.button``;

export default function InfoModal(props: InfoModalProps) {
    const { openChannelTalk } = useChannelTalk();

    useEffect(() => {
        Amplitude.getInstance().logEvent('Popup', {
            Title: '계정 유의사항',
            Script: `회원님과 동일한 휴대폰번호로 가입한 계정을 찾는 과정에서, 동일한
            휴대폰번호를 이전에 사용했던 다른 회원님의 계정도 표시될 수 있습니다. 계정 정보는 모두 암호화 처리 되어있고, 계정 소유자 본인만 로그인할 수
            있습니다. 계정 소유자가 아닌 경우 로그인할 수 없습니다.
            회원님의 계정이 아닌 경우 회원가입해 주시기 바랍니다. 기타 문의사항은 고객센터로 문의주시기 바랍니다.`,
        });
    }, []);

    return (
        <LoginModal {...props} width={'429px'} height={`378px`}>
            <TopFixed onClick={() => props.close()}>
                <CloseButton>
                    <Image
                        src={`//cdn.ggumim.co.kr/storage/20220324133254mUujKE3TL6.png`}
                        unoptimized
                        width={'24px'}
                        height={'24px'}
                    />
                </CloseButton>
            </TopFixed>
            <Col padding="40px">
                <Display5 fontWeight={'bold'} color={COLOR_V4.GRAY900}>
                    계정 유의사항
                </Display5>

                <Row width={'100%'} margin={'24px 0 0 0'}>
                    <Body2 margin={'0 8px 0 0'}>•</Body2>
                    <Body2 fontWeight={'regular'} color={COLOR.GREY10}>
                        회원님과 동일한 휴대폰번호로 가입한 계정을 찾는 과정에서, 동일한
                        휴대폰번호를 이전에 사용했던 다른 회원님의 계정도 표시될 수 있습니다.
                    </Body2>
                </Row>
                <Row width={'100%'} margin={'12px 0 0 0'}>
                    <Body2 margin={'0 8px 0 0'}>•</Body2>
                    <Body2 fontWeight={'regular'} color={COLOR.GREY10}>
                        계정 정보는 모두 암호화 처리 되어있고, 계정 소유자 본인만 로그인할 수
                        있습니다.
                    </Body2>
                </Row>
                <Row width={'100%'} margin={'12px 0 0 0'}>
                    <Body2 margin={'0 8px 0 0'}>•</Body2>
                    <Body2 fontWeight={'regular'} color={COLOR.GREY10}>
                        <Stress>계정 소유자가 아닌 경우 로그인할 수 없습니다.</Stress>
                        회원님의 계정이 아닌 경우 회원가입해 주시기 바랍니다.
                    </Body2>
                </Row>
                <Row width={'100%'} margin={'12px 0 0 0'}>
                    <Body2 margin={'0 8px 0 0'}>•</Body2>
                    <Body2 fontWeight={'regular'} color={COLOR.GREY10}>
                        기타 문의사항은 고객센터로 문의주시기 바랍니다.
                    </Body2>
                </Row>

                <AskCS
                    isDisplayNone={false}
                    margin={'24px 0 0 0'}
                    description={'도움이 필요하신가요?'}
                    buttonText={'문의하기'}
                    onClick={(e) => {
                        e.preventDefault();
                        Amplitude.getInstance().logEvent('PageView', {
                            Type: '고객센터',
                        });
                        openChannelTalk();
                    }}
                />
            </Col>
        </LoginModal>
    );
}
