import { createGlobalStyle } from 'styled-components';
import { $BASE_FONT_PATH } from '../../lib/values';
// language=CSS
export default createGlobalStyle`
    @font-face {
        font-family: 'icomoon';
        src: url("${$BASE_FONT_PATH}/icomoon/icomoon.eot");
        src: url("${$BASE_FONT_PATH}/icomoon/icomoon.eot") format('embedded-opentype'),
        url("${$BASE_FONT_PATH}/icomoon/icomoon.ttf") format('truetype'),
        url("${$BASE_FONT_PATH}/icomoon/icomoon.woff") format('woff'),
        url("${$BASE_FONT_PATH}/icomoon/icomoon.svg") format('svg');
        font-weight: normal;
        font-style: normal;
    }

    [class^="icon-"], [class*=" icon-"] {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
`;
