import _ from 'lodash';
import moment, { MomentInput } from 'moment';
import 'moment/locale/ko';
import airbridge from 'airbridge-web-sdk-loader';
import sanitizeHtml from 'sanitize-html';
import { uuid4 } from '@sentry/utils';
import Amplitude from 'amplitude-js';
import { eventLogApi } from './request';
import { AccountType } from './values';

moment.updateLocale('ko', {
    relativeTime: {
        s: '방금',
        ss: '%d초',
        m: '1분',
        mm: '%d분',
        h: '1시간',
        hh: '%d시간',
        d: '하루',
        dd: '%d일',
        M: '한달',
        MM: '%d개월',
        y: '1년',
        yy: '%d년',
    },
});

export function ago(time: MomentInput) {
    return moment(time).fromNow();
}

// eslint-disable-next-line no-unused-vars
export function go(path: string, _as = '') {
    // legacy: Router.push(path) - 대신 강제로 url 변경
    window.location.href = path;
}

export function sanitize(html: string) {
    return sanitizeHtml(html, {
        allowedClasses: {
            a: ['react-tag-primary-text'],
            span: ['hashtag-text'],
        },
    });
}

export function getPriceString(min: number | null, max: number) {
    if (min === null) {
        return null;
    } else {
        if (min === 0 && max > 0) {
            return `${max}만원 이하`;
        } else if (max > 0) {
            return `${min} ~ ${max}만원`;
        } else if (min > 0) {
            return `${min}만원 이상`;
        } else {
            return null;
        }
    }
}

export function getProfileImage(image: string) {
    if (!image || /default\/profile.png/.test(image)) {
        return '/static/profile.png';
    } else if (/^(https?:\/\/|\/\/cdn\.)ggumim\.co\.kr\//.test(image)) {
        return image;
    } else {
        return `//cdn.ggumim.co.kr/cache/member/profile/180/${image}`;
    }
}

export function onCloseAndRefresh() {
    window.history.back();
}

export function setResult() {
    // 앱 새로고침
}

export function classes(cls: any) {
    if (_.isObject(cls)) {
        return _.keys(_.pickBy(cls, _.identity)).join(' ');
    } else if (_.isArray(cls)) {
        return cls.join(' ');
    } else {
        return cls;
    }
}

export function replaceToHashTag(content: string) {
    const replaced = _.replace(content, /#\[([^\]]*)\]/g, '<span class="hashtag-text">#$1</span>');
    return _.replace(replaced, /#\[([^\]]*)/g, '');
}
export function replaceToLinkHashTag(content: string) {
    const replaced = _.replace(
        content,
        /#\[([^\]]*)\]/g,
        '<a href="/search/results/?q=$1&type=picture"><span class="hashtag-text">#$1</span></a>',
    );
    return _.replace(replaced, /#\[([^\]]*)/g, '');
}
export function replaceHashTagToPlainText(content: string) {
    return _.replace(content, /#\[([^\]]*)\]/g, '#$1');
}

export const replaceToLineBreak = (content: string) => _.replace(content, /\n/g, '<br/>');

export const measureTextWidth = (content: string, fontSize: string, fontFamily: string) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.font = `${fontSize} ${fontFamily}`;
        return ctx.measureText(content.replace(/\n+/g, '')).width;
    }

    return 0;
};
export const getLengthToFitWidth = (
    content: string,
    fontSize: string,
    fontFamily: string,
    widthLimit: number,
) => {
    let result = content;
    let currentTextWidth = measureTextWidth(result, fontSize, fontFamily);
    while (currentTextWidth > widthLimit) {
        result = result.substring(0, result.length - 1);
        currentTextWidth = measureTextWidth(result, fontSize, fontFamily);
    }

    return result.length;
};

export function getProductThumbnailImage(imageUrl: string) {
    return !!imageUrl ? imageUrl : 'https://cdn.ggumim.co.kr/storage/20211116202248eDhBvDtQCH.png';
}

export function fileNameToUrl(fileName: string | null) {
    if (!fileName) {
        return '';
    }
    return `https://cdn.ggumim.co.kr/${fileName}`;
}

let isAirBridgeInitialized = false;
export function initAirBridge(memberIdx: string | number = 0) {
    if (isAirBridgeInitialized) {
        return;
    }

    try {
        airbridge.init({
            app: 'ggumim',
            webToken: '4b79f80c8ba34681b772b711a2717855',
            utmParsing: true,
            useProtectedAttributionWindow: true,
        });

        isAirBridgeInitialized = true;

        if (memberIdx) {
            airbridge.setUserId(memberIdx.toString());
        }

        // 유저 정보로 넣을 시 한글 주의
        airbridge.setUserAttributes({
            nickname: '',
        });
    } catch (e) {}
}

export const addEllipsis = (text: string, length: number) => {
    if (text.length > length) {
        return `${text.slice(0, length)}...`;
    }
    return text;
};

export const addNicknameEllipsis = (text: string) => {
    // 닉네임 길이: 3~10글자 (23.11.17)
    // 10글자 이상일 경우 9글자까지 노출 + ...
    if (text.length > 10) {
        return `${text.slice(0, 9)}...`;
    }
    return text;
};

export function getKoreanNumber(number: number) {
    if (number < 100) {
        return number;
    } else if (number < 1000) {
        return number.toLocaleString().slice(0, 1) + '백';
    } else if (number < 10000) {
        const splitNum = String(number / 1000).split('.');
        const [first, second] = splitNum;
        return second ? first + '천' + String(second).slice(0, 1) + '백' : first + '천';
    } else if (number >= 10000) {
        const splitNum = String(number / 10000).split('.');
        const [first, second] = splitNum;
        if (second) {
            if (second.slice(0, 1) === '0') {
                return first + '만' + second.slice(1, 2) + '백';
            } else {
                if (!second.slice(1, 2)) {
                    return first + '만' + second.slice(0, 1) + '천';
                } else {
                    return first + '만' + second.slice(0, 1) + '천' + second.slice(1, 2) + '백';
                }
            }
        } else {
            return first + '만';
        }
    } else {
        return number.toLocaleString();
    }
}

export interface GgumimEvent {
    memberIdx: number;
    eventType: string;
    page: string;
    section: string | null | undefined;
    itemType: string | null | undefined;
    itemIdx: number | string | null | undefined;
    ownerIdx?: number | string | null | undefined;
    keyword: string | null | undefined;
    refPage: string | null | undefined;
    refSection: string | null | undefined;
    refSource: string | null | undefined;
    eventData: any | null | undefined;
}

export function sendEventLog(event: GgumimEvent) {
    const deviceId = Amplitude.getInstance().options.deviceId;
    let logEvent = {
        eventId: uuid4(),
        sequence: 0,
        eventAt: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        deviceId: deviceId,
        sessionId: Amplitude.getInstance().getSessionId(),
        properties: event.eventData ? event.eventData : null,
        ...event,
    };

    eventLogApi.post('/log/v1/receive', logEvent).catch((_) => {});
}

export function sendPageView(
    memberIdx: number,
    queryString: any,
    page: string,
    section: string,
    itemType: string,
    itemIdx: number,
    eventData: any = null,
) {
    let refPage = queryString['refPage'] ? queryString['refPage'] : null;
    let refSection = queryString['refSection'] ? queryString['refSection'] : null;
    let refSource = queryString['refSource'] ? queryString['refSource'] : null;

    const logEvent = {
        memberIdx: memberIdx,
        eventType: 'pageView',
        page: page,
        section: section,
        itemType: itemType,
        itemIdx: itemIdx,
        refPage: refPage,
        refSection: refSection,
        refSource: refSource,
        eventData: eventData,
    } as GgumimEvent;
    sendEventLog(logEvent);
}

export let amplitudeEvents: any[] = [];

export function logAmplitudeEvent(eventName: string, eventObject: any) {
    try {
        if (typeof eventObject === 'string') {
            eventObject = JSON.parse(eventObject);
        }

        const amplitudeClient = Amplitude.getInstance();
        amplitudeClient.logEvent(eventName, eventObject);
    } catch (e) {}
}

export function logAmplitudePageViewEvent(pageName: string, eventObject?: any) {
    try {
        if (typeof eventObject === 'string') {
            eventObject = JSON.parse(eventObject);
        }
        const amplitudeClient = Amplitude.getInstance();
        amplitudeClient.logEvent('PageView', {
            Page: pageName,
            Url: window.location.pathname,
            Referrer: window.location.href,
            PageObject: eventObject,
            FullAgent: navigator.userAgent,
        });
    } catch (e) {}
}

export function facebookEvent(eventName: string, data: any) {
    try {
        const ReactPixel = require('react-facebook-pixel');

        if (Object.keys(data).length === 0) {
            ReactPixel.track(eventName);
        } else {
            ReactPixel.track(eventName, data);
        }
    } catch (e) {}
}

export const translateAccountTypeToKorean = (accountType: AccountType) => {
    switch (accountType) {
        case AccountType.KAKAO:
            return '카카오';
        case AccountType.NAVER:
            return '네이버';
        case AccountType.FACEBOOK:
            return '페이스북';
        case AccountType.APPLE:
            return '애플';
        case AccountType.EMAIL:
            return '이메일';
        default:
            return '';
    }
};
