import styled from 'styled-components';
import { Body2, Caption2, Row } from '../../components/layout';
import COLOR from '../../lib/styles/colors';
import Image from 'next/image';
import { ProgressBarProps } from '../../lib/hooks/useProgressBar';

const NumberedBullet = styled(Row)<{ background: string }>`
    background: ${(props) => props.background};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
`;

const SelectedStep = (props: { index: number; text: string }) => {
    return (
        <>
            <NumberedBullet background={COLOR.GREY11}>
                <Caption2 fontWeight={'bold'} color={COLOR.BG}>
                    {props.index}
                </Caption2>
            </NumberedBullet>
            <Body2 fontWeight={'bold'} color={COLOR.GREY11} margin={'0 0 0 6px'}>
                {props.text}
            </Body2>
        </>
    );
};

const NotSelectedStep = (props: { index: number; text: string }) => {
    return (
        <>
            <NumberedBullet background={COLOR.GREY3}>
                <Caption2 fontWeight={'bold'} color={COLOR.GREY4}>
                    {props.index}
                </Caption2>
            </NumberedBullet>
            <Body2 fontWeight={'bold'} color={COLOR.GREY4} margin={'0 0 0 6px'}>
                {props.text}
            </Body2>
        </>
    );
};

export const ProgressBarText = (props: ProgressBarProps) => {
    return (
        <Row>
            {props.stepList.map((singleStep, index) => {
                return (
                    <Row align={'center'} justify={'center'} key={index}>
                        {props.stepList.indexOf(props.step) === index ? (
                            <SelectedStep index={index + 1} text={singleStep} />
                        ) : (
                            <NotSelectedStep index={index + 1} text={singleStep} />
                        )}
                        {index !== props.stepList.length - 1 && (
                            <Row margin={'0 12px'}>
                                <Image
                                    unoptimized
                                    src={'//cdn.ggumim.co.kr/storage/20220721095455VZvmcS101O.png'}
                                    alt={'detail_icon'}
                                    width={7}
                                    height={9.8}
                                />
                            </Row>
                        )}
                    </Row>
                );
            })}
        </Row>
    );
};
