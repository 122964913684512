import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import COLOR from '../../lib/styles/colors';

export type ButtonProps = {
    focusBackground?: string;
    children?: React.ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    style?: React.CSSProperties;
} & React.CSSProperties &
    ButtonHTMLAttributes<HTMLButtonElement>;

export const StyledButton = styled.button<ButtonProps>`
    position: ${({ position }) => position || 'static'};
    display: ${({ display }) => display || 'inline-flex'};
    align-items: ${({ alignItems }) => alignItems || 'center'};
    justify-content: ${({ justifyContent }) => justifyContent || 'center'};
    width: ${({ width }) => (width ? width : 'auto')};
    height: ${({ height }) => (height ? height : 'auto')};
    ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
    padding: ${({ padding }) => (padding ? padding : '0')};
    border-radius: ${({ borderRadius }) => borderRadius ?? '5px'};
    border: ${({ border }) => (border ? border : 'none')};
    ${({ borderBottom }) =>
        borderBottom &&
        css`
            border-bottom: ${borderBottom};
        `}
    ${({ borderTop }) =>
        borderTop &&
        css`
            border-top: ${borderTop};
        `}
    margin: ${({ margin }) => (margin ? margin : '0')};
    background: ${({ background }) => (background ? background : `${COLOR.BG}`)};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
    color: ${({ color }) => (color ? color : 'black')};
    box-sizing: border-box;
    cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
    outline: none;
    & :focus,
    :active,
    :hover {
        ${({ focusBackground }) => focusBackground && `background: ${focusBackground};`}
    }
`;

export default function Button({
    children,
    position,
    display,
    alignItems,
    justifyContent,
    width,
    height,
    minWidth,
    background,
    focusBackground,
    margin,
    padding,
    border,
    borderTop,
    borderBottom,
    borderRadius,
    fontWeight,
    fontSize,
    color,
    cursor,
    style,
    onClick,
}: ButtonProps) {
    return (
        <StyledButton
            position={position}
            display={display}
            alignItems={alignItems}
            justifyContent={justifyContent}
            width={width}
            height={height}
            minWidth={minWidth}
            background={background}
            focusBackground={focusBackground}
            margin={margin}
            padding={padding}
            border={border}
            borderTop={borderTop}
            borderBottom={borderBottom}
            borderRadius={borderRadius}
            fontWeight={fontWeight}
            fontSize={fontSize}
            color={color}
            cursor={cursor}
            style={style}
            onClick={(e) => {
                const currentTarget = e.currentTarget;
                if (onClick && focusBackground) {
                    setTimeout(() => {
                        currentTarget?.blur();
                        onClick(e);
                    }, 100);
                } else {
                    onClick && onClick(e);
                    setTimeout(() => {
                        currentTarget?.blur();
                    }, 100);
                }
            }}
        >
            {children}
        </StyledButton>
    );
}

const TextButtonWrap = styled.button`
    &:hover {
        span {
            text-decoration: underline;
        }
    }
`;

export function TextButton({ children, focusBackground, style, onClick }: ButtonProps) {
    return (
        <TextButtonWrap
            style={style}
            onClick={(e) => {
                const currentTarget = e.currentTarget;
                if (onClick && focusBackground) {
                    setTimeout(() => {
                        currentTarget?.blur();
                        onClick(e);
                    }, 100);
                } else {
                    onClick && onClick(e);
                    setTimeout(() => {
                        currentTarget?.blur();
                    }, 100);
                }
            }}
        >
            {children}
        </TextButtonWrap>
    );
}
