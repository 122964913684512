import axios, { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';
import { parseCookies } from 'nookies';
import { store } from '../stores';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export const API_URL = serverRuntimeConfig.API_URL || publicRuntimeConfig.API_URL;
export const CONTENT_API_URL =
    serverRuntimeConfig.CONTENT_API_URL || publicRuntimeConfig.CONTENT_API_URL;
export const USER_API_URL = serverRuntimeConfig.USER_API_URL || publicRuntimeConfig.USER_API_URL;
export const PURCHASE_API_URL =
    serverRuntimeConfig.PURCHASE_API_URL || publicRuntimeConfig.PURCHASE_API_URL;
export const PRODUCT_API_URL =
    serverRuntimeConfig.PRODUCT_API_URL || publicRuntimeConfig.PRODUCT_API_URL;
export const HOME_API_URL = serverRuntimeConfig.HOME_API_URL || publicRuntimeConfig.HOME_API_URL;
export const EVENT_LOG_API_URL =
    serverRuntimeConfig.EVENT_LOG_API_URL || publicRuntimeConfig.EVENT_LOG_API_URL;
export const EVENT_API_URL = serverRuntimeConfig.EVENT_API_URL || publicRuntimeConfig.EVENT_API_URL;
export const SEARCH_API_URL =
    serverRuntimeConfig.SEARCH_API_URL || publicRuntimeConfig.SEARCH_API_URL;

export const api = axios.create({
    baseURL: API_URL,
    transformRequest: (data) => {
        if (data) {
            return Object.keys(data || {}).reduce((left, right) => {
                left.append(right, data[right]);
                return left;
            }, new FormData());
        }
        return data;
    },
    transformResponse: (data) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return {
                code: 400,
                html: data,
            };
        }
    },
});

export const contentApi = axios.create({
    baseURL: CONTENT_API_URL,
    transformResponse: (data) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return {
                code: 400,
                html: data,
            };
        }
    },
});

export const userApi = axios.create({
    baseURL: USER_API_URL,
    transformResponse: (data) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return {
                code: 400,
                html: data,
            };
        }
    },
});

export const purchaseApi = axios.create({
    baseURL: PURCHASE_API_URL,
    transformResponse: (data) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return {
                code: 400,
                html: data,
            };
        }
    },
});

export const productApi = axios.create({
    baseURL: PRODUCT_API_URL,
    transformResponse: (data) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return {
                code: 400,
                html: data,
            };
        }
    },
});

export const homeApi = axios.create({
    baseURL: HOME_API_URL,
    transformRequest: (data) => {
        if (data) {
            return Object.keys(data || {}).reduce((left, right) => {
                left.append(right, data[right]);
                return left;
            }, new FormData());
        }
        return data;
    },
    transformResponse: (data) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return {
                code: 400,
                html: data,
            };
        }
    },
});

export const eventLogApi = axios.create({
    baseURL: EVENT_LOG_API_URL,
});

export const eventApi = axios.create({
    baseURL: EVENT_API_URL,
    transformResponse: (data) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return {
                code: 400,
                html: data,
            };
        }
    },
});

export const searchApi = axios.create({
    baseURL: SEARCH_API_URL,
    transformRequest: (data) => {
        if (data) {
            return Object.keys(data || {}).reduce((left, right) => {
                left.append(right, data[right]);
                return left;
            }, new FormData());
        }
        return data;
    },
    transformResponse: (data) => {
        try {
            return JSON.parse(data);
        } catch (e) {
            return {
                code: 400,
                html: data,
            };
        }
    },
});

function setPhpSessionId(req: AxiosRequestConfig, value: string) {
    req.headers['X-PHPSESSID'] = value;
}

//추가

// function setAuthToken(req: AxiosRequestConfig, value: string) {
//     req.headers['X-AUTH-TOKEN'] = value;
// }

const axiosInstanceList = [
    contentApi,
    userApi,
    purchaseApi,
    productApi,
    homeApi,
    eventApi,
    searchApi,
];
axiosInstanceList.forEach((axiosInstance) => {
    axiosInstance.interceptors.request.use((req) => {
        const cookies = parseCookies();
        if (cookies['PHPSESSID']) {
            setPhpSessionId(req, cookies['PHPSESSID']);
        }
        //추가
        // if (cookies['AUTH-TOKEN']) {
        //     setAuthToken(req, cookies['AUTH-TOKEN']);
        // }

        const state = store?.getState();
        const nonmemberToken = (state as any).common.nonmemberToken;
        if (nonmemberToken) {
            req.headers['X-NONMEMBER-TOKEN'] = nonmemberToken;
        }

        return req;
    });
});

// 서버 사이드에서 필요한 로직
axiosInstanceList.forEach((axiosInstance) => {
    axiosInstance.interceptors.request.use((req) => {
        const state = store?.getState();
        const phpSessionId = state?.common.phpSessionId;
        // const authToken = state?.common.authToken; // 추가

        if (phpSessionId) {
            setPhpSessionId(req, phpSessionId);
        }
        // 추가
        // if (authToken) {
        //     setAuthToken(req, authToken);
        // }

        return req;
    });
});
