import styled from 'styled-components';
import { Button, Body1, Row, Display4 } from '../layout';
import { COLOR_V4 } from '../../lib/styles/colors';
import { useRouter } from 'next/router';

interface RelatedKeywordsProps {
    children?: React.ReactNode | React.ReactNode[];
}

const RelatedKeywordsRootStyle = styled.div`
    padding: 24px 22px;
    border-radius: 10px;
    border: 1px solid ${COLOR_V4.GRAY200};
`;

function RelatedKeywordsRoot(props: RelatedKeywordsProps) {
    const { children } = props;
    return (
        <RelatedKeywordsRootStyle>
            <Row margin={'0 0 13px 0'}>
                <Display4 fontWeight={'medium'} color={COLOR_V4.GRAY500}>
                    이 검색어는 어때요?
                </Display4>
            </Row>
            {children}
        </RelatedKeywordsRootStyle>
    );
}

/* 관련 키워드 블록 */
interface RelatedKeywordsKeywordProps {
    children: string;
}

function RelatedKeywordsKeyword(props: RelatedKeywordsKeywordProps) {
    const { children } = props;
    const router = useRouter();

    return (
        <Button
            padding={'9px 12px'}
            borderRadius={'50px'}
            background={COLOR_V4.GRAY50}
            margin={'7px'}
            onClick={() =>
                router.push(
                    {
                        query: { q: children, type: router.query.type },
                    },
                    undefined,
                    { shallow: true },
                )
            }
        >
            <Body1 fontWeight={'regular'} color={COLOR_V4.GRAY500}>
                {children}
            </Body1>
        </Button>
    );
}

const Root = RelatedKeywordsRoot;
const Keyword = RelatedKeywordsKeyword;

export { Root, Keyword };
