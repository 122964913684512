import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { COLOR_V4 } from '../../lib/styles/colors';

interface IInputProps {
    width?: string;
    height?: string;
    minWidth?: string;
    minHeight?: string;
    margin?: number | string;
    padding?: number | string;
    borderRadius?: string;
    color?: string;
    fontSize?: string | number;
    fontWeight?: string | number;
    lineHeight?: string | number;
    textAlign?: string;
    background?: string;
    noAnimation?: boolean;
    placeholderColor?: string;
}

export const Input = styled.input<IInputProps>`
    width: ${({ width }) => (width ? `${width}` : 'auto')};
    height: ${({ height }) => (height ? `${height}` : '46px')};
    ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')}
    ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')}
    margin: ${({ margin }) => (margin ? `${margin}` : '0')};
    padding: ${({ padding }) => (padding ? `${padding}` : '0 8px')};
    border: 1px solid ${COLOR_V4.GRAY200};
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}` : '4px')};
    background: white;
    color: ${({ color }) => (color ? `${color}` : COLOR_V4.GRAY600)};
    box-sizing: border-box;
    appearance: none;
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    font-size: ${({ fontSize }) => fontSize || '14px'};
    font-weight: ${({ fontWeight }) => fontWeight || 400};
    line-height: ${({ lineHeight }) => lineHeight || '20px'};
    cursor: text;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;

    &:hover {
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        -ms-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out;

        ${({ background, noAnimation }) =>
            noAnimation || background === 'none'
                ? ''
                : background
                ? css`
                      background: ${darken(0.02, background)};
                  `
                : css`
                      background: ${darken(0.02, '#f1f3f6')};
                  `}
    }

    -moz-appearance: textfield;

    &:focus {
        border-bottom: 1px solid #e9ebee;
        outline: none;
    }

    &::placeholder {
        color: ${({ placeholderColor }) => placeholderColor || '#bebebe'};
    }

    &:disabled,
    .disabled {
        border-bottom: 1px solid #f1f3f6;
        background: #f1f3f6;
        color: #40464c;
        opacity: 1;
        -webkit-text-fill-color: inherit;
    }

    &[type='button'] {
        text-align: left;
    }

    &[type='number'],
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        appearance: none;
        -moz-appearance: textfield;
        margin: 0;
    }
`;
