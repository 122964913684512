import React, { useState } from 'react';
import Arrow from '../../lib/Icons/Arrow';
import { Body1 } from './Text';
import { COLOR_V4 } from '../../lib/styles/colors';
import { Row } from './FlexBox';
import Button from './Button';

interface Props {
    currentPage: number;
    totalPages: number;
    chunkSize: number;
    handleClickPageNumber: (pageNum: number) => void;
}

const Pagenation = ({ currentPage, totalPages, chunkSize, handleClickPageNumber }: Props) => {
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
    const chunkPages = chunkArray(pageNumbers, chunkSize);

    const [chunkIndex, setChunkIndex] = useState<number>(0);

    return (
        <Row justify={'center'} margin={'30px 0'} width={'100%'}>
            {chunkIndex > 0 && (
                <Button
                    margin={'0 16px 0 0'}
                    color={COLOR_V4.GRAY400}
                    cursor={'pointer'}
                    onClick={() => {
                        const prevIndex = chunkIndex - 1;
                        if (prevIndex >= 0) {
                            setChunkIndex(prevIndex);
                            handleClickPageNumber(chunkPages[prevIndex][0]);
                        }
                    }}
                >
                    <Row align={'center'}>
                        <Arrow size={16} rotate={'rotate(90deg)'} color={COLOR_V4.GRAY400} />
                        <Body1
                            margin={'1px 0 0 3px'}
                            color={COLOR_V4.GRAY400}
                            fontWeight={'medium'}
                        >
                            이전
                        </Body1>
                    </Row>
                </Button>
            )}

            {chunkPages[chunkIndex].map((pageNum) => {
                const isCurr = currentPage === pageNum;
                return (
                    <Button
                        key={pageNum}
                        margin={'0 4px'}
                        width={'34px'}
                        height={'34px'}
                        borderRadius={'8px'}
                        background={isCurr ? COLOR_V4.GRAY600 : COLOR_V4.WHITE}
                        cursor={isCurr ? 'default' : 'pointer'}
                        onClick={() => handleClickPageNumber(pageNum)}
                    >
                        <Body1
                            color={isCurr ? COLOR_V4.WHITE : COLOR_V4.GRAY600}
                            fontWeight={'bold'}
                        >
                            {pageNum}
                        </Body1>
                    </Button>
                );
            })}
            {chunkIndex + 1 < chunkPages.length && (
                <Button
                    margin={'0 0 0 16px'}
                    cursor={'pointer'}
                    onClick={() => {
                        const nextIndex = chunkIndex + 1;
                        if (nextIndex < chunkPages.length) {
                            setChunkIndex(nextIndex);
                            handleClickPageNumber(chunkPages[nextIndex][0]);
                        }
                    }}
                >
                    <Row align={'center'}>
                        <Body1
                            margin={'1px 3px 0 0'}
                            color={COLOR_V4.GRAY400}
                            fontWeight={'medium'}
                        >
                            다음
                        </Body1>
                        <Arrow size={16} rotate={'rotate(270deg)'} color={COLOR_V4.GRAY400} />
                    </Row>
                </Button>
            )}
        </Row>
    );
};

export default Pagenation;

const chunkArray = (arr: number[], chunkSize: number) => {
    return arr.reduce((result: number[][], _, index) => {
        if (index % chunkSize === 0) {
            result.push(arr.slice(index, index + chunkSize));
        }
        return result;
    }, []);
};
