import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { COLOR_V4 } from '../../lib/styles/colors';

const ImageStyle = styled.div<{
    width?: string;
    height?: string;
    border?: string;
    radius?: string;
    cursor?: string;
}>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => (width ? width : '85px')};
    height: ${({ height }) => (height ? height : '85px')};
    border: ${({ border }) => (border ? border : `1px solid ${COLOR_V4.GRAY200}`)};
    border-radius: ${({ radius }) => (radius ? radius : '4px')};
    overflow: hidden;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s ease-in-out;

        &:hover {
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
        }
    }
`;

const ImageHover = styled.div<{ isHover: boolean }>`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    ${(isHover) => (isHover ? 'transform: scale(1.15)' : '')};
    transition: all ease 0.15s 0s;
`;

interface Props {
    src: string;
    width?: string;
    height?: string;
    border?: string;
    radius?: string;
    cursor?: string;
    children?: ReactNode;
    handleClickImage?: () => void;
}

const HoverImage = ({
    src,
    width,
    height,
    border,
    radius,
    cursor,
    children,
    handleClickImage,
}: Props) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <ImageStyle
            width={width}
            height={height}
            border={border}
            radius={radius}
            cursor={cursor}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
            onClick={handleClickImage}
        >
            <ImageHover isHover={isHover}>
                <img src={src} alt={'image'} width={'100%'} />
            </ImageHover>
            {children}
        </ImageStyle>
    );
};

export default HoverImage;
