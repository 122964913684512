/* Package */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import useSWRImmutable from 'swr/immutable';
import { parseCookies, setCookie } from 'nookies';
import _ from 'lodash';
import { Property } from 'csstype';
/* Lib */
import COLOR, { COLOR_V4 } from '../../lib/styles/colors';
import { addNicknameEllipsis, initAirBridge } from '../../lib/utils';
/* Store */
import { AppState } from '../../stores';
import { ICategoryItem } from '../../stores/common/common';
/* Component */
import {
    Body2,
    Body3,
    Body4,
    Caption1,
    Caption2,
    Col,
    Body1,
    Row,
    Text,
    Heading2,
} from '../layout';
import { Autocomplete, AutocompleteSmall } from '../../components/search';
/* Api */
import { getCartCount, getPurchaseHistoryCountByStatus } from '../../api/purchase';
import StoreNav from '../commerce/StoreNav';

const MAIN_HEADER_HEIGHT = 96;
const SUB_HEADER_HEIGHT = 54;
const APP_BANNER_HEIGHT = 56;
export const STICKY_HEADER_HEIGHT = 68;

const UserInfo = styled.div<{ headerPosition: Property.Position }>`
    position: absolute;
    top: ${({ headerPosition }) => (headerPosition === 'sticky' ? 69 : 54) + 'px'};
    left: -10px;
    display: none;
    width: 220px;
    background-color: ${COLOR.BG};
    border: 1px solid #dce0e0;
    z-index: 3000;

    a {
        width: 100%;
    }

    .user-summary {
        position: relative;

        &:hover {
            .membership-tooltip-wrap {
                display: block;
            }
        }
    }

    .membership-tooltip-wrap {
        display: none;
        position: absolute;
        top: 20px;
        right: 210px;
        width: 100%;
        height: 175px;
    }

    .membership-tooltip {
        position: absolute;
        width: 200px;
        padding: 16px 12px 16px 18px;
        background-color: ${COLOR.BG};
        border: 1px solid #f1f3f6;
        border-radius: 10px;
        box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.06), -4px -4px 10px 1px rgba(0, 0, 0, 0.06);
        color: #535b63;
        z-index: 9999;

        &::after {
            content: '';
            position: absolute;
            top: 25px;
            right: -14px;
            transform: rotate(90deg);
            border-top: 0 solid transparent;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid white;
        }
    }

    .membership-detail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 34px;
        margin: 12px auto 0;
        border: 1px solid #e9ebee;
        border-radius: 10px;
        cursor: pointer;
    }
`;

const HeaderContainer = styled.div<{}>`
    position: relative;
    // 모달보다는 낮게
    z-index: 4999;

    .circle-image {
        border: 0.5px solid #e9ebee !important;
        border-radius: 50%;
        cursor: pointer;
    }

    .member-profile:hover {
        ${UserInfo} {
            display: block;
        }
    }
`;

const HeaderMainWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 1000px;
    height: ${MAIN_HEADER_HEIGHT}px;
    margin: 0 auto;
    background-color: ${COLOR.BG};
`;

const HeaderWrapper = styled.header<{ position: string }>`
    min-width: 1440px;
    background-color: ${COLOR.BG};
    z-index: 997;

    ${({ position }) =>
        position === 'sticky' &&
        css`
            top: 0;
        `}
`;

const HeaderSubWrapper = styled.div<{ display: string }>`
    display: ${({ display }) => display};
    align-items: center;
    width: 1000px;
    height: ${SUB_HEADER_HEIGHT}px;
    margin: 0 auto;

    position: relative;
`;

const HeaderLine = styled.div`
    width: 100%;
    height: 1px;
    background: #e9ebee;
`;

const LogoWrapper = styled.div``;
const Logo = styled.div<{
    width: string | null;
    height: string | null;
    imageSrc?: string;
    margin?: string;
}>`
    margin: ${({ margin }) => (margin ? `${margin}` : '0 20px 0 0')};
    width: ${({ width }) => (width ? `${width}` : '170px')};
    height: ${({ height }) => (height ? `${height}` : '50px')};
    background-image: ${({ imageSrc }) =>
        imageSrc
            ? `url('${imageSrc}')`
            : "url('https://cdn.ggumim.co.kr/storage/202204051459171AgPRwqnvh.png')"};
    background-repeat: no-repeat;
    background-size: cover;
`;

const GlobalNavigation = styled.nav``;
const GlobalNavigationList = styled.ul`
    display: flex;
    align-items: center;
`;

const GlobalNavigationMenu = styled.li<{ active: boolean; padding?: string }>`
    height: auto;
    padding: ${({ padding }) => (padding ? padding : '0 20px')};
    cursor: pointer;

    &:hover {
        &::after {
            border-bottom: 2px solid #ee6d6d;
        }
    }

    ::after {
        content: '';
        display: block;
        position: relative;
        top: -1px;
        width: 100%;
        border-bottom: 2px solid white;
        // border-bottom: ${({ active }) => (active ? `4px solid ${COLOR.CORAL6}` : 'none')};
    }

    > a {
        width: 100%;
        height: ${SUB_HEADER_HEIGHT}px;
        display: flex;
        align-items: center;
    }
`;

const CategoryTreeWrapper = styled(Row)`
    position: absolute;
    top: 54px;
    left: 0;
`;

const CategoryMenuArrow = styled.div`
    display: none;
    width: 8px;
    height: 16px;
    margin-left: auto;
    margin-right: 16px;
    background-image: url('https://cdn.ggumim.co.kr/storage/20190822164906OeZewVFMgY.png');
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: contain;
`;

const CategoryMenuIcon = styled.div`
    width: 24px;
    height: 24px;
    margin: 0 0 2px 2px;
    background-image: url('https://cdn.ggumim.co.kr/storage/202204051459052q2QRSpTV9.png');
    background-size: contain;
    background-position-y: center;
    background-repeat: no-repeat;
`;

const CategoryMenuList = styled(Col)`
    width: 170px;
    background-color: ${COLOR.BG};

    &.depth-1 {
        display: none;
        z-index: 1000;
        position: absolute;
        left: 0;
        min-height: 660px;
        background-color: ${COLOR.BG};
        box-shadow: 0 6px 10px 0 rgb(66 66 66 / 6%);
        border: 1px solid #eee;
    }

    &.depth-2 {
        display: none;
        z-index: 2000;
        position: absolute;
        top: -1px;
        left: 168px;
        width: 190px;
        min-height: 660px;
        padding: 8px 0 8px 20px;
        background-color: ${COLOR.BG};
        box-shadow: 0 6px 10px 0 rgb(66 66 66 / 6%);
        border: 1px solid #eee;
    }

    &.depth-3 {
        display: none;
        z-index: 3000;
        position: absolute;
        top: -1px;
        left: 188px;
        width: 190px;
        min-height: 660px;
        padding: 8px 0 8px 20px;
        background-color: ${COLOR.BG};
        box-shadow: 0 6px 10px 0 rgb(66 66 66 / 6%);
        border: 1px solid #eee;
    }

    &.depth-4 {
        display: none;
        z-index: 4000;
        position: absolute;
        top: -1px;
        left: 188px;
        width: 190px;
        min-height: 660px;
        padding: 8px 0 8px 20px;
        background-color: ${COLOR.BG};
        box-shadow: 0 6px 10px 0 rgb(66 66 66 / 6%);
        border: 1px solid #eee;
    }

    ${Row} {
        height: 55px;
        padding: 5px 0;
    }
`;

const CategoryMenu = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding-right: 20px;
    cursor: pointer;

    .depth-1,
    .depth-2,
    .depth-3 {
        ${Row}:hover {
            > ${Text} {
                color: #f15657;
            }
            > ${CategoryMenuArrow} {
                display: block;
            }
        }
    }

    &:hover {
        & > ${Text} {
            &::after {
                border-bottom: 2px solid #ee6d6d;
            }
        }

        .depth-1 {
            display: block;
            ${Row}:hover {
                .depth-2 {
                    display: block;
                    ${Row}:hover {
                        .depth-3 {
                            display: block;
                            ${Row}:hover {
                                .depth-4 {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const CategoryImage = styled.div<{ src: string | null }>`
    width: 50px;
    height: 37.5px;
    background-image: url('${({ src }) => src || ''}');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
`;

const HeaderRight = styled(Row)`
    position: relative;
    margin-left: auto;

    .circle-image {
        border: 0.5px solid #e9ebee !important;
        border-radius: 50%;
        cursor: pointer;
    }

    .member-profile:hover {
        ${UserInfo} {
            display: block;
        }
    }

    > a {
        display: flex;
        align-items: center;
        height: 55px;
    }
`;

const CartCount = styled(Row)`
    position: absolute;
    top: -2px;
    right: 13px;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    min-width: 18px;
    width: max-content;
    height: 18px;
    border-radius: 9px;
    background-color: ${COLOR_V4.PINK600};
`;

const LoginButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 32px;
    margin: 0 20px 0 0;
    border-radius: 20px;
    background: linear-gradient(0deg, #535b63, #535b63),
        linear-gradient(180deg, #f86a45 0%, #ff688e 100%);
`;

const ProfileMenu = styled(Row)`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    padding: 14px 0 6px;
    border-bottom: 1px solid #eef0f0;

    > ${Body4} {
        position: relative;
        transition: 0.5s;
    }

    :hover > ${Body4} {
        padding-left: 10px;
    }
`;

const ProfileMenuCount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: #ff3636;
`;

const AppInduceBannerWrapper = styled(Row)`
    position: relative;
    min-width: 1440px;
    background: #ffae0c;

    .banner-main {
        cursor: pointer;
    }

    .banner-close {
        position: absolute;
        right: 0;
        cursor: pointer;
    }
`;

const HeaderStickyWrapper = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    min-width: 1440px;
    height: auto;
    background-color: ${COLOR.BG};
    z-index: 4999;

    ${HeaderMainWrapper} {
        height: 68px;
    }

    ${CategoryMenu} {
        height: 68px;

        & > ${Text} {
            &::after {
                top: 22px;
            }
        }
    }

    ${CategoryTreeWrapper} {
        top: 68px;
    }

    ${GlobalNavigationMenu} {
        > a {
            height: 66px;
        }

        ::after {
            top: 0px;
        }
    }

    ${CartCount} {
        top: -3px;
        right: 19px;
    }
`;

function CategoryMenuTree({
    tree,
    depth,
    parentCategoryString = '',
}: {
    tree: ICategoryItem[];
    depth: number;
    parentCategoryString?: string;
}) {
    return (
        <CategoryMenuList className={`depth-${depth}`}>
            {tree.map((category) => {
                const hasSubList = category.sub.length > 0;
                return (
                    <Row
                        key={`category_${category.idx}`}
                        align={'center'}
                        onClick={(e) => {
                            e.stopPropagation();
                            location.href = `/furniture/more/${
                                parentCategoryString + category.idx
                            }`;
                        }}
                    >
                        {depth === 1 && <CategoryImage src={category.image_url} />}
                        <Body2>{category.value}</Body2>
                        {hasSubList && <CategoryMenuArrow />}
                        {hasSubList && (
                            <CategoryMenuTree
                                tree={category.sub}
                                depth={depth + 1}
                                parentCategoryString={parentCategoryString + category.idx + '_'}
                            />
                        )}
                    </Row>
                );
            })}
        </CategoryMenuList>
    );
}

function GlobalMenuList(props: any) {
    const { categoryTree, isFurnitureActive, isStarActive, padding } = props;

    return (
        <GlobalNavigation>
            <GlobalNavigationList>
                <CategoryMenu>
                    <CategoryMenuIcon />
                    <Heading2 color={COLOR_V4.GRAY900} fontWeight={'bold'} margin={'2px 0 0 10px'}>
                        카테고리
                    </Heading2>
                    {categoryTree && (
                        <CategoryTreeWrapper>
                            <CategoryMenuTree tree={categoryTree} depth={1} />
                        </CategoryTreeWrapper>
                    )}
                </CategoryMenu>
                <Row width={'1px'} height={'16px'} margin={'0 4px'} background={'#c5c8ce'}></Row>
                <GlobalNavigationMenu active={isFurnitureActive} padding={padding}>
                    <a href={'/furniture'}>
                        <Heading2 color={COLOR_V4.GRAY900} fontWeight={'bold'} margin={'4px 0 0'}>
                            스토어
                        </Heading2>
                    </a>
                </GlobalNavigationMenu>
                <GlobalNavigationMenu active={isStarActive} padding={padding}>
                    <a href={'/star'}>
                        <Heading2 color={COLOR_V4.GRAY900} fontWeight={'bold'} margin={'4px 0 0'}>
                            매거진
                        </Heading2>
                    </a>
                </GlobalNavigationMenu>
            </GlobalNavigationList>
        </GlobalNavigation>
    );
}

function CartIcon(props: any) {
    const {
        width = '36px',
        height = '36px',
        padding = '0 20px 0 0',
        cartCount = 0,
        iconImage = '//cdn.ggumim.co.kr/storage/20220405155129j8hGmfA4Oa.png',
    } = props;

    return (
        <a href={'/commerce/cart'}>
            <Row position={'relative'} padding={padding}>
                <Image unoptimized src={iconImage} width={width} height={height} />
                {!!cartCount && (
                    <CartCount>
                        <Caption1 color={COLOR_V4.WHITE} fontWeight={'bold'}>
                            {cartCount > 99 ? '99+' : cartCount}
                        </Caption1>
                    </CartCount>
                )}
            </Row>
        </a>
    );
}

function MemberInfo(props: any) {
    const { member, purchaseCountInProgress, headerPosition } = props;

    return (
        <Row
            className={'member-profile'}
            position={'relative'}
            align={'center'}
            height={`${headerPosition === 'sticky' ? 70 : 55}px`}
        >
            <Row cursor={'pointer'} align={'center'}>
                <Image
                    className={'circle-image'}
                    unoptimized
                    src={
                        member.recentProfile !== ''
                            ? `https://cdn.ggumim.co.kr/cache/member/profile/180/${member.recentProfile}`
                            : `https://cdn.ggumim.co.kr/storage/20210708182007wM3nkRdM5x.png`
                    }
                    width={'32px'}
                    height={'32px'}
                />
                <Body2 color={COLOR_V4.GRAY900} fontWeight={'bold'} margin={'0 0 0 9px'}>
                    {member.nickname
                        ? addNicknameEllipsis(member.nickname)
                        : '닉네임을 설정해주세요'}
                </Body2>
            </Row>
            <UserInfo headerPosition={headerPosition}>
                <Row className={'user-summary'} align={'center'} padding={'19px 21px 14px 16px'}>
                    <Image
                        className={'circle-image'}
                        unoptimized
                        src={
                            member.recentProfile !== ''
                                ? `https://cdn.ggumim.co.kr/cache/member/profile/180/${member.recentProfile}`
                                : `https://cdn.ggumim.co.kr/storage/20210708182007wM3nkRdM5x.png`
                        }
                        width={'56px'}
                        height={'56px'}
                    />

                    <Col margin={'0 0 0 16px'} justify={'center'} cursor={'pointer'}>
                        <Row align={'center'}>
                            <Caption2 width={'34px'} color={COLOR.GREY5} fontWeight={'bold'}>
                                등급
                            </Caption2>
                            <Body3 color={COLOR.GREY10} fontWeight={'medium'}>
                                {member.gradeName}
                            </Body3>
                        </Row>
                        <Row align={'center'}>
                            <Caption2 width={'34px'} color={COLOR.GREY5} fontWeight={'bold'}>
                                포인트
                            </Caption2>
                            <Body3 color={COLOR.GREY10} fontWeight={'medium'}>
                                {member?.point ? member.point.toLocaleString() : 0}P
                            </Body3>
                        </Row>
                        <Row align={'center'}>
                            <Caption2 width={'34px'} color={COLOR.GREY5} fontWeight={'bold'}>
                                쿠폰
                            </Caption2>
                            <Body3 color={COLOR.GREY10} fontWeight={'medium'}>
                                {member.couponCount}장
                            </Body3>
                        </Row>
                    </Col>

                    <div className="membership-tooltip-wrap">
                        <div className="membership-tooltip">
                            <Body3 color={COLOR.GREY10} fontWeight={'bold'} margin={'0 0 8px 0'}>
                                등급별 포인트 적립 안내
                            </Body3>
                            <Caption1 color={COLOR.GREY9} margin={'0 0 4px 0'}>
                                FAMILY 등급 : 포인트 0.5% 적립
                            </Caption1>
                            <Caption1 color={COLOR.GREY9} margin={'0 0 4px 0'}>
                                VIP 등급 : 포인트 1% 적립
                            </Caption1>
                            <Caption1 color={COLOR.GREY9}>RVIP 등급 : 포인트 2% 적립</Caption1>
                            <div
                                className="membership-detail"
                                onClick={() => (location.href = '/member/membership')}
                            >
                                <Body4 fontWeight={'bold'}>자세히 보기</Body4>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row width={'100%'} padding={'0 20px'}>
                    <a href={`/member/noti/${member.idx}`}>
                        <ProfileMenu>
                            <Body4 color={'#black'} fontWeight={'bold'}>
                                알림
                            </Body4>
                            <ProfileMenuCount>
                                <Body4 color={COLOR.BG} fontWeight={'bold'}>
                                    {member.notiCount}
                                </Body4>
                            </ProfileMenuCount>
                        </ProfileMenu>
                    </a>
                </Row>
                <Row width={'100%'} padding={'0 20px'}>
                    <a href={`/member/view/${member.idx}`}>
                        <ProfileMenu>
                            <Body4 color={'#black'} fontWeight={'bold'}>
                                보관함
                            </Body4>
                        </ProfileMenu>
                    </a>
                </Row>
                <Row width={'100%'} padding={'0 20px'}>
                    <a href={`/member/my_order/${member.idx}`}>
                        <ProfileMenu>
                            <Body4 color={'#black'} fontWeight={'bold'}>
                                주문정보
                            </Body4>
                            <ProfileMenuCount>
                                <Body4 color={COLOR.BG} fontWeight={'bold'}>
                                    {purchaseCountInProgress}
                                </Body4>
                            </ProfileMenuCount>
                        </ProfileMenu>
                    </a>
                </Row>
                <Row width={'100%'} padding={'0 20px'}>
                    <a href={'/help/'}>
                        <ProfileMenu>
                            <Body4 color={'#black'} fontWeight={'bold'}>
                                고객센터
                            </Body4>
                        </ProfileMenu>
                    </a>
                </Row>
                <Row width={'100%'} padding={'0 20px'}>
                    <a href={'/logout/'}>
                        <ProfileMenu>
                            <Body4 color={'#black'} fontWeight={'bold'}>
                                로그아웃
                            </Body4>
                        </ProfileMenu>
                    </a>
                </Row>

                <Row width={'100%'} margin={'20px 0 20px'} justify={'center'}>
                    <Body4 color={'#545454'} fontWeight={'bold'}>
                        1522-7966
                    </Body4>
                </Row>
                <Row width={'100%'} margin={'0 0 20px'} justify={'center'}>
                    <Body3 color={'#545454'}>카카오플러스 친추 '집꾸미기'</Body3>
                </Row>
                <Row width={'100%'} justify={'center'} margin={'0 0 36px'} textAlign={'center'}>
                    <Body3 color={'#545454'}>
                        OPEN AM 10:00 - PM 5:00
                        <br />
                        SAT, SUN, HOLIDAY OFF.
                    </Body3>
                </Row>
            </UserInfo>
        </Row>
    );
}

function AppInduceBanner({ onCloseBanner }: { onCloseBanner: Function }) {
    const [display, setDisplay] = useState<'flex' | 'none'>('flex');
    return (
        <AppInduceBannerWrapper
            display={display}
            align={'center'}
            justify={'center'}
            width={'100%'}
            height={'56px'}
        >
            <Row
                className={'banner-main'}
                align={'center'}
                justify={'center'}
                width={'calc(100% - 200px)'}
                onClick={() => (location.href = '/promotion/view/1548')}
            >
                <Image
                    unoptimized
                    width={60}
                    height={32}
                    src="https://cdn.ggumim.co.kr/storage/20220802212913ZtSq9O4sVh.png"
                    alt="coupon-pack-icon"
                />
                <Body1 color={COLOR.BG} fontWeight={'bold'} margin={'0 16px'}>
                    지금 앱 다운로드하고 회원가입하면 할인 쿠폰 즉시 지급!
                </Body1>
                <Image
                    unoptimized
                    width={60}
                    height={32}
                    src="https://cdn.ggumim.co.kr/storage/20220802212913ZtSq9O4sVh.png"
                    alt="coupon-pack-icon"
                />
            </Row>
            <Row
                className="banner-close"
                width={'80px'}
                height={'64px'}
                align={'center'}
                justify={'center'}
                onClick={(_e) => {
                    setDisplay('none');
                    onCloseBanner();
                }}
            >
                <Image
                    unoptimized
                    width={24}
                    height={24}
                    src="//cdn.ggumim.co.kr/storage/20210802143959mhRbBHlcNj.png"
                    alt="banner-close-icon"
                />
            </Row>
        </AppInduceBannerWrapper>
    );
}

const HeaderTopWrapper = styled.div``;

export const _getCartCount = () => getCartCount().then((data) => (data ? data.countProduct : 0));

export default function Header() {
    const router = useRouter();
    const cookies = parseCookies();
    const [position, setPosition] = useState<Property.Position>('absolute');
    const [showAppInduceBanner, setAppInduceBanner] = useState(false);

    const member = useSelector((state: AppState) => state.common.member);
    const categoryTree = useSelector((state: AppState) => state.common.categoryTree?.sub);

    const isLogin = !!member.idx;
    const memberIdx = member?.idx || 0;
    const { show_store_nav } = router.query;
    const showStoreNav: boolean = show_store_nav === 'true' || router.pathname === '/furniture';

    const { data: cartCount } = useSWRImmutable('/purchase/v2/cart/count', _getCartCount);

    const { data: purchaseHistoryCount } = useSWRImmutable(
        '/purchase/v1/history/countByStatus',
        isLogin ? getPurchaseHistoryCountByStatus : () => null,
    );

    const purchaseCountInProgress = purchaseHistoryCount
        ? purchaseHistoryCount.notDeposit +
          purchaseHistoryCount.prepare +
          purchaseHistoryCount.deliveryWait +
          purchaseHistoryCount.delivery
        : 0;

    const isFurnitureActive =
        router.pathname.startsWith('/furniture') ||
        (router.pathname.startsWith('/promotion/view') && router.query['show_menu'] === 'true');
    const isStarActive = router.pathname.startsWith('/star');
    const isStoryActive = router.pathname.startsWith('/story');
    const isCommercePromotion = router.pathname.startsWith('/commerce/promotion');

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const isShowBanner = !cookies['app-induce-banner'];

        if (isShowBanner) {
            setPosition('absolute');
            setAppInduceBanner(isShowBanner);
        }

        if (member) {
            const ReactPixel = require('react-facebook-pixel');
            ReactPixel.default.init('1952412534769617');
            ReactPixel.default.pageView();
        }

        initAirBridge(memberIdx);
    }, []);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        let checkPoint = showAppInduceBanner
            ? MAIN_HEADER_HEIGHT + APP_BANNER_HEIGHT
            : MAIN_HEADER_HEIGHT;

        const onScroll = (_e: Event) => {
            const scrollY = window.scrollY;

            if (scrollY >= checkPoint && position === 'absolute') {
                setPosition('sticky');
            } else if (scrollY < checkPoint && position === 'sticky') {
                setPosition('absolute');
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [position, showAppInduceBanner]);

    return (
        <>
            <HeaderContainer id={'header-container'}>
                {showAppInduceBanner && (
                    <AppInduceBanner
                        onCloseBanner={() => {
                            setAppInduceBanner(false);
                            setCookie(null, 'app-induce-banner', '1', {
                                maxAge: 21600, // 6시간
                                path: '/',
                            });
                        }}
                    />
                )}
                <HeaderWrapper position={position}>
                    <HeaderTopWrapper id={'header-top-wrapper'}>
                        <HeaderMainWrapper>
                            <LogoWrapper>
                                <a href={'/'}>
                                    <Logo width={'190px'} height={'48px'} />
                                </a>
                            </LogoWrapper>
                            <Autocomplete />
                            <HeaderRight align={'center'}>
                                <a href={'/member/view'}>
                                    <Row position={'relative'} padding={'0 20px'}>
                                        <Image
                                            unoptimized
                                            src={
                                                '//cdn.ggumim.co.kr/storage/20220405154816wIeKzpSF8u.png'
                                            }
                                            width={'36px'}
                                            height={'36px'}
                                        />
                                    </Row>
                                </a>
                                <CartIcon cartCount={cartCount} />
                            </HeaderRight>
                        </HeaderMainWrapper>
                        <HeaderLine />
                        <Row boxShadow={COLOR_V4.SHADOW}>
                            <HeaderSubWrapper display={position === 'sticky' ? 'none' : 'flex'}>
                                <GlobalMenuList
                                    categoryTree={categoryTree}
                                    isFurnitureActive={isFurnitureActive}
                                    isStarActive={isStarActive}
                                    isStoryActive={isStoryActive}
                                    isCommercePromotion={isCommercePromotion}
                                />
                                <HeaderRight>
                                    {!isLogin ? (
                                        <>
                                            <a
                                                href={`/login?ref=${encodeURIComponent(
                                                    router.asPath.slice(1).replace(/\//gi, '|'),
                                                )}`}
                                            >
                                                <LoginButton>
                                                    <Body3
                                                        color={COLOR_V4.WHITE}
                                                        fontWeight={'bold'}
                                                    >
                                                        로그인/가입
                                                    </Body3>
                                                </LoginButton>
                                            </a>
                                            <a href={'/member/order_nonmember'}>
                                                <Row>
                                                    <Body2
                                                        color={COLOR_V4.GRAY900}
                                                        fontWeight={'bold'}
                                                    >
                                                        비회원 주문조회
                                                    </Body2>
                                                </Row>
                                            </a>
                                        </>
                                    ) : (
                                        <MemberInfo
                                            member={member}
                                            purchaseCountInProgress={purchaseCountInProgress}
                                            headerPosition={position}
                                        />
                                    )}
                                    <a href={'/help'}>
                                        <Row
                                            width={'1px'}
                                            height={'12px'}
                                            background={'#c5c8ce'}
                                            margin={'0 12px'}
                                        ></Row>
                                        <Row>
                                            <Body2 color={COLOR_V4.GRAY900} fontWeight={'bold'}>
                                                고객센터
                                            </Body2>
                                        </Row>
                                    </a>
                                </HeaderRight>
                            </HeaderSubWrapper>
                        </Row>
                    </HeaderTopWrapper>
                </HeaderWrapper>
            </HeaderContainer>

            {position === 'sticky' && (
                <HeaderStickyWrapper id={'header-fixed-wrapper'}>
                    <Row boxShadow={COLOR_V4.SHADOW}>
                        <HeaderMainWrapper>
                            <LogoWrapper>
                                <a href={'/'}>
                                    <Logo
                                        margin={'0 5px 0 0'}
                                        width={'40px'}
                                        height={'40px'}
                                        imageSrc={
                                            'https://cdn.ggumim.co.kr/storage/20211118152439veoaKuTjUh.png'
                                        }
                                    />
                                </a>
                            </LogoWrapper>
                            <GlobalMenuList
                                padding={'0 0 0 20px'}
                                categoryTree={categoryTree}
                                isFurnitureActive={isFurnitureActive}
                                isStarActive={isStarActive}
                                isStoryActive={isStoryActive}
                                isCommercePromotion={isCommercePromotion}
                            />
                            <HeaderRight align={'center'}>
                                <AutocompleteSmall />
                                <CartIcon
                                    width={'32px'}
                                    height={'32px'}
                                    padding={'0 27px 0 0'}
                                    cartCount={cartCount}
                                    iconImage={
                                        '//cdn.ggumim.co.kr/storage/20220405154434B4uwWl2qr6.png'
                                    }
                                />
                                {!isLogin ? (
                                    <>
                                        <a
                                            href={`/login?ref=${router.asPath
                                                .slice(1)
                                                .replace(/\//gi, '|')}`}
                                        >
                                            <LoginButton>
                                                <Body3 color={COLOR_V4.WHITE} fontWeight={'bold'}>
                                                    로그인/가입
                                                </Body3>
                                            </LoginButton>
                                        </a>
                                        <a href={'/member/order_nonmember'}>
                                            <Row padding={'0 0 0 0'}>
                                                <Body2 color={COLOR_V4.GRAY900} fontWeight={'bold'}>
                                                    비회원 주문조회
                                                </Body2>
                                            </Row>
                                        </a>
                                    </>
                                ) : (
                                    <MemberInfo
                                        member={member}
                                        purchaseCountInProgress={purchaseCountInProgress}
                                        headerPosition={position}
                                    />
                                )}
                                <a href={'/help'}>
                                    <Row
                                        width={'1px'}
                                        height={'12px'}
                                        background={'#c5c8ce'}
                                        margin={'0 12px'}
                                    ></Row>
                                    <Row>
                                        <Body2 color={COLOR_V4.GRAY900} fontWeight={'bold'}>
                                            고객센터
                                        </Body2>
                                    </Row>
                                </a>
                            </HeaderRight>
                        </HeaderMainWrapper>
                    </Row>
                </HeaderStickyWrapper>
            )}

            {showStoreNav && <StoreNav />}
        </>
    );
}
