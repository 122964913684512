import React, { useRef, useState, useCallback } from 'react';

interface OptionPropType {
    option: any;
    index: number;
}

interface UseAutocompletePropType {
    id: string;
    options: object[];
    initialInput: string;
    onOpen?: (event: any) => void;
    onClose?: (event: any, reason: string) => void;
    onInputChange?: (newValue: string) => void;
    onOptionSelect?: (option: any) => void;
    onHighlightChange?: (event: any, option: any, reason: string) => void;
    onSearch?: (value: string) => void;
}

export const useAutocomplete = (props: UseAutocompletePropType) => {
    const {
        id,
        options = [],
        initialInput,
        onOpen,
        onClose,
        onInputChange,
        onOptionSelect,
        onHighlightChange,
        onSearch,
    } = props;

    const ignoreFocus = useRef(false); // true일 경우 focus 처리 무시
    const defaultHighlighted = -1;
    const highlightedIndexRef = useRef(defaultHighlighted);
    const listboxRef = useRef<HTMLUListElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [inputValue, setInputValue] = useState(initialInput);
    const [focused, setFocused] = useState(false); // input 창 focus되었는지 여부
    const [focusedTag, setFocusedTag] = useState(-1); // 생성된 자동완성 tag중 focus된 게 어느 것인지
    const [open, setOpen] = useState(false);

    const handleOpen = (event: any) => {
        if (open) {
            return;
        }

        setOpen(true);

        if (onOpen) {
            onOpen(event);
        }
    };

    const handleClose = (event: any, reason: string) => {
        if (!open) {
            return;
        }

        setOpen(false);

        if (onClose) {
            onClose(event, reason);
        }
    };

    const setHighlightedIndex = ({
        event,
        index,
        reason = 'auto',
    }: {
        event?: any;
        index: number;
        reason?: string;
    }) => {
        if (reason !== 'mouse') {
            highlightedIndexRef.current = index;
        }

        if (onHighlightChange) {
            onHighlightChange(event, index === -1 ? null : options[index], reason);
        }

        if (!listboxRef.current) {
            return;
        }

        const prev = listboxRef.current.querySelector('[role="option"].focused');

        if (prev) {
            prev.classList.remove('focused');
            prev.classList.remove('focusVisible');
        }

        const listboxNode = listboxRef.current.parentElement?.querySelector('[role="listbox"]'); // "No results"

        if (!listboxNode) {
            return;
        }

        if (index === -1) {
            listboxNode.scrollTop = 0;
            return;
        }

        const option = listboxRef.current.querySelector(
            `[data-option-index="${index}"]`,
        ) as HTMLElement;

        if (!option) {
            return;
        }

        option.classList.add('focused');

        if (reason === 'keyboard') {
            option.classList.add('focusVisible');
        } // Scroll active descendant into view.
        // Logic copied from https://www.w3.org/WAI/ARIA/apg/example-index/combobox/js/select-only.js
        //
        // Consider this API instead once it has a better browser support:
        // .scrollIntoView({ scrollMode: 'if-needed', block: 'nearest' });

        if (listboxNode.scrollHeight > listboxNode.clientHeight && reason !== 'mouse') {
            const element = option;
            const scrollBottom = listboxNode.clientHeight + listboxNode.scrollTop;
            const elementBottom = element.offsetTop + element.offsetHeight;

            if (elementBottom > scrollBottom) {
                listboxNode.scrollTop = elementBottom - listboxNode.clientHeight;
            } else if (element.offsetTop < listboxNode.scrollTop) {
                listboxNode.scrollTop = element.offsetTop;
            }
        }
    };

    function validOptionIndex(index: number, direction: string) {
        if (!listboxRef.current || index === -1) {
            return -1;
        }

        let nextFocus = index;

        while (true) {
            // Out of range
            if (
                (direction === 'next' && nextFocus === options.length) ||
                (direction === 'previous' && nextFocus === -1)
            ) {
                return -1;
            }

            const option = listboxRef.current.querySelector(`[data-option-index="${nextFocus}"]`);

            if (option && !option.hasAttribute('tabindex')) {
                // Move to the next element.
                nextFocus += direction === 'next' ? 1 : -1;
            } else {
                return nextFocus;
            }
        }
    }

    const changeHighlightedIndex = ({
        event,
        diff,
        direction = 'next',
        reason = 'auto',
    }: {
        event?: any;
        diff: any;
        direction?: string;
        reason?: string;
    }) => {
        if (!open) {
            return;
        }

        const getNextIndex = () => {
            const maxIndex = options.length - 1;

            if (diff === 'reset') {
                return defaultHighlighted;
            }

            if (diff === 'start') {
                return 0;
            }

            if (diff === 'end') {
                return maxIndex;
            }

            const newIndex = highlightedIndexRef.current + diff;

            if (newIndex < 0) {
                return maxIndex;
            }

            if (newIndex > maxIndex) {
                return 0;
            }

            return newIndex;
        };

        const nextIndex = validOptionIndex(getNextIndex(), direction);
        setHighlightedIndex({
            index: nextIndex,
            reason,
            event,
        }); // Sync the content of the input with the highlighted option.
    };

    const findIndex = (array: any[], comp: (optionItem: any) => boolean) => {
        for (let i = 0; i < array.length; i += 1) {
            if (comp(array[i])) {
                return i;
            }
        }

        return -1;
    };

    const isOptionEqualToValue = (option: any, value: any) => option === value;

    const syncHighlightedIndex = useCallback(() => {
        if (!open) {
            return;
        }

        const valueItem = inputValue; // The popup is empty, reset

        if (options.length === 0 || valueItem == null) {
            changeHighlightedIndex({
                diff: 'reset',
            });
            return;
        }

        if (!listboxRef.current) {
            return;
        } // Synchronize the value with the highlighted index

        if (valueItem != null) {
            const itemIndex = findIndex(options, (optionItem) =>
                isOptionEqualToValue(optionItem, valueItem),
            );

            if (itemIndex === -1) {
                changeHighlightedIndex({
                    diff: 'reset',
                });
            } else {
                setHighlightedIndex({
                    index: itemIndex,
                });
            }

            return;
        } // Prevent the highlighted index to leak outside the boundaries.

        if (highlightedIndexRef.current >= options.length - 1) {
            setHighlightedIndex({
                index: options.length - 1,
            });
            return;
        } // Restore the focus to the previous index.

        setHighlightedIndex({
            index: highlightedIndexRef.current,
        }); // Ignore filteredOptions (and options, isOptionEqualToValue, getOptionLabel) not to break the scroll position
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // Only sync the highlighted index when the option switch between empty and not
        options.length, // Don't sync the highlighted index with the value when multiple
        // eslint-disable-next-line react-hooks/exhaustive-deps
        inputValue,
        changeHighlightedIndex,
        setHighlightedIndex,
        open,
        inputValue,
    ]);

    const handleListboxRef = (node: any) => {
        listboxRef.current = node;

        if (!node) {
            return;
        }

        syncHighlightedIndex();
    };

    const handleKeyDown = (other: any) => (event: React.KeyboardEvent) => {
        if (other.onKeyDown) {
            other.onKeyDown(event);
        }

        if (focusedTag !== -1 && ['ArrowLeft', 'ArrowRight'].indexOf(event.key) === -1) {
            setFocusedTag(-1);
            //focusTag(-1);
        } // Wait until IME is settled.

        if (event.which !== 229) {
            switch (event.key) {
                case 'ArrowDown':
                    // Prevent cursor move
                    event.preventDefault();
                    changeHighlightedIndex({
                        diff: 1,
                        direction: 'next',
                        reason: 'keyboard',
                        event,
                    });
                    handleOpen(event);
                    break;

                case 'ArrowUp':
                    // Prevent cursor move
                    event.preventDefault();
                    changeHighlightedIndex({
                        diff: -1,
                        direction: 'previous',
                        reason: 'keyboard',
                        event,
                    });
                    handleOpen(event);
                    break;

                case 'Enter':
                    if (highlightedIndexRef.current !== -1 && open) {
                        const option = options[highlightedIndexRef.current];

                        event.preventDefault();

                        if (onOptionSelect) {
                            onOptionSelect(option);
                        }
                    } else {
                        handleSearch();
                    }
                    break;

                default:
            }
        }
    };

    const handleMouseDown = (event: any) => {
        if (event.target.getAttribute('id') !== id) {
            event.preventDefault();
        }
    };

    const handleClick = () => {};

    const handleFocus = (event: any) => {
        setFocused(true);

        if (/*openOnFocus && */ !ignoreFocus.current) {
            handleOpen(event);
        }
    };

    const handleBlur = (event: any) => {
        setFocused(false);
        //firstFocus.current = true;
        ignoreFocus.current = false;

        handleClose(event, 'blur');
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        if (inputValue !== newValue) {
            setInputValue(newValue);

            if (onInputChange) {
                onInputChange(newValue);
            }
        }
    };

    const handleInputMouseDown = () => {};

    const handleClear = () => {
        ignoreFocus.current = true;
        setInputValue('');

        if (onInputChange) {
            onInputChange('');
        }
    };

    const handleSearch = () => {
        if (inputValue === '') {
            return;
        }
        if (onSearch) {
            onSearch(inputValue);
        }
    };

    const handleOptionMouseOver = (event: any) => {
        setHighlightedIndex({
            event,
            index: Number(event.currentTarget.getAttribute('data-option-index')),
            reason: 'mouse',
        });
    };

    const handleOptionTouchStart = () => {};

    const handleOptionClick = (event: any) => {
        const index = Number(event.currentTarget.getAttribute('data-option-index'));

        if (onOptionSelect) {
            onOptionSelect(options[index]);
        }
    };

    return {
        getRootProps: (other = {}) => ({
            onKeyDown: handleKeyDown(other),
            onMouseDown: handleMouseDown,
            onClick: handleClick,
        }),
        getInputProps: () => ({
            id,
            onBlur: handleBlur,
            onFocus: handleFocus,
            onChange: handleInputChange,
            onMouseDown: handleInputMouseDown,
            value: inputValue,
            ref: inputRef,
            role: 'combobox',
        }),
        getClearProps: () => ({
            tabIndex: -1,
            onClick: handleClear,
        }),
        getSearchProps: () => ({
            onClick: handleSearch,
        }),
        getListboxProps: () => ({
            ref: handleListboxRef,
            role: 'listbox',
            onMouseDown: (event: any) => {
                // Prevent blur
                event.preventDefault();
            },
        }),
        getOptionProps: ({ index, option }: OptionPropType) => ({
            key: JSON.stringify(option),
            tabIndex: -1,
            onMouseOver: handleOptionMouseOver,
            onClick: handleOptionClick,
            onTouchStart: handleOptionTouchStart,
            'data-option-index': index,
            role: 'option',
        }),
        focused: focused || focusedTag !== -1, // input 창 포커스되어있거나 자동완성된 tag중 하나에 focus가 가 있나?
        open,
        inputValue,
        setInputValue,
    };
};
