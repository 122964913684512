import styled from 'styled-components';
import { COLOR_V4 } from '../../lib/styles/colors';

interface ILineProps {
    margin?: string | number;
    background?: string;
}

export const Line = styled.div<ILineProps>`
    width: 100%;
    height: 1px;
    background: ${({ background }) => background || COLOR_V4.GRAY200};
    ${({ margin }) => (margin ? `margin: ${margin}` : '')};
`;
