import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import COLOR from '../../lib/styles/colors';

const SCROLL_BOX_MIN_HEIGHT = 20;

const ScrollContainer = styled.div`
    position: relative;
    height: 100%;
`;

const ScrollHost = styled.div`
    overflow: auto;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;

    ::-webkit-scrollbar {
        display: none;
    }
`;

const ScrollThumb = styled.div`
    width: 3px;
    margin-right: 3px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50px;
    background-color: ${COLOR.GREY5};
`;

interface CustomScrollDivProps {
    children?: React.ReactNode;
}

export const CustomScrollDiv = ({ children }: CustomScrollDivProps) => {
    const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT);
    const [scrollBoxTop, setScrollBoxTop] = useState(0);
    const scrollHostRef = useRef<HTMLDivElement>(null);

    const handleScroll = useCallback(() => {
        if (!scrollHostRef) {
            return;
        }
        const scrollHostElement = scrollHostRef.current;
        if (scrollHostElement === null) return;
        const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;

        let newTop = (scrollTop / scrollHeight) * offsetHeight;
        newTop = Math.min(newTop, offsetHeight - scrollBoxHeight - 77);
        setScrollBoxTop(newTop);
    }, []);

    useEffect(() => {
        const scrollHostElement = scrollHostRef.current;
        if (scrollHostElement === null) return;
        const { clientHeight, scrollHeight } = scrollHostElement;
        const scrollThumbPercentage = clientHeight / scrollHeight;
        const scrollThumbHeight = Math.max(
            scrollThumbPercentage * clientHeight,
            SCROLL_BOX_MIN_HEIGHT,
        );
        setScrollBoxHeight(scrollThumbHeight);
        scrollHostElement.addEventListener('scroll', handleScroll, true);
        return function cleanup() {
            scrollHostElement.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    return (
        <ScrollContainer>
            <ScrollHost ref={scrollHostRef}>{children}</ScrollHost>
            <ScrollThumb style={{ height: '80px', top: scrollBoxTop + 7 }} />
        </ScrollContainer>
    );
};
