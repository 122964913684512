import React from 'react';
import useCountDown from '../../lib/hooks/useCountDown';
import styled from 'styled-components';
import COLOR from '../../lib/styles/colors';

interface CountDownProps {
    secondLeft: number;
    setSecondLeft: (c: (c: number) => number) => void;
    whenDone: () => void;
}

const Container = styled.div`
    font-size: 14px;
    line-height: 17.53px;
    font-weight: 500;
    color: ${COLOR.GREY8};
`;

export const CountDown = ({ secondLeft, setSecondLeft, whenDone }: CountDownProps) => {
    const timeLeft = useCountDown({ secondLeft, setSecondLeft, whenDone });

    return <Container>{timeLeft}</Container>;
};
