import React, { useContext } from 'react';
import { GlobalModalDispatchContext } from './GlobalModalProvider';

export function useGlobalModal() {
    const { open, close } = useContext(GlobalModalDispatchContext);
    const openModal = (Component: React.ReactNode, props: any) => {
        open(Component, props);
    };
    const closeModal = (Component: React.ReactNode) => {
        close(Component);
    };
    return {
        openModal,
        closeModal,
    };
}
