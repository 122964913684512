import { useEffect, useRef } from 'react';

interface UseCountDownProps {
    secondLeft: number;
    setSecondLeft: (c: (c: number) => number) => void;
    whenDone: () => void;
}

export default function useCountDown({ secondLeft, setSecondLeft, whenDone }: UseCountDownProps) {
    const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

    const start = () => {
        if (intervalRef.current !== null) {
            return;
        }
        intervalRef.current = setInterval(() => {
            setSecondLeft((c: number) => c - 1);
        }, 1000);
    };

    const stop = () => {
        if (intervalRef.current === null) {
            return;
        }

        clearInterval(intervalRef.current);
        intervalRef.current = null;
        whenDone();
    };

    const fillZero = (width: number, num: number) => {
        const str = num.toString();
        return str.length >= width ? str : new Array(width - str.length + 1).join('0') + str;
    };

    useEffect(() => {
        start();
        if (secondLeft === 0) {
            stop();
        }
        return () => {
            if (intervalRef.current === null) return;
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        };
    }, [secondLeft]);

    return `${fillZero(2, Math.floor(secondLeft / 60))}:${fillZero(2, secondLeft % 60)}`;
}
