import { userApi } from '../lib/request';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface IUser {
    idx: number;
    name: string;
    nickname: string;
    recentProfile: string;
    recentShout: string;
    recentCover: string;
    website: string;
    email: string;
    gradeIdx: number;
    specialGradeIdx: number;
    point: number;
    status: string;
    accountType: string;
    phoneNumber: string;
    checkTerm: number;
    isConfirm: number;
    customsCode: string;
}
export interface IUserNicknameInfo {
    idx: number;
    nickname: string;
}

export interface PhoneCertificationRequest {
    phoneNumber: string;
}

export interface PhoneCertificationResponse {
    phoneNumber: string;
    key: string;
    idx: number;
}

interface GetSocialLoginResultRequest {
    type: string;
    id?: string;
    code?: string;
    token?: string;
    targetMemberIdxes?: number[];
    phpSessionId?: string;
    redirectUrl?: string;
}

export async function getUserInfo(_req?: AxiosRequestConfig): Promise<IUser | null> {
    try {
        const response: AxiosResponse<IUser> = await userApi.get('/user/v1/auth/info');

        return response.data;
    } catch (e) {
        return null;
    }
}

export async function searchByNickname(req?: AxiosRequestConfig): Promise<IUserNicknameInfo[]> {
    try {
        const response: AxiosResponse<IUserNicknameInfo[]> = await userApi.get(
            '/user/v1/searchByNickname',
            { params: req?.params },
        );

        return response.data;
    } catch (e) {
        return [];
    }
}

export async function getPhoneCertification({
    phoneNumber,
}: PhoneCertificationRequest): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.post(
            `/user/v1/confirm/key?phoneNumber=${phoneNumber}`,
        );
        return response;
    } catch (e) {
        return e;
    }
}

export async function confirmPhoneCertification({
    phoneNumber,
    key,
    idx,
}: PhoneCertificationResponse): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.post(
            `/user/v1/confirm/key/${idx}?phoneNumber=${phoneNumber}&key=${key}`,
        );
        return response;
    } catch (e) {
        return e;
    }
}

export async function loginByEmail(data: {
    email: string;
    password: string;
    recaptchaToken: string;
    phpSessionId?: string;
}): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.post('/user/v1/auth/integratedLogin', data);
        return response;
    } catch (e: any) {
        return e.response;
    }
}

export async function signup(signupData: any): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.post(
            `/user/v1/auth/integratedSignup`,
            signupData,
        );
        return response.data;
    } catch (e) {
        return false;
    }
}

export async function getTerm(): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.get(`/user/v1/term/signup/terms`);
        return response.data;
    } catch (e) {
        return false;
    }
}

export async function getPrivacy(): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.get(`/user/v1/term/signup/privacy`);
        return response.data;
    } catch (e) {
        return false;
    }
}

export async function checkPhoneExist({ phoneNumber }: any): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.post(
            `/user/v1/exist/phoneNumber?phoneNumber=${phoneNumber}`,
        );
        return response;
    } catch (e) {
        return e;
    }
}

export async function checkNicknameExist(nickname: any): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.get(
            `/user/v1/exist/nickname?nickname=${nickname}`,
        );
        return response.data;
    } catch (e) {
        return false;
    }
}

export async function checkEmailExist({ email }: any): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.post(`/user/v1/exist/email`, {
            email,
            accountType: 4,
        });

        return response;
    } catch (e: any) {
        return e.response;
    }
}

export async function getSocialLoginInfo(): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.get(`/user/v1/auth/social-login-info`);
        return response.data;
    } catch (e) {
        return e;
    }
}

export async function findLoginBefore({ phoneNumber }: any): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.post(`/user/v1/account/find/login/before`, {
            phoneNumber,
        });

        return response.data;
    } catch (e) {
        return e;
    }
}

export async function sendPasswordResetEmail({ email, redirect }: any): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.post(
            `/user/v1/reset/password/email?email=${email}&redirect=${redirect}`,
        );

        return response.data;
    } catch (e) {
        return e;
    }
}

export async function updateUserInfo(data: {
    email: string;
    phoneNumber: string;
    checkMarketingPolicy: boolean;
}) {
    try {
        const response: AxiosResponse = await userApi.patch(
            `/user/v1/updateEssentialInfo?&email=${data.email}&phoneNumber=${data.phoneNumber}&checkMarketingPolicy=${data.checkMarketingPolicy}`,
        );

        return response.data;
    } catch (e: any) {
        return e.response;
    }
}

/*미완성*/
export async function loginBySocial({
    type,
    id,
    code,
    token,
    targetMemberIdxes,
    phpSessionId,
    redirectUrl,
}: GetSocialLoginResultRequest): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.post(`/user/v1/auth/integratedLogin/social`, {
            type,
            id,
            code,
            token,
            targetMemberIdxes,
            phpSessionId,
            redirectUrl,
        });

        return response;
    } catch (e: any) {
        return e.response;
    }
}
/*미완성*/
export async function socialSignup(signupData: any): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.post(
            `/user/v1/auth/integratedSignup/social`,
            signupData,
        );
        return response.data;
    } catch (e: any) {
        return e.response;
    }
}
/*미완성*/
// 현재 데이터는 제대로 오는데 404여서 임시로 catch에서 data 받는다.
export async function getSocialInfo({ type, token }: any): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.get(
            `/user/v1/auth/login/${type}?token=${token}`,
        );
        return response.data;
    } catch (e: any) {
        return e.response.data;
    }
}

export async function checkPasswordResetTokenValid(passwordResetToken: string): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.get(
            `user/v1/reset/password/valid?token=${passwordResetToken}`,
        );
        return response.data;
    } catch (e: any) {
        return e.response;
    }
}

export async function resetPassword({ token, newPassword }: any): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.patch(`/user/v1/reset/password`, {
            token,
            newPassword,
        });
        return response;
    } catch (e: any) {
        return e.response;
    }
}

export async function awakeSleeperAccount(): Promise<any> {
    try {
        const response: AxiosResponse = await userApi.patch(`/user/v1/active`);
        return response;
    } catch (e: any) {
        return e.response;
    }
}

export async function getNonMemberToken() {
    try {
        const response: AxiosResponse<{ key: string }> = await userApi.get(
            `/user/v1/auth/token/isLogin`,
        );
        return response.data;
    } catch (e) {
        return null;
    }
}
