import { useState, useEffect, useRef } from 'react';
import { useAutocomplete } from './useAutocomplete';
import {
    getKeywordSearch,
    getSearchSuggest,
    deleteRecentKeyword,
    TSearchSuggestion,
    TSearchCategory,
    ISearchSuggestResponse,
    ISearchKeywordResponse,
    TRecentKeyword,
} from '../../api/search';
import styled from 'styled-components';
import { Button, Row, Col, Line, Body4, Caption2, Body2 } from '../layout';
import { Search, Delete, Shopping, Door, Photo, Bracket } from '../../lib/Icons';
import COLOR from '../../lib/styles/colors';
import { go } from '../../lib/utils';
import { useRouter } from 'next/router';
import { addEllipsis } from '../../lib/utils';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const Root = styled.div`
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    color: ${COLOR.GREY8};

    line-height: 20px;
    letter-spacing: -0.02em;

    .simplebar-track.simplebar-vertical {
        margin-top: 15px;
        margin-bottom: 15px;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;

    width: 420px;
    height: 48px;
    background: ${COLOR.GREY1};
    border: 1px solid ${COLOR.GREY4};
    border-radius: 100px;
    padding: 15px 10px 15px 10px;
`;

const Input = styled.input`
    background: ${COLOR.GREY1};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;

    color: ${COLOR.GREY8};
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: -0.02em;

    &::placeholder {
        color: ${COLOR.GREY5};
    }
`;

const Listbox = styled.ul`
    /*max-height: 400px;*/
    width: 420px;
    box-sizing: border-box;
    border: 1px solid ${COLOR.GREY1};
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    /*padding: 15px 18px;*/
    margin: 3px 0 0;

    position: absolute;
    list-style: none;
    background: #fff;

    overflow: auto;
    z-index: 1;
`;

const ListItem = styled.li<{ height: string }>`
    padding: 0 18px;
    height: ${(props) => props.height};
    &.focused {
        background-color: #e6f7ff;
        cursor: pointer;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 28px;
    width: 28px;
    border-radius: 100px;
    background: ${COLOR.GREY3};
`;

export const Autocomplete = () => {
    const router = useRouter();
    const [recentSearchList, setRecentSearchList] = useState<TRecentKeyword[]>([]);
    const [searchSuggestList, setSearchSuggestList] = useState<TSearchSuggestion[]>([]);
    const [options, setOptions] = useState<
        TRecentKeyword[] | (TSearchSuggestion | TSearchCategory)[]
    >([]);
    const [categorySuggestList, setCategorySuggestList] = useState<TSearchCategory[]>([]);

    const requestType = useRef('');

    const getSearchResult = async (keyword: string) => {
        if (keyword === '') {
            return;
        }

        const searchSuggestListRes: ISearchSuggestResponse | null = await getSearchSuggest({
            params: { type: requestType.current, keyword },
        });
        if (searchSuggestListRes) {
            setCategorySuggestList(searchSuggestListRes.categories);
            setSearchSuggestList(
                searchSuggestListRes.suggestions.filter((searchRes: any) => searchRes.type !== 4),
            );
            setOptions([...searchSuggestListRes.categories, ...searchSuggestListRes.suggestions]);
        }
    };

    const getRecentSearch = async () => {
        const recentSearchListRes: ISearchKeywordResponse | null = await getKeywordSearch({
            params: { keywordCount: 10 },
        });
        if (recentSearchListRes) {
            setRecentSearchList(recentSearchListRes.recentKeywords);
            setOptions(recentSearchListRes.recentKeywords);
        }
    };

    const deleteRecentSearch = async (idx: number) => {
        setRecentSearchList(recentSearchList.filter((recentSearch) => recentSearch.idx !== idx));
        setOptions(recentSearchList.filter((recentSearch) => recentSearch.idx !== idx));
        deleteRecentKeyword({
            params: { itemIdx: idx, keywordCount: 10 },
        });
    };

    const deleteRecentSearchAll = () => {
        setRecentSearchList([]);
        setOptions([]);
        deleteRecentKeyword({});
    };

    useEffect(() => {
        setInputValue(router.query.q ? (router.query.q as string) : '');
    }, [router.query.q]);

    const {
        getRootProps,
        getInputProps,
        getClearProps,
        getSearchProps,
        getListboxProps,
        getOptionProps,
        focused,
        open,
        inputValue,
        setInputValue,
    } = useAutocomplete({
        id: 'customized-hook-demo',
        options,
        initialInput: router.query.q ? (router.query.q as string) : '',
        onInputChange: async (newValue: string) => {
            const hasSpecialCharacters = (str: string) => {
                const reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
                return str.match(reg);
            };
            if (hasSpecialCharacters(newValue)) {
                return;
            }

            if (newValue === '') {
                setCategorySuggestList([]);
                setSearchSuggestList([]);
                setOptions(recentSearchList);
                return;
            }
            getSearchResult(newValue);
        },

        onOptionSelect: (option: any) => {
            if ('depth1Idx' in option) {
                go(
                    `/furniture/more/${option.depth1Idx ? option.depth1Idx : ''}${
                        option.depth2Idx ? `_${option.depth2Idx}` : ''
                    }${option.depth3Idx ? `_${option.depth3Idx}` : ''}${
                        option.depth4Idx ? `_${option.depth4Idx}` : ''
                    }`,
                );
                return;
            }
            if ('suggestion' in option) {
                go(
                    `/search/results/?q=${encodeURIComponent(option.suggestion)}&type=${
                        getCategory(option.type).path
                    }`,
                );
                return;
            }
            const path = requestType.current === 'Content' ? 'star' : 'furniture';

            go(`/search/results/?q=${encodeURIComponent(option.searchText)}&type=${path}`);
        },

        onSearch: (value: string) => {
            go(`/search/results/?q=${encodeURIComponent(value)}&type=all`);
        },
    });

    const getRequestType = () => {
        const requestTypes = [
            { id: 1, path: 'mypage', requestType: 'Mypage' },
            { id: 2, path: 'furniture', requestType: 'Store' },
            { id: 3, path: 'star', requestType: 'Content' },
            { id: 4, path: 'story', requestType: 'Community' },
        ];

        const res = requestTypes.find((requestType) => router.asPath.includes(requestType.path));

        if (res === undefined) {
            requestType.current = 'Home';
            return;
        }

        requestType.current = res.requestType;
    };

    const getCategory = (type: number) => {
        switch (type) {
            case 1:
                return { name: '집들이', path: 'star' };
            case 2:
                return { name: '상품', path: 'furniture' };
            case 3:
                return { name: '사진', path: 'picture' };
            case 4:
                return { name: '커뮤니티', path: 'star' }; // pc에는 story 없으므로
            default:
                return { name: '', path: '' };
        }
    };

    useEffect(() => {
        if (open) {
            getRecentSearch();
            getRequestType();
        }
    }, [open]);

    return (
        <Root>
            <InputWrapper {...getRootProps()} className={focused ? 'focused' : ''}>
                <Button background={'inherit'} {...getSearchProps()}>
                    <Search color={COLOR.GREY7} width={32} height={32} />
                </Button>
                <Input
                    {...getInputProps()}
                    placeholder={
                        router?.pathname?.includes(`story`)
                            ? `인테리어 정보 검색`
                            : router?.pathname?.includes(`furniture`)
                            ? `내가 필요한 상품 검색`
                            : router?.pathname?.includes(`star`)
                            ? `인테리어 정보 검색`
                            : router?.pathname?.includes(`mypage`)
                            ? `인테리어 정보, 상품 검색`
                            : router?.pathname?.includes(`qna`)
                            ? `인테리어 정보 검색`
                            : `인테리어 정보, 상품 검색`
                    }
                />
                {inputValue !== '' ? (
                    <Button background={'inherit'} {...getClearProps()}>
                        <Delete color={COLOR.GREY4} width={24} height={24} />
                    </Button>
                ) : null}
            </InputWrapper>

            {open ? (
                inputValue !== '' ? (
                    categorySuggestList.length !== 0 || searchSuggestList.length !== 0 ? (
                        <Listbox {...getListboxProps()}>
                            <SimpleBar style={{ maxHeight: 400, padding: '15px 0' }}>
                                {categorySuggestList.map((categorySuggest, index) => {
                                    return (
                                        <ListItem
                                            height={'40px'}
                                            {...getOptionProps({
                                                option: categorySuggest,
                                                index,
                                            })}
                                        >
                                            <Row
                                                width={'100%'}
                                                height={'100%'}
                                                align={'center'}
                                                justify={'space-between'}
                                            >
                                                <Row align={'center'}>
                                                    <Body4
                                                        fontWeight={'regular'}
                                                        color={
                                                            categorySuggest.depth2Name
                                                                ? COLOR.GREY5
                                                                : COLOR.GREY10
                                                        }
                                                    >
                                                        {categorySuggest.depth1Name}
                                                    </Body4>
                                                    {categorySuggest.depth2Name && (
                                                        <Bracket
                                                            color={COLOR.GREY5}
                                                            width="14px"
                                                            height="14px"
                                                        />
                                                    )}
                                                    <Body4
                                                        fontWeight={'regular'}
                                                        color={
                                                            categorySuggest.depth3Name
                                                                ? COLOR.GREY5
                                                                : COLOR.GREY10
                                                        }
                                                    >
                                                        {categorySuggest.depth2Name}
                                                    </Body4>
                                                    {categorySuggest.depth3Name && (
                                                        <Bracket
                                                            color={COLOR.GREY5}
                                                            width="14px"
                                                            height="14px"
                                                        />
                                                    )}
                                                    <Body4
                                                        fontWeight={'regular'}
                                                        color={
                                                            categorySuggest.depth4Name
                                                                ? COLOR.GREY5
                                                                : COLOR.GREY10
                                                        }
                                                    >
                                                        {categorySuggest.depth3Name}
                                                    </Body4>
                                                    {categorySuggest.depth4Name && (
                                                        <Bracket
                                                            color={COLOR.GREY5}
                                                            width="14px"
                                                            height="14px"
                                                        />
                                                    )}
                                                    <Body4
                                                        fontWeight={'regular'}
                                                        color={COLOR.GREY10}
                                                    >
                                                        {categorySuggest.depth4Name}
                                                    </Body4>
                                                </Row>
                                                <Row align={'center'}>
                                                    <Caption2
                                                        margin={'0 2px 0 0'}
                                                        fontWeight={'regular'}
                                                        color={COLOR.GREY5}
                                                    >
                                                        카테고리
                                                    </Caption2>
                                                    <Search
                                                        color={COLOR.GREY7}
                                                        width={16}
                                                        height={16}
                                                    />
                                                </Row>
                                            </Row>
                                            {index !== categorySuggestList.length - 1 && (
                                                <Line margin={'0 0 0 0'} background={COLOR.GREY1} />
                                            )}
                                        </ListItem>
                                    );
                                })}
                                {categorySuggestList.length > 0 ? (
                                    <Line margin={'0 0 0 0'} background={COLOR.GREY3} />
                                ) : null}
                                {searchSuggestList.map((option, index) => {
                                    const _suggestion = addEllipsis(
                                        option.suggestion,
                                        25,
                                    ).toLowerCase();
                                    const strongKeywordStart = _suggestion.indexOf(
                                        inputValue.toLowerCase(),
                                    );

                                    const beforeKeyword = addEllipsis(
                                        option.suggestion,
                                        25,
                                    ).substring(0, strongKeywordStart);
                                    const afterKeyword = addEllipsis(
                                        option.suggestion,
                                        25,
                                    ).substring(strongKeywordStart + inputValue.length);
                                    const strongKeyword = addEllipsis(
                                        option.suggestion,
                                        25,
                                    ).substring(
                                        strongKeywordStart,
                                        strongKeywordStart + inputValue.length,
                                    );
                                    return (
                                        <ListItem
                                            height={'48px'}
                                            {...getOptionProps({
                                                option,
                                                index: index + categorySuggestList.length,
                                            })}
                                        >
                                            <Row
                                                align={'center'}
                                                justify={'space-between'}
                                                width={'100%'}
                                                height={'100%'}
                                            >
                                                <CategoryIcon type={option.type} />
                                                <Col width="80%">
                                                    <Body2
                                                        fontWeight={'regular'}
                                                        color={COLOR.GREY10}
                                                    >
                                                        {strongKeywordStart === -1 ? (
                                                            <>
                                                                {addEllipsis(option.suggestion, 25)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {beforeKeyword}
                                                                <Body2 color={COLOR.CORAL6}>
                                                                    {strongKeyword}
                                                                </Body2>
                                                                {afterKeyword}
                                                            </>
                                                        )}
                                                    </Body2>
                                                    <Caption2
                                                        fontWeight={'regular'}
                                                        color={COLOR.GREY5}
                                                    >
                                                        {getCategory(option.type).name}
                                                    </Caption2>
                                                </Col>

                                                <Button background={'inherit'}>
                                                    <Search
                                                        color={COLOR.GREY7}
                                                        width={18}
                                                        height={18}
                                                    />
                                                </Button>
                                            </Row>
                                            {index !== searchSuggestList.length - 1 && (
                                                <Line margin={'0 0 0 0'} background={COLOR.GREY1} />
                                            )}
                                        </ListItem>
                                    );
                                })}
                            </SimpleBar>
                        </Listbox>
                    ) : (
                        <></>
                    )
                ) : recentSearchList.length !== 0 ? (
                    <Listbox {...getListboxProps()}>
                        <SimpleBar style={{ maxHeight: 400, padding: '15px 18px' }}>
                            <Row align={'center'} justify={'space-between'}>
                                <Body4 fontWeight={'medium'} color={COLOR.GREY7}>
                                    최근 검색어
                                </Body4>
                                <Button onClick={() => deleteRecentSearchAll()}>
                                    <Body4
                                        fontWeight={'regular'}
                                        color={COLOR.GREY5}
                                        letterSpacing={'-0.04em'}
                                    >
                                        전체삭제
                                    </Body4>
                                </Button>
                            </Row>
                            <Line margin={'14px 0 4px 0'} background={COLOR.GREY3} />
                            {recentSearchList.map((option, index) => (
                                <ListItem height={'34px'} {...getOptionProps({ option, index })}>
                                    <Row
                                        align={'center'}
                                        justify={'space-between'}
                                        width={'100%'}
                                        height={'100%'}
                                    >
                                        <Body2 fontWeight={'regular'} color={COLOR.GREY10}>
                                            {addEllipsis(option.searchText, 25)}
                                        </Body2>
                                        <Button
                                            background={'inherit'}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                deleteRecentSearch(option.idx);
                                            }}
                                        >
                                            <Delete color={COLOR.GREY4} width={18} height={18} />
                                        </Button>
                                    </Row>
                                </ListItem>
                            ))}
                        </SimpleBar>
                    </Listbox>
                ) : (
                    <></>
                )
            ) : null}
        </Root>
    );
};

interface CategoryIconProps {
    type: number;
}

const CategoryIcon = (props: CategoryIconProps) => {
    switch (props.type) {
        case 1:
            return (
                <IconWrapper>
                    <Door color={COLOR.BG} />
                </IconWrapper>
            );
        case 2:
            return (
                <IconWrapper>
                    <Shopping color={COLOR.BG} />
                </IconWrapper>
            );
        case 3:
            return (
                <IconWrapper>
                    <Photo color={COLOR.BG} />
                </IconWrapper>
            );
        case 4:
            return (
                <IconWrapper>
                    <Door color={COLOR.BG} />
                </IconWrapper>
            );
        default:
            return <></>;
    }
};
