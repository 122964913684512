import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { productApi } from '../lib/request';

export type TProductTag = {
    type: string;
    tag: string;
    color?: string;
    backgroundColor?: string;
};

export enum OutsideType {
    OUTSIDE = 1, //외부몰 (미입점)
    INTERNAL_MALL = 2, //집꾸미기 (입점)
    OTHERS = 3, //과거 cafe 24 (기타)
}

export interface IProduct {
    idx: number;
    name: string;
    filename: string;
    brandName: string;
    discount: number;
    couponPrice: number | null;
    priceDiscount: number;
    priceOriginal: number;
    hasCoupon: boolean;
    isSoldout: boolean;
    isToday: boolean;
    isOutside: OutsideType;
    isFreeDelivery: boolean;
    isHopeDelivery: boolean;
    isGlobalDelivery: boolean;
    productTagList: TProductTag[];
    exactReviewScore: number;
    reviewCount: number;
    displayReviewScore: string;
    startDateTime?: string;
    endDateTime?: string;
    rank?: number;
}

export const DEFAULT_PRODUCT_SIZE = 20;

export function getStoreHomeFeed() {
    return productApi.get(`/product/v1/ad/store_home_feed`);
}

export interface Category {
    idx: number;
    name: string;
    depth: number;
    path: string;
    imageUrl: string;
    children: Category[];
}

export interface BestProductsWithTime {
    dateTime: string;
    timeStamp: number;
    products: BestProduct[];
}

export interface BestProduct extends IProduct {
    productViewType: string;
}

export const getCategories = async () => {
    try {
        const response: AxiosResponse<Category[]> = await productApi.get('/product/v1/category');
        return response.data;
    } catch (e) {
        throw e;
    }
};

export enum BestProductsType {
    YEARLY = 'yearly',
    HOURLY = 'hourly',
    CATEGORY = 'category',
}

export enum BestProductsWithTimeType {
    TOTAL = 'total',
    REALTIME = 'realtime',
}

interface GetBestProductsParams<T = BestProductsType | BestProductsWithTimeType> {
    type: T;
}

export const getBestProducts = async <T>({ type }: GetBestProductsParams<T>) => {
    try {
        const response: AxiosResponse<
            T extends BestProductsType ? BestProduct[] : BestProductsWithTime
        > = await productApi.get(`/product/v1/best/store/${type}`);
        return response.data;
    } catch (e) {
        return null;
    }
};

interface GetBestProductsByCategoryIdx {
    index: string | number;
}

export const getBestProductsByCategoryIdx = async ({ index }: GetBestProductsByCategoryIdx) => {
    try {
        const response: AxiosResponse<BestProductsWithTime> = await productApi.get(
            `/product/v1/best/store/category/${index}`,
        );
        return response.data;
    } catch (e) {
        throw e;
    }
};

export function getFurnitureBannerList(idx: number | string) {
    return productApi
        .get(`product/v1/banner/product/${idx}`)
        .then((res) => {
            if (res.data.code) {
                return null;
            }

            return res.data;
        })
        .catch((_err) => {});
}

export async function getOnlineShowRoomData(idx: number | string) {
    try {
        const res = await productApi.get(
            `product/v1/furniture-styling?furnitureIdx=${idx}&imageSize=Mobile`,
        );
        return res.data;
    } catch (_err) {
        console.log(_err);
    }
}

export async function getFurnitureTagList(idx: number | string) {
    try {
        const res = await productApi.get(`/product/v1/recommend/${idx}/tag`);
        return res.data;
    } catch (_err) {}
}

export async function reportReview(
    req: AxiosRequestConfig & { reviewIdx: number },
): Promise<boolean> {
    try {
        const response: AxiosResponse<boolean> = await productApi.post(
            `/product/v1/review/report/${req.reviewIdx}`,
            req.data,
        );

        if (response.status === 201) {
            return response.data;
        }
    } catch (e) {
        return false;
    }

    return false;
}

export type IReviewSummary = {
    score: number;
    scoreMap: { [key: number]: number };
    photoOnlyCount: number;
    isReviewWritable: boolean;
};

export async function getFurnitureReviewSummary(furnitureIdx: number | string) {
    try {
        const res = await productApi.get<IReviewSummary>(
            `/product/v1/product/${furnitureIdx}/reviews/summary`,
        );
        return res.data;
    } catch (_err) {
        return null;
    }
}

export type TReview = {
    commerceIdx: number;
    content: string;
    filename: string | null;
    furnitureIdx: number;
    furnitureReviewIdx: number;
    furnitureReviewLikeIdx: number | null;
    imageList: string[];
    likeCount: number;
    memberIdx: number;
    nickname: string;
    profile: string;
    reviewDateTime: string;
    score: number;
    unitName: string;
};

export const DEFAULT_REVIEW_SIZE = 5;
export const DEFAULT_PHOTO_REVIEW_SIZE = 30;

export async function getFurnitureReviews(
    furnitureIdx: number | string,
    req: AxiosRequestConfig & { params: { page: number; size: number; isPhotoOnly: boolean } },
) {
    try {
        const res = await productApi.get<TReview[]>(
            `/product/v1/product/${furnitureIdx}/reviews`,
            req,
        );
        return res.data;
    } catch (_err) {
        return [];
    }
}

export async function addFurnitureReviewLike(
    furnitureIdx: number | string,
    reviewIdx: number | string,
) {
    try {
        const res = await productApi.put(
            `/product/v1/review/${reviewIdx}?furnitureIdx=${furnitureIdx}`,
            {
                furnitureIdx,
                reviewIdx,
            },
        );
        return res.data;
    } catch (_err) {}
}

export async function deleteFurnitureReviewLike(
    furnitureReviewLikeIdx: number | string,
    reviewIdx: number | string,
) {
    try {
        const res = await productApi.delete(
            `/product/v1/review/${reviewIdx}?furnitureReviewLikeIdx=${furnitureReviewLikeIdx}`,
        );
        return res.data;
    } catch (_err) {}
}

export type TQna = {
    idx: number;
    furnitureIdx: number;
    nickname: string;
    answerAdminIdx: number;
    type: string;
    questionDescription: string;
    answerDescription: string;
    answerStatus: number;
    visibility: number;
    questionDateTime: string;
    answerDateTime: string;
    isMyQuestion: boolean;
};

export const DEFAULT_QNA_SIZE = 5;

export async function getFurnitureQna(
    furnitureIdx: number | string,
    req: AxiosRequestConfig & { params: { page: number; size: number } },
) {
    try {
        const res: AxiosResponse<TQna[]> = await productApi.get(
            `/product/v1/qna/${furnitureIdx}`,
            req,
        );
        return res.data;
    } catch (_err) {
        return [];
    }
}

export async function postFurnitureQna(req: AxiosRequestConfig) {
    try {
        const res = await productApi.post(`/product/v1/qna`, req.data);
        return res.data;
    } catch (e) {
        throw e;
    }
}

export async function getFurnitureRelationList(furnitureIdx: number | string) {
    try {
        const res = await productApi.get(`/product/v1/recommend/${furnitureIdx}/relation`);
        return res.data;
    } catch (_err) {}
}

export async function getFurniturePresetList(
    idx: number | string,
    req?: AxiosRequestConfig & { params: { offset: number; limit: number } },
) {
    try {
        const res: AxiosResponse<IProduct[]> = await productApi.get(
            `/product/v1/recommend/${idx}/preset`,
            req,
        );
        return res.data;
    } catch (_err) {
        return [];
    }
}

interface FurnitureBestProps {
    categoryIdx: number | string;
    offset?: number;
    limit?: number;
}

export async function getFurnitureBestList({
    categoryIdx,
    offset = 0,
    limit = 10,
}: FurnitureBestProps) {
    try {
        const res = await productApi.get(`/product/v1/recommend/${categoryIdx}/best`, {
            params: {
                offset,
                limit,
            },
        });
        return res.data;
    } catch (_err) {}
}

export async function downloadCoupon(couponIdx: number, productIdx: number) {
    try {
        const res = await productApi.post(
            `/product/v1/coupon?couponIdx=${couponIdx}&productIdx=${productIdx}`,
        );
        return res.data;
    } catch (_err) {}
}

export async function downloadAllCoupon(productIdx: number) {
    return productApi
        .post(`/product/v1/coupon/download/all?productIdx=${productIdx}`)
        .then((res) => res.data)
        .catch((_err) => {});
}

export async function getFurnitureOptionList(idx: number | string) {
    try {
        const res = await productApi.get(`/product/v1/product/${idx}/options`);
        return res.data;
    } catch (_err) {}
}

export async function getFurnitureExtraOptionList(idx: number | string) {
    try {
        const res = await productApi.get(`/product/v1/product/${idx}/extra`);
        return res.data;
    } catch (_err) {
        return [];
    }
}

export async function getFurnitureView(idx: number | string) {
    try {
        const res = await productApi.get(`/product/v1/product/${idx}`);
        return res.data;
    } catch (_err: any) {
        const { status } = _err.response?.data;

        if (status === 404) {
            return null;
        }
    }
}

export interface IDeliveryPolicy {
    deliveryCase: number;
    deliveryCaseName: string;
    deliveryCondition: number;
    deliveryGroup: number;
    deliveryIdx: number;
    deliveryLocalPrice: number;
    deliveryLocalType: number;
    deliveryLocalTypeName: string;
    deliveryName: string;
    deliveryPayment: number;
    deliveryPaymentName: string;
    deliveryPrice: number;
    deliveryType: number;
    deliveryTypeName: string;
    hopeDeliveryDateType: number;
    hopeDeliveryStart: number;
    hopeDeliveryTerm: number;
    isHopeDelivery: false;
    replaceDeliveryPrice: number;
    returnDeliveryIdx: number;
    returnDeliveryName: string;
    returnDeliveryPrice: number;
    returnWarehouse: string;
    useDeliveryLocalPayment: number;
}

export const getFurnitureDeliveryPolicy = async (idx: number | string) => {
    try {
        const response: AxiosResponse<IDeliveryPolicy> = await productApi.get(
            `/product/v1/delivery/${idx}`,
        );
        return response.data;
    } catch (e) {
        return null;
    }
};

export function getCommercePromotionData(idx: number) {
    return productApi.get(`/product/v1/commerce/promotion/${idx}`);
}

export async function getNewProductList(req: AxiosRequestConfig) {
    try {
        const response: AxiosResponse<IProduct[]> = await productApi.get(
            `/product/v1/best/home/new`,
            {
                params: req?.params,
            },
        );
        if (response.data && response.status === 200) {
            return response.data;
        }
        return null;
    } catch (e) {
        return null;
    }
}

export interface PromotionInfo {
    commercePromotionIdx: number;
    idx: number;
    imageUrl: string;
    name: string;
    products: IProduct[];
}

export const getFurnitureHomePromotion = async () => {
    try {
        const response: AxiosResponse<PromotionInfo[]> = await productApi.get(
            `/product/v1/promotion/storeHome`,
        );
        return response.data;
    } catch (e) {
        return null;
    }
};

export interface IBrandInfo {
    furnitureIdx: number;
    filename: string;
    brandIdx: number;
    brandListIdx: number;
    sequenceIdx: number;
    brandName: string;
    explanation: string;
    logo: string;
    productCount: number;
}

export const getBrandInfo = async (
    req: AxiosRequestConfig & {
        params: {
            brandIdx: number | string;
        };
    },
) => {
    try {
        const response: AxiosResponse<IBrandInfo> = await productApi.get(
            `product/v1/brand/${req.params.brandIdx}`,
        );
        return response.data;
    } catch (e) {
        throw e;
    }
};

export type TShowRoomItem = {
    idx: number;
    furnitureIdx: number;
    visibility: boolean;
    fileType: number;
    source: number;
    url: string;
    fileName: string;
    videoUrl: string;
    width: number;
    height: number;
    createdDateTime: string;
    updatedDateTime: string;
};

export interface IShowRoomData {
    totalCount: number;
    data: TShowRoomItem[];
}

export const DEFAULT_STYLING_RESULT_SIZE = 40;

export async function getBrandShowRoomData(
    brandIdx: number | string,
    req?: AxiosRequestConfig & { params: { offset: number; limit: number } },
) {
    try {
        const response: AxiosResponse<IShowRoomData> = await productApi.get(
            `/product/v1/furniture-styling/brand/${brandIdx}`,
            req,
        );
        return response.data;
    } catch (e) {
        console.log(e);
        throw e;
    }
}
