import React from 'react';
import { IconProps } from '.';

interface FilterIconProps extends IconProps {}

const FilterIcon = ({ color, width, height, style, ...args }: FilterIconProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            width="25"
            height="27"
            viewBox="0 0 25 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <path
                d="M13.3241 19.801C12.9268 20.3791 12.0732 20.3791 11.6759 19.801L2.75132 6.81643C2.29528 6.15292 2.77031 5.25 3.57543 5.25L21.4246 5.25C22.2297 5.25 22.7047 6.15292 22.2487 6.81643L13.3241 19.801Z"
                fill="#C5C8CE"
            />
            <rect x="10" y="14" width="5" height="13" rx="1" fill="#C5C8CE" />
        </svg>
    );
};

export default FilterIcon;
