import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    right: 50%;
    width: 200%;
`;

export const ToastContainer = () => {
    return <Container id="toast" />;
};
