import { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { COLOR_V4 } from '../../lib/styles/colors';

const LoadingWrapper = styled.div<{ top: string | number }>`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: ${(props) => props.top};
    left: 0;
    background: #fff;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    overscroll-behavior-y: none;
    padding-bottom: ${(props) => (props.top === '50px' ? '100px' : '180px')};

    img {
        position: fixed;
        width: 75px;
        height: 75px;
        opacity: 0;
        -webkit-animation: loading1 500ms infinite ease-in;
        -moz-animation: loading1 500ms infinite ease-in;
        -ms-animation: loading1 500ms infinite ease-in;
        animation: loading1 500ms infinite ease-in;

        &:nth-child(2) {
            animation-delay: 6s;
            -webkit-animation-delay: 6s;
            -webkit-animation: loading2 500ms infinite ease-in;
            -moz-animation: loading2 500ms infinite ease-in;
            -ms-animation: loading2 500ms infinite ease-in;
            animation: loading2 500ms infinite ease-in;
        }

        &:nth-child(3) {
            animation-delay: 7s;
            -webkit-animation-delay: 7s;
            -webkit-animation: loading3 500ms infinite ease-in;
            -moz-animation: loading3 500ms infinite ease-in;
            -ms-animation: loading3 500ms infinite ease-in;
            animation: loading3 500ms infinite ease-in;
        }

        &:nth-child(4) {
            animation-delay: 8s;
            -webkit-animation-delay: 8s;
            -webkit-animation: loading4 500ms infinite ease-in;
            -moz-animation: loading4 500ms infinite ease-in;
            -ms-animation: loading4 500ms infinite ease-in;
            animation: loading4 500ms infinite ease-in;
        }

        &:nth-child(5) {
            animation-delay: 9s;
            -webkit-animation-delay: 9s;
            -webkit-animation: loading5 500ms infinite ease-in;
            -moz-animation: loading5 500ms infinite ease-in;
            -ms-animation: loading5 500ms infinite ease-in;
            animation: loading5 500ms infinite ease-in;
        }
    }

    @keyframes loading1 {
        0% {
            opacity: 0;
        }
        20% {
            opacity: 1;
        }
    }

    @keyframes loading2 {
        20% {
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
    }

    @keyframes loading3 {
        40% {
            opacity: 0;
        }
        60% {
            opacity: 1;
        }
    }

    @keyframes loading4 {
        60% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
    }

    @keyframes loading5 {
        80% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

interface ILoadingProps {
    top: string | number;
}

export default class Loading extends Component<ILoadingProps> {
    render() {
        const { top } = this.props;

        return (
            <LoadingWrapper top={top}>
                <img src="//cdn.ggumim.co.kr/storage/20200908135125XgPjwQgZ21.png" />
                <img src="//cdn.ggumim.co.kr/storage/20200908135125WpeSKI6uA0.png" />
                <img src="//cdn.ggumim.co.kr/storage/20200908135125q45MtFg72c.png" />
                <img src="//cdn.ggumim.co.kr/storage/20200908135124XAWE5JEIqv.png" />
                <img src="//cdn.ggumim.co.kr/storage/20200908135125g2QIcpZXnM.png" />
            </LoadingWrapper>
        );
    }
}

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: ${COLOR_V4.DIM1};
    border-radius: 8px;
`;

const Spinner = styled.div`
    border: 4px solid ${COLOR_V4.GRAY50};
    border-top: 4px solid ${COLOR_V4.PINK500};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spinAnimation} 1s linear infinite;
`;

export function LoadingSpinner() {
    return (
        <SpinnerContainer>
            <Spinner />
        </SpinnerContainer>
    );
}
