import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export interface IOptionData {
    furnitureIdx: number;
    furnitureUnitIdx: number;
    optionName: string;
    optionNameList: string | null;
    parentName: string;
    count: number;
    price: number;
    countType: number;
    sold: number | boolean;
}

export interface IFunitureViewAmplitudeData {
    CategoryDepth1?: string;
    CategoryDepth2?: string;
    CategoryDepth3?: string;
    CategoryDepth4?: string;
    Content?: string;
    Discount?: number;
    HaveItem?: boolean;
    OriginalPrice?: number;
    Price?: number;
    ProductId?: number | string;
    UseMarketing?: boolean;
}

export interface IFurnitureState {
    selectedOptionList: IOptionData[];
    selectedExtraOptionList: IOptionData[];
    amplitudeData: IFunitureViewAmplitudeData;
}

const initialState: IFurnitureState = {
    selectedOptionList: [],
    selectedExtraOptionList: [],
    amplitudeData: {},
};

const furnitureSlice = createSlice({
    name: 'furniture',
    initialState,
    reducers: {
        setSelectedOptionList: (state: IFurnitureState, action) => {
            state.selectedOptionList = action.payload;
        },
        setSelectedExtraOptionList: (state: IFurnitureState, action) => {
            state.selectedExtraOptionList = action.payload;
        },
        removeOptionList: (state: IFurnitureState) => {
            state.selectedOptionList = [];
            state.selectedExtraOptionList = [];
        },
        setFurnitureViewAmplitudeData: (state: IFurnitureState, action) => {
            state.amplitudeData = action.payload;
        },
    },
    extraReducers: {
        [HYDRATE]: (state: IFurnitureState, action) => {
            return {
                ...state,
                ...action.payload.furniture,
            };
        },
    },
});

export const furnitureActions = furnitureSlice.actions;
export default furnitureSlice;
