import React from 'react';
import styled from 'styled-components';
import COLOR from '../../lib/styles/colors';
import { Row, Body3 } from '../layout';
import { LoginButton } from '.';

interface AskCSProps {
    isDisplayNone: boolean;
    margin: string;
    description: string;
    buttonText: string;
    onClick: (e: any) => void;

    buttonWidth?: string;
    buttonHeight?: string;
}

const AskCSStyle = styled(Row)<{ isDisplayNone: boolean; margin: string }>`
    margin: ${(props) => (props.margin ? props.margin : '0 0 0 0')};
    align-items: center;
    justify-content: center;
    display: ${(props) => props.isDisplayNone && 'none'};
`;

export const AskCS = (props: AskCSProps) => {
    return (
        <AskCSStyle isDisplayNone={props.isDisplayNone} margin={props.margin}>
            <Body3 fontWeight={'medium'} color={COLOR.GREY8} margin={'0 8px 0 0'}>
                {props.description}
            </Body3>
            <LoginButton
                width={props.buttonWidth !== undefined ? props.buttonWidth : '72px'}
                height={props.buttonHeight !== undefined ? props.buttonHeight : '30px'}
                background={COLOR.GREY2}
                borderRadius="20px"
                onClick={props.onClick}
                fontWeight="700"
                fontSize="13px"
                lineHeight="16px"
                color={COLOR.GREY11}
                title={props.buttonText}
            />
        </AskCSStyle>
    );
};
