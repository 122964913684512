// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN || 'https://f451cde8720e4bc0ade2ea0f14fef5c7@o154688.ingest.sentry.io/5938243',
    integrations: [
        new TracingIntegrations.BrowserTracing({
            // sentry-trace라는 http 헤더 포함 되므로 외부 api 에러 주의
            tracingOrigins: [/.+ggumim\.co\.kr$/],
        }),
    ],
    // 성능 모니터링 10%만
    tracesSampleRate: 0.1,
});
