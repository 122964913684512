import styled from 'styled-components';

interface IGridBoxProps {
    display?: string;
    gap?: string;
    templateColumns?: string;
    templateRows?: string;
    position?: string;
    top?: number | string;
    zIndex?: number;
    width?: number | string;
    height?: number | string;
    minWidth?: number | string;
    maxWidth?: number | string;
    minHeight?: number | string;
    margin?: number | string;
    padding?: number | string;
    border?: string;
    borderRadius?: string;
    borderBottom?: string;
    boxShadow?: string;
    textAlign?: string;
    cursor?: string;
    background?: string;
    overflow?: string;
}

export const GridBox = styled.div<IGridBoxProps>`
    display: ${({ display }) => display || `grid`};
    grid-template-columns: ${({ templateColumns }) => templateColumns || 'repeat(4, 1fr)'};
    ${({ templateRows }) => templateRows && `grid-template-rows: ${templateRows};`}
    ${({ gap }) => gap && `gap: ${gap};`}
    ${({ position }) => position && `position: ${position};`}
    ${({ top }) => top && `top: ${top};`}
    ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
    ${({ width }) => width && `width: ${width};`}
    ${({ height }) => height && `height: ${height};`}
    ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
    ${({ margin }) => margin && `margin: ${margin};`}
    ${({ padding }) => padding && `padding: ${padding};`}
    ${({ border }) => border && `border: ${border};`}
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}
    ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom};`}
    ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow};`}
    ${({ cursor }) => cursor && `cursor: ${cursor};`}
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
    ${({ background }) => background && `background: ${background};`}
    ${({ overflow }) => overflow && `overflow: ${overflow};`}
`;
