import { useEffect } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import css from 'styled-jsx/css';
import { LoginButton } from '../../../../components/signup/LoginButton';
import Image from 'next/image';
import Amplitude from 'amplitude-js';

// language=LESS
const style = css.global`
    .react-modal-overlay-alert {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 2000;
    }

    .react-modal-alert {
        z-index: 2000;
        background: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        min-width: 288px;
        border-radius: 11px;
        box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.13);
        top: 50% !important;
        left: 50% !important;
        width: 650px;
        height: 457px;
    }
`;

const ModalWrapper = styled.div`
    height: 373px;
    padding: 32px 0 0 0;
`;

const ScrollWrapper = styled.div`
    height: 357px;
    padding: 0 24px 0 24px;

    overflow-y: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        width: 4px;
        /* Chrome, Safari, Opera*/
    }

    ::-webkit-scrollbar-thumb {
        height: 35px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.1);
    }
`;

const TopFixed = styled.div`
    position: fixed;
    top: 16px;
    right: 16px;
    height: 24px;
    width: 24px;
    text-align: center;
`;

const CloseButton = styled.button``;

const BottomFixed = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 11px 11px;

    height: 84px;

    background: white;
    width: 100%;
`;

export default function TermDetailModal({ onClose, detail }: { onClose: () => void; detail: any }) {
    useEffect(() => {
        if (typeof window !== 'object') {
            return;
        }
        Amplitude.getInstance().logEvent('Popup', {
            Title: '서비스 이용약관',
            Script: ``,
        });
    }, []);

    return (
        <ReactModal
            shouldCloseOnOverlayClick
            ariaHideApp={false}
            isOpen
            onRequestClose={onClose}
            style={{
                overlay: {
                    zIndex: 10000,
                },
                content: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    padding: 0,
                    border: 'none',
                },
            }}
            className="react-modal-alert"
            overlayClassName="react-modal-overlay-alert"
        >
            <TopFixed onClick={onClose}>
                <CloseButton>
                    <Image
                        src={`//cdn.ggumim.co.kr/storage/20220324133254mUujKE3TL6.png`}
                        unoptimized
                        width={'24px'}
                        height={'24px'}
                    />
                </CloseButton>
            </TopFixed>
            <ModalWrapper>
                <ScrollWrapper>
                    <div dangerouslySetInnerHTML={{ __html: detail }}></div>
                </ScrollWrapper>
            </ModalWrapper>
            <BottomFixed>
                <LoginButton
                    width="126px"
                    height="44px"
                    margin="0 0 0 0"
                    background="#40464C"
                    borderRadius="10px"
                    onClick={onClose}
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="18px"
                    color="white"
                    title={'확인'}
                />
            </BottomFixed>
            <style jsx>{style}</style>
        </ReactModal>
    );
}
