import { useSelector } from 'react-redux';
import { AppState } from '../../stores';
import md5 from 'md5';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

const { publicRuntimeConfig } = getConfig();
const { CHANNELTALK_PLUGIN_KEY } = publicRuntimeConfig;

export default function useChannelTalk() {
    const router = useRouter();
    const member = useSelector((state: AppState) => state.common.member);
    const isLogin = !!member.idx;

    const openChannelTalk = (openMethod?: string) => {
        let accountType = '';
        switch (member.accountType) {
            case 'KAKAO':
                accountType = '카카오톡';
                break;
            case 'FACEBOOK':
                accountType = '페이스북';
                break;
            case 'GOOGLE':
                accountType = '구글';
                break;
            case 'EMAIL':
                accountType = '이메일';
                break;
            case 'NAVER':
                accountType = '네이버';
                break;
            case 'APPLE':
                accountType = '애플';
                break;
            default:
                accountType = '';
        }

        window.ChannelIO('boot', {
            pluginKey: `${CHANNELTALK_PLUGIN_KEY}`,
            customLauncherSelector: '#channel_talk',
            hideChannelButtonOnBoot: true,
            userId: !isLogin ? '' : md5(`${member.idx}_m`),
            profile: !isLogin
                ? {}
                : {
                      name: member.nickname,
                      email: member.email,
                      mobileNumber: member.phoneNumber,
                      accountType,
                  },
        });

        if (openMethod) {
            window.ChannelIO('setPage', `https://www.ggumim.co.kr${router.asPath}`);
            window.ChannelIO(openMethod);
        } else {
            window.ChannelIO('setPage', 'https://www.ggumim.co.kr/signup');
            window.ChannelIO('openChat');
        }
    };

    return { openChannelTalk };
}
