import React from 'react';
import { Col } from '../../layout';
import { IProduct } from '../../../api/commerce';
import { ProductCard } from '..';
import { COLOR_V4 } from '../../../lib/styles/colors';

export enum ViewType {
    LIST = 'LIST',
    GRID = 'GRID',
}

interface Props {
    product: IProduct;
    viewType?: ViewType;
    useRank?: boolean;
    useTimer?: boolean;
    handleClickProductCard?: () => void;
}

const ProductCardXLarge = ({
    product,
    viewType = ViewType.LIST,
    useRank,
    useTimer,
    handleClickProductCard,
}: Props) => {
    const {
        idx,
        filename,
        brandName,
        name,
        discount,
        couponPrice,
        priceOriginal,
        priceDiscount,
        productTagList,
        exactReviewScore,
        reviewCount,
        displayReviewScore,
        isToday,
        isSoldout,
        rank,
        startDateTime,
        endDateTime,
    } = product;

    switch (viewType) {
        case ViewType.GRID:
            return (
                <ProductCard.Root
                    href={`/furniture/view/${idx}`}
                    viewType={viewType}
                    handleClickProductCard={handleClickProductCard}
                >
                    <Col
                        width={'235px'}
                        borderRadius={'4px'}
                        overflow={'hidden'}
                        boxShadow={COLOR_V4.SHADOW}
                    >
                        <ProductCard.Image
                            src={filename}
                            isSoldout={isSoldout}
                            isHotdeal={isToday}
                            useTimer={useTimer}
                            startDateTime={startDateTime}
                            endDateTime={endDateTime}
                        />
                        <Col padding={'10px'} height={'146px'} background={COLOR_V4.WHITE}>
                            <ProductCard.BrandName margin={'0px'}>
                                {brandName}
                            </ProductCard.BrandName>
                            <ProductCard.Name>{name}</ProductCard.Name>
                            <ProductCard.PriceDiscount
                                discount={discount}
                                couponPrice={couponPrice}
                                priceDiscount={priceDiscount}
                            />
                            <ProductCard.TagList tagList={productTagList} />
                        </Col>
                    </Col>
                </ProductCard.Root>
            );
        default:
            return (
                <ProductCard.Root
                    href={`/furniture/view/${idx}`}
                    handleClickProductCard={handleClickProductCard}
                >
                    <ProductCard.Image
                        src={filename}
                        rank={rank}
                        isSoldout={isSoldout}
                        isHotdeal={isToday}
                        useRank={useRank}
                        useTimer={useTimer}
                        startDateTime={startDateTime}
                        endDateTime={endDateTime}
                    />
                    <Col padding={'0 4px'}>
                        <ProductCard.BrandName>{brandName}</ProductCard.BrandName>
                        <ProductCard.Name>{name}</ProductCard.Name>
                        <ProductCard.PriceDiscount
                            discount={discount}
                            couponPrice={couponPrice}
                            priceDiscount={priceDiscount}
                            priceOriginal={priceOriginal}
                        />
                        <ProductCard.Star
                            reviewScore={exactReviewScore}
                            reviewCount={reviewCount}
                            displayReviewScore={displayReviewScore}
                        />
                        <ProductCard.TagList tagList={productTagList} />
                    </Col>
                </ProductCard.Root>
            );
    }
};

export default ProductCardXLarge;
