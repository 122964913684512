import styled from 'styled-components';
import { Body3, Col, Body1, Row } from '../layout';
import { COLOR_V4 } from '../../lib/styles/colors';
import { default as NextImage } from 'next/image';

interface StarCardRootProps {
    href: string;
    children?: React.ReactNode | React.ReactNode[];
}

function StarCardRoot(props: StarCardRootProps) {
    const { href, children } = props;
    return (
        <a href={href}>
            <Col>{children}</Col>
        </a>
    );
}

/* 게시글 사진 */
interface StarCardImageProps {
    src: string;
    width?: number;
    height?: number;
}

const StarCardImageStyle = styled.div`
    border-radius: 6px;
    background: ${COLOR_V4.GRAY300};
    overflow: hidden;

    width: 100%;
    padding-top: 73.75%;

    position: relative;
`;

function StarCardImage(props: StarCardImageProps) {
    const { src } = props;

    return (
        <StarCardImageStyle>
            <NextImage unoptimized src={src} alt={''} layout="fill" objectFit={'cover'} />
        </StarCardImageStyle>
    );
}

/* 게시글 이름 */
interface StarCardTitleProps {
    children: string;
    margin?: string;
}

function StarCardTitle(props: StarCardTitleProps) {
    const { children, margin } = props;
    return (
        <Body1 fontWeight={'regular'} color={COLOR_V4.GRAY900} margin={margin}>
            {children}
        </Body1>
    );
}

/* 작성자 프로필 */
interface StarCardProfileProps {
    //src: string;
    children: string;
    margin?: string;
    //pictureMargin?: string;
}

/*
const ProfileContainer = styled.div<{ margin: string; width: number; height: number }>`
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${(props) => props.margin};
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
`;
*/

function StarCardProfile(props: StarCardProfileProps) {
    const { /*src,*/ children, margin /*pictureMargin = '0 8px 0 0'*/ } = props;
    return (
        <Row margin={margin} align={'center'}>
            {/*<ProfileContainer margin={pictureMargin} width={24} height={24}>
                <NextImage unoptimized src={src} alt={''} width={24} height={24} />
    </ProfileContainer>*/}
            <Body3 fontWeight={'regular'} color={COLOR_V4.GRAY400}>
                {children}
            </Body3>
        </Row>
    );
}

const Root = StarCardRoot;
const Image = StarCardImage;
const Title = StarCardTitle;
const Profile = StarCardProfile;

export { Root, Image, Title, Profile };
