import React from 'react';
import styled, { css } from 'styled-components';

export interface ITextProps {
    display?: string;
    align?: string;
    justify?: string;
    position?: string;
    width?: number | string;
    height?: number | string;
    maxHeight?: number | string;
    margin?: number | string;
    padding?: number | string;
    overflow?: string;
    color?: string;
    fontSize?: string;
    fontWeight?: number | string;
    fontStyle?: string;
    fontFamily?: string;
    children?: React.ReactNode;
    lineHeight?: number | string;
    textAlign?: string;
    textOverflow?: string;
    lineBreak?: string;
    whiteSpace?: string;
    displayOneLine?: boolean;
    letterSpacing?: string;
    hideText?: boolean;
    clamp?: number | string;
    cursor?: string;
}

export const Text = styled.span<ITextProps>`
    display: ${({ display }) => display || 'inline-block'};
    ${({ position }) => position && `position: ${position};`}
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
    margin: ${({ margin }) => margin || 0};
    padding: ${({ padding }) => padding || 0};
    overflow: ${({ overflow }) => overflow || 'initial'};
    color: ${({ color }) => color || 'inherit'};
    ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily}`};
    ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
    font-weight: ${({ fontWeight }) =>
        fontWeight === 'bold'
            ? '700'
            : fontWeight === 'medium'
            ? '500'
            : fontWeight === 'regular'
            ? '400'
            : fontWeight || 'inherit'};
    font-style: ${({ fontStyle }) => fontStyle || 'normal'};
    line-height: ${({ lineHeight }) => lineHeight || 'inherit'};
    text-align: ${({ textAlign }) => textAlign || 'inherit'};
    white-space: ${({ whiteSpace }) => whiteSpace || 'initial'};
    letter-spacing: ${({ letterSpacing }) => letterSpacing || 'initial'};

    ${({ cursor }) => cursor && `cursor: ${cursor};`};
    ${({ align }) => align && `align-items: ${align};`}
    ${({ justify }) => justify && `justify-content: ${justify};`}
    ${({ textOverflow }) => textOverflow && `text-overflow: ${textOverflow};`}
    ${({ lineBreak }) => lineBreak && `line-break: ${lineBreak};`}
    ${({ displayOneLine }) =>
        displayOneLine &&
        css`
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        `}
        
    ${({ hideText, clamp }) =>
        hideText &&
        css`
            word-wrap: normal;
            display: -webkit-box;
            -webkit-line-clamp: ${clamp || 2};
            -webkit-box-orient: vertical;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            text-aline: left;
            word-break: break-all;
        `}
`;

export const Display1 = styled(Text)`
    font-size: 32px;
    line-height: 40px;
`;

export const Display2 = styled(Text)`
    font-size: 28px;
    line-height: 38px;
`;

export const Display3 = styled(Text)`
    font-size: 24px;
    line-height: 34px;
`;

export const Display4 = styled(Text)`
    font-size: 22px;
    line-height: 30px;
`;

export const Display5 = styled(Text)`
    font-size: 20px;
    line-height: 26px;
`;

export const Heading1 = styled(Text)`
    font-size: 18px;
    line-height: 26px;
`;

export const Heading2 = styled(Text)`
    font-size: 17px;
    line-height: 24px;
`;

export const Body1 = styled(Text)`
    font-size: 16px;
    line-height: 24px;
`;

export const Body2 = styled(Text)`
    font-size: 14px;
    line-height: 20px;
`;

export const Body3 = styled(Text)`
    font-size: 13px;
    line-height: 18px;
`;

export const Body4 = styled(Text)`
    font-size: 12px;
    line-height: 16px;
`;

export const Caption1 = styled(Text)`
    font-size: 11px;
    line-height: 14px;
`;

export const Caption2 = styled(Text)`
    font-size: 10px;
    line-height: 12px;
`;

export const Button1 = styled(Text)`
    font-size: 16px;
    line-height: 100%;
`;

export const Button2 = styled(Text)`
    font-size: 14px;
    line-height: 100%;
`;

export const Button3 = styled(Text)`
    font-size: 13px;
    line-height: 100%;
`;
