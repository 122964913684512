import React from 'react';
import { IconProps } from '.';

interface ShoppingProps extends IconProps {}

const Shopping = ({ color, width, height, style, ...args }: ShoppingProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <circle cx="7.39279" cy="15.7497" r="1.10714" stroke="white" />
            <circle cx="12.5356" cy="15.7497" r="1.10714" stroke="white" />
            <path
                d="M0.964355 2.89258H3.53578L4.17864 5.78543M4.17864 5.78543L5.78578 12.8569H14.4644L16.0715 5.78543H4.17864Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Shopping;
