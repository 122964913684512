import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import {
    Action,
    AnyAction,
    configureStore,
    EnhancedStore,
    ThunkAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import commonSlice, { ICommonState } from './common/common';
import commentSlice, { ICommentState } from './story/comments';
import storySlice, { IStoryState } from './story/story';
import furnitureSlice, { IFurnitureState } from './furniture/furniture';
import { ThunkMiddlewareFor } from '@reduxjs/toolkit/src/getDefaultMiddleware';

// ToDo: 새로운 redux toolkit slice 추가 시 타입 명시
export let store: EnhancedStore<
    {
        common: ICommonState;
        story: IStoryState;
        comment: ICommentState;
        furniture: IFurnitureState;
    },
    AnyAction,
    [
        ThunkMiddlewareFor<{
            common: ICommonState;
            story: IStoryState;
            comment: ICommentState;
            furniture: IFurnitureState;
        }>,
    ]
> | null = null;

const makeStore = () => {
    store = configureStore({
        devTools: !!publicRuntimeConfig.REDUX_DEV_TOOLS,
        reducer: {
            [storySlice.name]: storySlice.reducer,
            [commentSlice.name]: commentSlice.reducer,
            [furnitureSlice.name]: furnitureSlice.reducer,
            [commonSlice.name]: commonSlice.reducer,
        },
    });
    return store;
};

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

// https://stackoverflow.com/questions/62332738/next-js-calling-thunks-in-getserversideprops-with-next-redux-wrapper-with-types
export type AppThunkDispatch = ThunkDispatch<ReturnType<AppStore['getState']>, void, AnyAction>;

const wrapper = createWrapper(makeStore);
export default wrapper;
