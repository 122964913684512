import styled from 'styled-components';
import { Body3 } from '../layout';
import COLOR from '../../lib/styles/colors';

interface AccountInfoProps {
    children?: React.ReactNode | React.ReactNode[];
}

const AccountInfoStyle = styled.div`
    position: relative;
    width: 100%;
    border: 2px solid ${COLOR.GREY3};
    border-radius: 8px;
    margin-top: 10px;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

function AccountInfoWrapper(props: AccountInfoProps) {
    return <AccountInfoStyle {...props}>{props.children}</AccountInfoStyle>;
}

/*우측 상단 버튼 영역*/
interface AccountInfoButtonAreaProps {
    children?: React.ReactNode | React.ReactNode[];
}

const AccountInfoButtonAreaStyle = styled.div`
    justify-contents: right;
    display: flex;
    align-items: center;
`;

function AccountInfoButtonArea(props: AccountInfoButtonAreaProps) {
    return <AccountInfoButtonAreaStyle>{props.children}</AccountInfoButtonAreaStyle>;
}

/* 계정 정보 영역 */
interface AccountInfoRowAreaProps {
    children?: React.ReactNode | React.ReactNode[];
}

const AccountInfoRowAreaStyle = styled.div``;

function AccountInfoRowArea(props: AccountInfoRowAreaProps) {
    return <AccountInfoRowAreaStyle>{props.children}</AccountInfoRowAreaStyle>;
}

/*계정 정보*/
interface AccountInfoRowProps {
    name: string;
    description?: string;
    children?: React.ReactNode | React.ReactNode[];
    descriptionWidth?: string;
}

const AccountInfoRowStyle = styled.div`
    margin-bottom: 5px;
    display: flex;
    word-break: keep-all;
    word-wrap: break-word;
    overflow-wrap: anywhere;
`;

function AccountInfoRow(props: AccountInfoRowProps) {
    return (
        <AccountInfoRowStyle>
            <Body3 fontWeight={'medium'} color={COLOR.GREY7} width={'91px'}>
                {props.name}
            </Body3>
            <Body3
                fontWeight={'medium'}
                color={COLOR.GREY9}
                width={props.descriptionWidth === undefined ? '260px' : props.descriptionWidth}
            >
                {props.description}
            </Body3>
            {props.children}
        </AccountInfoRowStyle>
    );
}

const Wrapper = AccountInfoWrapper;
const ButtonArea = AccountInfoButtonArea;
const RowArea = AccountInfoRowArea;
const Row = AccountInfoRow;

export { Wrapper, ButtonArea, RowArea, Row };
