import { SmallModal } from '.';
import { Body2, Heading1 } from '../../../../components/layout';
import COLOR, { COLOR_V4 } from '../../../styles/colors';
import { useEffect } from 'react';
import Amplitude from 'amplitude-js';

interface LoginFailModalProps {
    onClickButton: () => void;
    onClickOverlay: () => void;
}

export default function LoginFailModal(props: LoginFailModalProps) {
    useEffect(() => {
        Amplitude.getInstance().logEvent('Popup', {
            Title: '로그인할 수 없습니다.',
            Script: `고객님은 이 계정의 소유자로 확인되지 않았습니다. 다른 계정으로 로그인하시거나 새로
            가입해 주시기 바랍니다.`,
        });
    }, []);

    return (
        <SmallModal buttonColor={COLOR_V4.GRAY900} buttonText={'확인'} height={'212px'} {...props}>
            <Heading1 fontWeight={'bold'} color={COLOR.GREY12}>
                로그인할 수 없습니다.
            </Heading1>
            <Body2 fontWeight={'regular'} color={COLOR.GREY8} margin={'8px 0 0 0'}>
                고객님은 이 계정의 소유자로 확인되지 않았습니다. 다른 계정으로 로그인하시거나 새로
                가입해 주시기 바랍니다.
            </Body2>
        </SmallModal>
    );
}
