import React from 'react';
import { COLOR_V4 } from '../styles/colors';

interface Props {
    size: number;
    color?: string;
    rotate?: string;
}

const Arrow = ({ size, rotate, color }: Props) => {
    switch (size) {
        case 16:
            return (
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        transform: rotate || '',
                    }}
                >
                    <path
                        d="M13.3335 6.00004L8.00016 11.3334L2.66683 6.00004"
                        stroke={color || COLOR_V4.GRAY600}
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 24:
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        transform: rotate || '',
                    }}
                >
                    <path
                        d="M20 9L12 17L4 9"
                        stroke={color || COLOR_V4.GRAY600}
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        default:
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        transform: rotate || '',
                    }}
                >
                    <path
                        d="M20 9L12 17L4 9"
                        stroke={color || COLOR_V4.GRAY600}
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
};

export default Arrow;
