import React from 'react';
import { IconProps } from '.';

interface DoorProps extends IconProps {}

const Door = ({ color, width, height, style, ...args }: DoorProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <path
                d="M7.39289 3.85742H3.85718L3.85718 14.786H7.39289"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.39282 16.7144V1.92871L14.4643 3.85728V14.7859L7.39282 16.7144Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.96443 8.28564C10.3589 8.28564 10.6787 8.60544 10.6787 8.99993L10.6787 9.64279C10.6787 10.0373 10.3589 10.3571 9.96443 10.3571C9.56994 10.3571 9.25014 10.0373 9.25014 9.64279L9.25014 8.99993C9.25014 8.60544 9.56994 8.28564 9.96443 8.28564Z"
                stroke="white"
                strokeWidth="0.5"
            />
        </svg>
    );
};

export default Door;
