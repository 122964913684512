import React from 'react';
import styled from 'styled-components';
import { COLOR_V4 } from '../../lib/styles/colors';
import { Col, Display5, Display4 } from '../layout';
import { ExclamationMark } from '../../lib/Icons';

interface NoResultProps {
    searchText?: string;
    whenFiltering?: boolean;
}

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    border: 1px solid ${COLOR_V4.PINK500};
    border-radius: 50%;
`;

export const NoResult = (props: NoResultProps) => {
    const { searchText = '', whenFiltering = false } = props;
    return (
        <Col align={'center'} margin={'150px 0 189px'}>
            <Circle>
                <ExclamationMark color={COLOR_V4.PINK500} />
            </Circle>
            {whenFiltering ? (
                <Display4 fontWeight={'medium'} color={COLOR_V4.GRAY900} margin={'8px 0 0 0'}>
                    죄송합니다. 일치하는 검색결과가 없습니다.
                </Display4>
            ) : (
                <>
                    <Display4 fontWeight={'medium'} color={COLOR_V4.GRAY400} margin={'20px 0 0 0'}>
                        "{searchText}"
                    </Display4>
                    <Display4 fontWeight={'medium'} color={COLOR_V4.GRAY900} margin={'8px 0 0 0'}>
                        죄송합니다. 일치하는 검색어 및 검색결과가 없습니다.
                    </Display4>
                    <Display5 fontWeight={'regular'} color={COLOR_V4.GRAY300} margin={'30px 0 0 0'}>
                        다른 검색어로 검색해보세요.
                    </Display5>
                </>
            )}
        </Col>
    );
};
