import React from 'react';
import { Display5 } from '../layout';
import { COLOR_V4 } from '../../lib/styles/colors';

interface SearchResultCountProps {
    keyword: string;
    type: string;
    count: number;
}

export const SearchResultCount = (props: SearchResultCountProps) => {
    const { keyword, type, count } = props;

    return (
        <Display5 fontWeight={'bold'} color={COLOR_V4.GRAY600}>
            <Display5 color={COLOR_V4.PINK500}>{`"${keyword}"`}</Display5>에 대한 {type} 검색 결과
            <Display5 fontWeight={'medium'} color={COLOR_V4.GRAY500} margin={'0 0 0 8px'}>
                {count === -1 ? '...' : count.toLocaleString()}개
            </Display5>
        </Display5>
    );
};
