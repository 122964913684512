import React from 'react';
import { Body3, Button } from '../layout';
import { Delete } from '../../lib/Icons';
import { COLOR_V4 } from '../../lib/styles/colors';

interface FilterListTagProps {
    children: string;
    isDisplayed: boolean;
    onClick: () => void;
}

export const Tag = (props: FilterListTagProps) => {
    const { children, isDisplayed, onClick } = props;
    return (
        <>
            {isDisplayed && (
                <Button
                    borderRadius={'50px'}
                    background={COLOR_V4.GRAY50}
                    padding={'7px 10px'}
                    margin={'0 8px 0 0'}
                >
                    <Body3 fontWeight={'regular'} margin={'0 4px 0 0'} color={COLOR_V4.GRAY500}>
                        {children}
                    </Body3>
                    <Button onClick={onClick} background={'inherit'} padding={'2px'}>
                        <Delete color={COLOR_V4.GRAY900} width={'12px'} height={'12px'} />
                    </Button>
                </Button>
            )}
        </>
    );
};
