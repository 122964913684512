import React from 'react';
import { IconProps } from '.';

interface ArrowForwardProps extends IconProps {}

const ArrowForward = ({ color, width, height, style, ...args }: ArrowForwardProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <path
                d="M9.5 6.5L15.0001 12.0087L9.5 17.5"
                stroke="#333C45"
                strokeWidth="2.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowForward;
