import React from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

const SmoothAppearAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateX(20%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const Container = styled.div`
    animation: ${SmoothAppearAnimation} 0.3s ease-out 0s 1;
`;

interface SmoothAppearProps {
    children: JSX.Element | JSX.Element[];
}

export const SmoothAppear = ({ children }: SmoothAppearProps) => {
    return <Container>{children}</Container>;
};
