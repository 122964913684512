import { api, purchaseApi } from '../lib/request';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ICategoryTree } from '../stores/common/common';
import { ProductTagList } from './search';

export async function getCategoryTree(_req?: AxiosRequestConfig): Promise<ICategoryTree | null> {
    try {
        const response: AxiosResponse<{ data: ICategoryTree; code: number | string }> =
            await api.get('/category/getTree');

        if (response.data.code.toString() === '200') {
            return response.data.data;
        }

        return null;
    } catch (e) {
        return null;
    }
}

export interface IPurchaseHistoryCount {
    all: number;
    notDeposit: number;
    prepare: number;
    deliveryWait: number;
    delivery: number;
    cancelBeforeDeposit: number;
    cancel: number;
    returnProduct: number;
}

// 주문내역 상태별 카운트
export async function getPurchaseHistoryCountByStatus(
    _req?: AxiosRequestConfig,
): Promise<IPurchaseHistoryCount | null> {
    try {
        const response: AxiosResponse<IPurchaseHistoryCount> = await purchaseApi.get(
            '/purchase/v1/history/countByStatus',
        );

        return response.data;
    } catch (e) {
        return null;
    }
}

export interface NonMemberOrderListProps {
    orderIdx: string;
    requestData: {
        phoneNumber: string;
        key: number;
        idx: number;
    };
}

export async function getNonMemberOrderList({
    orderIdx,
    requestData,
}: NonMemberOrderListProps): Promise<any> {
    try {
        const response: AxiosResponse<IPurchaseHistoryCount> = await purchaseApi.get(
            `/purchase/v1/order/no_member/${orderIdx}?phoneNumber=${requestData.phoneNumber}&confirmCode=${requestData.key}&confirmIdx=${requestData.idx}`,
        );

        return response;
    } catch (e) {
        return e;
    }
}

type TOptionInfo = {
    idx: number;
    title: string;
    name: string;
};

export type TCartUnit = {
    idx: number;
    furnitureIdx: number;
    hopeDeliveryDate: string | null;
    textOption: string | null;
    count: number;
    option: {
        idx: number;
        furnitureIdx: number;
        type: number;
        info: TOptionInfo[];
        parentIdx: number;
        parentName: string;
        price: number;
        count: number;
        buyLimitCount: number;
        countType: string;
        productCode: string;
        commission: number;
    };
    errorName: string;
    errorMessage: string;
};

export type TCartProduct = {
    idx: number;
    name: string;
    image: string;
    images: string[];
    status: string;
    type: number;
    priceDiscount: number;
    priceOriginal: number;
    productTagList: ProductTagList[];
    buyLimitCount: number;
    textOptionName: string | null;
    brand: {
        idx: number;
        name: string;
    };
    partner: {
        idx: number;
        name: string;
    };
    textOptionRequired: boolean;
    hopeDelivery: boolean;
    textOption: boolean;
};

export type TCartShipping = {
    idx: number;
    groupIdx: number;
    shippingType: number;
    shippingCase: number;
    shippingPayment: number;
    price: number;
    condition: number;
    useAdditionalPrice: boolean;
    additionalPrice: number;
};

export type TCartItem = {
    discountedPriceTotal: number;
    originalPriceTotal: number;
    shippingFee: number;
    additionalShippingFee: number;
    shipping: TCartShipping;
    products: { product: TCartProduct; options: TCartUnit[] }[];
};

export type TCartGroup = {
    countProduct: number;
    countUnit: number;
    countUnitWithExtra: number;
    originalPriceTotal: number;
    discountedPriceTotal: number;
    shippingFee: number;
    additionalShippingFee: number;
    total: number;
    list: TCartItem[];
};

export interface ICartList {
    groupsSelected: TCartGroup;
    groupsAvailable: TCartGroup;
    groupsUnavailable: TCartGroup;
}

export async function getCart(selected?: string): Promise<ICartList | null> {
    try {
        const response: AxiosResponse<ICartList> = await purchaseApi.get(
            `/purchase/v2/cart${selected ? `?selected=${selected}` : ''}`,
        );

        return response.data;
    } catch (e) {
        return null;
    }
}

export type TCommerceItem = {
    furnitureIdx: number | string;
    furnitureUnitIdx: number | string;
    count: number;
    additionalCommerceList?: TCommerceItem[];
};

export async function postCart(commerceList: TCommerceItem[]) {
    try {
        const response: AxiosResponse = await purchaseApi.post(`/purchase/v2/cart`, {
            items: commerceList,
        });

        return response.data;
    } catch (e) {
        throw e;
    }
}

interface ICartCountResponse {
    countProduct: number;
    countUnit: number;
    countUnitWithExtra: number;
}

export async function getCartCount(_req?: AxiosRequestConfig): Promise<ICartCountResponse | null> {
    try {
        const response: AxiosResponse<ICartCountResponse> = await purchaseApi.get(
            '/purchase/v2/cart/count',
        );
        return response.data;
    } catch (e) {
        return null;
    }
}

export interface IUnitCount {
    furnitureUnitIdx: number;
    count: number;
}

export async function patchCartCount(cartCount: IUnitCount) {
    try {
        const response: AxiosResponse = await purchaseApi.patch('/purchase/v2/cart', {
            items: [cartCount],
        });

        return response.data;
    } catch (e) {
        throw e;
    }
}

export interface IDeleteCartItem {
    idx: number;
    type: string; //'OPTION' | 'PRODUCT';
}

export async function deleteCart(items: IDeleteCartItem[]) {
    try {
        const response: AxiosResponse = await purchaseApi.delete(`/purchase/v2/cart`, {
            data: { items },
        });

        return response.data;
    } catch (e) {
        throw e;
    }
}

export async function migrateCart() {
    try {
        const response: AxiosResponse = await purchaseApi.put('/purchase/v2/cart/migrate');

        return response.data;
    } catch (e) {
        return null;
    }
}

export async function validateCart(commerceList: TCommerceItem[]) {
    try {
        const response: AxiosResponse = await purchaseApi.post(`/purchase/v2/cart/validate`, {
            items: commerceList,
        });

        return response.data;
    } catch (e) {
        throw e;
    }
}
