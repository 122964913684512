import { useRef, useEffect } from 'react';
import { debounce } from 'lodash';

const useMemorizeScroll = (name: string) => {
    const scrollRef = useRef<any>(null);

    const getCachedScrollTop = () => {
        const scrollTop = localStorage.getItem(name);
        if (scrollTop !== null) {
            if (scrollRef.current === null) {
                return;
            }
            scrollRef.current.scrollTop = Number(scrollTop);
        }
    };

    useEffect(() => {
        getCachedScrollTop();
    }, []);

    const onScroll = debounce(() => {
        if (scrollRef.current !== null) {
            localStorage.setItem(name, scrollRef.current.scrollTop);
        }
    }, 50);

    return { scrollRef, onScroll };
};

export default useMemorizeScroll;
