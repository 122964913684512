import React from 'react';
import styled from 'styled-components';
import { Row, Button, Body2 } from '../layout';
import { Spread, CheckSelectedIcon, CheckDefaultIcon } from '../../lib/Icons';
import { COLOR_V4 } from '../../lib/styles/colors';
import useMemorizeScroll from './useMemorizeScroll';

interface FilterRootProps {
    children?: React.ReactNode | React.ReactNode[];
}

const FilterRoot = (props: FilterRootProps) => {
    const { children } = props;
    return <Row align={'center'}>{children}</Row>;
};

/* 버튼 */
interface FilterOptionButtonProps {
    children?: React.ReactNode | React.ReactNode[];
    optionsSize?: string;
    buttonElem: React.ReactNode | React.ReactNode[];
    open?: boolean;
    hasSpread?: boolean;
    hasListBox?: boolean;
    selected: boolean;
    margin?: string;
    padding?: string;
    textMargin?: string;
    onClick: () => void;
}

const FilterOptionButtonStyle = styled.div`
    position: relative;
    button {
        transition: border-color 0.1s ease 0s;
    }

    span {
        transition: color 0.1s ease 0s;
    }
`;

const FilterOptionButton = React.forwardRef<HTMLDivElement, FilterOptionButtonProps>(
    (props, ref) => {
        const {
            children,
            buttonElem,
            open = false,
            hasSpread,
            selected,
            margin = '0 10px 0 0',
            padding = '11px',
            textMargin = '0 4px 0 0',
            onClick,
        } = props;

        return (
            <FilterOptionButtonStyle ref={ref}>
                <Button
                    borderRadius={'6px'}
                    border={`1px solid ${selected ? COLOR_V4.GRAY900 : COLOR_V4.GRAY200}`}
                    padding={padding}
                    margin={margin}
                    onClick={onClick}
                >
                    <Row align="center" justify="center">
                        <Body2
                            fontWeight={selected ? 'bold' : 'regular'}
                            margin={textMargin}
                            color={COLOR_V4.GRAY900}
                        >
                            {buttonElem}
                        </Body2>
                        {hasSpread ? (
                            open ? (
                                <Spread
                                    color={COLOR_V4.GRAY600}
                                    style={{ transform: 'rotate(0.5turn)' }}
                                />
                            ) : (
                                <Spread color={COLOR_V4.GRAY600} />
                            )
                        ) : (
                            <></>
                        )}
                    </Row>
                </Button>
                {children}
            </FilterOptionButtonStyle>
        );
    },
);

/* 옵션 박스 */
interface FilterOptionBoxProps {
    children?: React.ReactNode | React.ReactNode[];
    optionsSize?: string;
    open?: boolean;
    useScrollCache?: boolean;
    name: string;
}

const FilterOptionBoxStyle = styled.div<{ open: boolean; optionsSize: string }>`
    display: ${(props) => (props.open ? 'block' : 'none')};
    position: absolute;

    width: ${(props) => props.optionsSize};
    max-height: 260px;
    box-sizing: border-box;
    border: 1px solid ${COLOR_V4.GRAY200};
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    margin: 8px 0 0;
    list-style: none;
    background: ${COLOR_V4.WHITE};

    overflow: auto;
    z-index: 100;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

const FilterOptionBox = (props: FilterOptionBoxProps) => {
    const { children, optionsSize = '320px', open = false, name, useScrollCache = false } = props;
    const { scrollRef, onScroll } = useMemorizeScroll(name);

    return (
        <FilterOptionBoxStyle
            open={open}
            optionsSize={optionsSize}
            onScroll={onScroll}
            ref={useScrollCache ? scrollRef : null}
        >
            {children}
        </FilterOptionBoxStyle>
    );
};

/* 필터 상단 */
interface FilterListHeaderProps {
    children?: React.ReactNode | React.ReactNode[];
    hoverEffect?: boolean;
}

const FilterListHeaderStyle = styled(Row)<{ hoverEffect: boolean }>`
    padding: 12px;
    background: ${COLOR_V4.GRAY50};

    ${(props) =>
        props.hoverEffect &&
        `&:hover {
        background-color: #e6f7ff;
    };
    cursor: pointer;
    `}
`;

const FilterListHeader = (props: FilterListHeaderProps) => {
    const { children, hoverEffect = false } = props;
    return <FilterListHeaderStyle hoverEffect={hoverEffect}>{children}</FilterListHeaderStyle>;
};

/* 필터 내부 옵션 */
interface FilterListOptionProps {
    name: string;
    count: number;
    selected: boolean;
    onClick: (event?: any) => void;
}

const FilterListOptionStyle = styled.div`
    padding: 13px 12px;
    cursor: pointer;
    border-bottom: 1px solid ${COLOR_V4.GRAY200};

    &:hover {
        background-color: #e6f7ff;
    }
`;

const FilterListOption = (props: FilterListOptionProps) => {
    const { name, count, selected, ...rest } = props;
    return (
        <FilterListOptionStyle {...rest}>
            <Row justify={'space-between'} align={'center'}>
                <Row>
                    <Body2
                        fontWeight={selected ? 'bold' : 'regular'}
                        margin={'0 4px 0 0'}
                        color={COLOR_V4.GRAY900}
                    >
                        {name}
                    </Body2>
                    <Body2 fontWeight={selected ? 'bold' : 'regular'} color={COLOR_V4.GRAY300}>
                        {count}
                    </Body2>
                </Row>
                <Button
                    borderRadius={'50%'}
                    width={'20px'}
                    height={'20px'}
                    padding={'6px'}
                    background={selected ? COLOR_V4.GRAY900 : COLOR_V4.GRAY200}
                >
                    {selected ? (
                        <CheckSelectedIcon color={COLOR_V4.GRAY900} width={'9px'} height={'8px'} />
                    ) : (
                        <CheckDefaultIcon color={COLOR_V4.GRAY900} width={'9px'} height={'8px'} />
                    )}
                </Button>
            </Row>
        </FilterListOptionStyle>
    );
};

const Root = FilterRoot;
const OptionButton = FilterOptionButton;
const OptionBox = FilterOptionBox;
const ListHeader = FilterListHeader;
const ListOption = FilterListOption;

export { Root, OptionButton, OptionBox, ListHeader, ListOption };
