import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Col, Row, Text, Wrapper, GridWrapper } from '../layout';
import { getNoticeList } from '../../api/home';

import { go } from '../../lib/utils';
import { COLOR_V4 } from '../../lib/styles/colors';

interface INoticeItem {
    idx: number | string;
    title: string;
    createDateTime: string;
    viewCount: number;
}

const InicisIcon = styled.img`
    opacity: 0.6;
`;

const TitleText = styled(Text)`
    color: #181d1f;
    font-size: 17px;
    font-weight: bold;
    line-height: 48px;
`;

const NormalText = styled(Text)`
    color: #535b63;
    font-size: 14px;
    font-weight: normal;
    line-height: ${({ lineHeight }) => lineHeight || `27px`};
`;

const CopyrightText = styled(Text)`
    color: #c5c8ce;
    font-size: 13px;
    line-height: 18px;
    white-space: nowrap;
    margin: 0 50px 0 0;
`;

const IconCol = styled(Col)`
    width: 32px;
    height: 32px;
    margin: 0 8px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    background: #898f94;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background: #a1a3a7;
        transition: 0.3s;
    }

    &:last-child > img {
        margin: 1px 0 0;
    }

    img {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const MenuRow = styled(Row)`
    margin: 0 0 10px;

    ${Col} {
        justify-content: center;
        height: 18px;
        padding: 0 14px;
        border-right: 1px solid #333c45;
        cursor: pointer;

        &:first-child {
            padding: 0 16px 0 0;
        }

        &:last-child {
            border: none;
        }

        ${Text} {
            font-size: 15px;
            line-height: 18px;
        }
    }
`;

export default function Footer() {
    const [noticeList, setNoticeList] = useState([]);

    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }

        async function getNoticeData() {
            const returnData = await getNoticeList();
            setNoticeList(returnData);
        }

        getNoticeData();
    }, []);

    return (
        <Wrapper background={COLOR_V4.GRAY50}>
            <Row width={'100%'} height={'1px'} background={COLOR_V4.GRAY600}></Row>
            <GridWrapper padding={'14px 0 0'}>
                <Row>
                    <Col width={'30%'}>
                        <Row>
                            <TitleText>공지사항</TitleText>
                        </Row>
                        {noticeList.map((item: INoticeItem) => {
                            const { idx, title } = item;

                            return (
                                <Row
                                    key={`notice-item-${idx}`}
                                    cursor={'pointer'}
                                    onClick={() => go(`/notice/view/${idx}`)}
                                >
                                    <NormalText>{title}</NormalText>
                                </Row>
                            );
                        })}
                    </Col>
                    <Col width={'30%'}>
                        <Row cursor={'pointer'} onClick={() => go(`/help`)}>
                            <Col height={'48px'} margin={'0 8px 0 0'}>
                                <TitleText>고객센터</TitleText>
                            </Col>
                            <Col height={'48px'} justify={'center'} margin={'-1px 0 0 0'}>
                                <img
                                    src={'//cdn.ggumim.co.kr/storage/20220315174249bD12nUuElv.png'}
                                    alt=""
                                    width={18}
                                    height={18}
                                />
                            </Col>
                        </Row>
                        <Row margin={'-8px 0 6px'}>
                            <Text color={COLOR_V4.GRAY900} fontSize={'36px'} lineHeight={'58px'}>
                                1522-7966
                            </Text>
                        </Row>
                        <Row>
                            <NormalText lineHeight={'26px'}>
                                평일 10:00 ~ 17:00
                                <br />
                                주말 및 공휴일 제외
                                <br />
                                (점심시간 : 12:00 ~ 13:00)
                            </NormalText>
                        </Row>
                    </Col>
                    <Col width={'40%'} height={'48px'} justify={'center'}>
                        <Row justify={'flex-end'}>
                            <IconCol
                                onClick={() =>
                                    window.open('https://www.instagram.com/ggumigi/', '_blank')
                                }
                            >
                                <img
                                    src="//cdn.ggumim.co.kr/storage/20220225135022Ha9RwaNfwa.png"
                                    alt="instagram-icon"
                                    width={16}
                                    height={16}
                                />
                            </IconCol>
                            <IconCol
                                onClick={() =>
                                    window.open('https://www.youtube.com/@ggumigi', '_blank')
                                }
                            >
                                <img
                                    src="//cdn.ggumim.co.kr/storage/20230607093724TOwadINTtx.png"
                                    alt="youtube-icon"
                                    width={17}
                                    height={12}
                                />
                            </IconCol>
                            <IconCol
                                onClick={() =>
                                    window.open('https://ko-kr.facebook.com/ggumim2013', '_blank')
                                }
                            >
                                <img
                                    src="//cdn.ggumim.co.kr/storage/2022022513494634RuwfmNbp.png"
                                    alt="facebook-icon"
                                    width={7.5}
                                    height={15}
                                />
                            </IconCol>
                            <IconCol
                                onClick={() =>
                                    window.open('https://post.naver.com/mdl87', '_blank')
                                }
                            >
                                <img
                                    src="//cdn.ggumim.co.kr/storage/20220225134946RNPX2nL18K.png"
                                    alt="naver-post-icon"
                                    width={10}
                                    height={17}
                                />
                            </IconCol>
                            <IconCol
                                onClick={() =>
                                    window.open('https://story.kakao.com/_2CpjG5', '_blank')
                                }
                            >
                                <img
                                    src="//cdn.ggumim.co.kr/storage/20220225134946Xu6UnrmsRT.png"
                                    alt="naver-post-icon"
                                    width={8}
                                    height={14}
                                />
                            </IconCol>
                            <IconCol
                                onClick={() =>
                                    window.open('http://band.us/#!/band/56666078', '_blank')
                                }
                            >
                                <img
                                    src="//cdn.ggumim.co.kr/storage/20220225134946kdTTfXjvyX.png"
                                    alt="naver-band-icon"
                                    width={9}
                                    height={13}
                                />
                            </IconCol>
                        </Row>
                    </Col>
                </Row>
            </GridWrapper>
            <Row
                width={'100%'}
                height={'1px'}
                background={COLOR_V4.GRAY600}
                margin={'28px 0 30px'}
            />
            <GridWrapper>
                <MenuRow>
                    <Col onClick={() => go(`/agreement/term`)}>
                        <TitleText>이용약관</TitleText>
                    </Col>
                    <Col onClick={() => (location.href = 'mailto:info@osquarecorp.com')}>
                        <TitleText>제휴문의</TitleText>
                    </Col>
                    <Col onClick={() => go(`/partner/store_inquiry/`)}>
                        <TitleText>입점문의</TitleText>
                    </Col>
                    <Col onClick={() => go(`/agreement/privacy/new`)}>
                        <TitleText>개인정보처리방침</TitleText>
                    </Col>
                    <Col onClick={() => window.open('https://zipggumigi.oopy.io/', '_blank')}>
                        <TitleText>회사소개</TitleText>
                    </Col>
                </MenuRow>

                <Row margin={'14px 0 0'}>
                    <NormalText lineHeight={'25px'}>
                        대표이사 : 길경환&nbsp;&nbsp;&nbsp;&nbsp;사업자등록번호 :
                        142-81-50856&nbsp;&nbsp;&nbsp;&nbsp;통신판매업신고 : 제 2023-서울용산-1713
                        호&nbsp;&nbsp;&nbsp;&nbsp;호스팅 서비스 : (주)집꾸미기
                        <br />
                        주소 : 서울특별시 용산구 서빙고로 17, 13층 1305호(한강로3가,
                        용산센트럴파크타워)
                        <br />
                        개인정보관리책임자 : 길경환
                        <br />
                    </NormalText>
                </Row>
                <Row margin={'10px 0 30px'}>
                    <Col
                        justify={'center'}
                        cursor={'pointer'}
                        onClick={() =>
                            window.open(
                                'https://mark.inicis.com/mark/escrow_popup.php?mid=theggumim2',
                                'mark',
                                'scrollbars=no,resizable=no,width=565,height=683',
                            )
                        }
                    >
                        <InicisIcon
                            src={'//cdn.ggumim.co.kr/storage/20211028185300YqADrWoB5x.png'}
                            width={24}
                            height={24}
                        />
                    </Col>
                    <Col
                        width={'1px'}
                        height={'18px'}
                        background={'#535b63'}
                        margin={'2px 10px'}
                    ></Col>
                    <Col>
                        <NormalText lineHeight={'24px'}>
                            안전거래를 위해 현금 등으로 결제 시 저희 쇼핑몰에서 가입한 KG 이니시스의
                            구매안전 서비스를 이용하실 수 있습니다.
                        </NormalText>
                    </Col>
                </Row>
            </GridWrapper>
            <Row
                width={'100%'}
                padding={'15px 0 17px'}
                background={COLOR_V4.GRAY900}
                overflow={'hidden'}
            >
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
                <CopyrightText>© 집꾸미기 ALL RIGHTS RESERVED.</CopyrightText>
            </Row>
        </Wrapper>
    );
}
