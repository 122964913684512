import getConfig from 'next/config';

const nextConfig = getConfig();
const { serverRuntimeConfig, publicRuntimeConfig } = nextConfig || {};

export const AMPLITUDE_API_KEY =
    serverRuntimeConfig?.AMPLITUDE_API_KEY || publicRuntimeConfig?.AMPLITUDE_API_KEY || '';
// font
export const $FONT_SANS = '"Spoqa Han Sans Neo", sans-serif';
// Century Gothic
// color
export const $BASE_COLOR = '#2F2F2F';
export const $BASE_FONT_SIZE = 16;
export const $BASE_LINE_HEIGHT = 1.45;
export const $BASE_LETTER_SPACING = 0;
export const $BASE_FONT_PATH = '/static/fonts';
export const TRACKID = 6082172864527224275;

// export const propertyCategoryList = [
//     {
//         categoryId: 1,
//         categoryName: '주거형태',
//         type: '형태',
//     },
//     {
//         categoryId: 2,
//         categoryName: '평수',
//         type: '평수',
//     },
//     {
//         categoryId: 3,
//         categoryName: '거주형태',
//         type: '타입',
//     },
//     {
//         categoryId: 4,
//         categoryName: '스타일',
//         type: '스타일',
//     },
//     {
//         categoryId: 5,
//         categoryName: '공간',
//         type: '공간',
//     },
// ];

export const numberWithCommas = (x: number) =>
    x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';

export const interiorPrice = [
    {
        min: '0',
        max: '1000',
        name: '1000만원 미만',
    },
    {
        min: '1000',
        max: '1500',
        name: '1000 ~ 1500만원',
    },
    {
        min: '1500',
        max: '2000',
        name: '1500 ~ 2000만원',
    },
    {
        min: '2000',
        max: '2500',
        name: '2000 ~ 2500만원',
    },
    {
        min: '2500',
        max: '3000',
        name: '2500 ~ 3000만원',
    },
    {
        min: '3000',
        max: '0',
        name: '3000만원 이상',
    },
];

export const productPrice = [
    {
        min: '0',
        max: '50',
        name: '50만원 미만',
    },
    {
        min: '50',
        max: '75',
        name: '50 ~ 75만원',
    },
    {
        min: '75',
        max: '100',
        name: '75 ~ 100만원',
    },
    {
        min: '100',
        max: '150',
        name: '100 ~ 150만원',
    },
    {
        min: '150',
        max: '200',
        name: '150 ~ 200만원',
    },
    {
        min: '200',
        max: '300',
        name: '200 ~ 300만원',
    },
    {
        min: '300',
        max: '400',
        name: '300 ~ 400만원',
    },
    {
        min: '500',
        max: '0',
        name: '500만원 이상',
    },
];

export enum PropertyCategory {
    HOUSING_TYPE = 1,
    FLOOR_SPACE = 2,
    RESIDENCE_TYPE = 3,
    COLOR = 10,
    STYLE = 4,
    IMAGE_ROOM_TYPE = 5,
}
export const RequiredPropertyCategory = [
    PropertyCategory.HOUSING_TYPE,
    PropertyCategory.FLOOR_SPACE,
    PropertyCategory.RESIDENCE_TYPE,
];

export interface IPropertyCategoryTab {
    label: string;
    category: number;
}
export const PropertyCategoryTab = [
    {
        label: '주거형태',
        category: 1,
    },
    {
        label: '평수',
        category: 2,
    },
    {
        label: '거주형태',
        category: 3,
    },
    {
        label: '색상',
        category: 10,
    },
    {
        label: '스타일',
        category: 4,
    },
];

export const ApplicationJsonHeader = {
    headers: {
        'content-type': 'application/json',
    },
};
export const TextPlainHeader = {
    headers: {
        'content-type': 'text/plain',
    },
};

export enum CookieKeys {
    nonMemberCart = 'ggumim_cart',
    nonMemberToken = 'NONMEMBER-TOKEN',
    accountType = 'ACCOUNT-TYPE',
}

export enum AccountType {
    KAKAO = 'KAKAO',
    NAVER = 'NAVER',
    FACEBOOK = 'FACEBOOK',
    APPLE = 'APPLE',
    EMAIL = 'EMAIL',
}

export enum FurnitureTagUIType {
    Outside = 1,
    Internal = 2,
    Other = 3,
}

// Delivery Set
export const DELIVERY_CASES = {
    DELIVERY: 0, // 택배
    FAST_REGISTERED_MAIL: 1, // 빠른 등기
    GENERAL: 2, // 일반 배송
    DIRECT: 3, // 직접 배송
    QUICK: 4, // 퀵 배송
    OTHERS: 5, // 기타
    FREIGHT: 6, // 화물 배송
    VISIT_IN_PERSON: 7, // 매장 직접 수령
    UNNECESSARY: 8, // 배송 불필요
    GLOBAL_DELIVERY: 9, // 해외 배송
};

export const DELIVERY_PAYMENT = {
    PRE_POST: 0,
    PRE: 1,
    POST: 2,
};

export const DELIVERY_TYPE = {
    FIXED: 0,
    FREE: 1,
    PROPORTION: 2,
    CONDITION: 3,
    CONDITION_1: 5,
    CONDITION_2: 7,
    COUNT: 4,
    COUNT_1: 6,
    COUNT_2: 8,
};

export const USE_DELIVERY_LOCAL_PRICE = {
    NONE: 0,
    USE: 1,
};

export const DELIVERY_LOCAL_TYPE = {
    POST: 0,
    FIXED: 1,
};

export const FILE_TYPES = {
    PHOTO: 1,
    YOUTUBE: 2,
};

export const PURCHASE_ERROR_TYPE = {
    SOLDOUT: 'SOLDOUT', //품절
    VISIBILITY: 'VISIBILITY', //비공개
    DISCONTINUED: 'DISCONTINUED', //단종
};

export const OPTION_COUNT_TYPE = {
    LIMIT: 'LIMIT', //재고량 사용
    NOLIMIT: 'NOLIMIT', //재고 무한
    SOLDOUT: 'SOLDOUT', //품절
};

export const OPTION_TYPE = {
    BASE: 0, //기본 옵션
    EXTRA: 1, //추가 옵션
};

export const CART_ITEM_TYPE = {
    PRODUCT: 'PRODUCT', //상품
    OPTION: 'OPTION', //옵션
};

export enum CouponDownload {
    'FALSE' = 0,
    'TRUE' = 1,
}

export enum CouponExpireType {
    'DAYS' = 0,
    'DATE' = 1,
}
