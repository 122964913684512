import { LoginModal } from '.';
import { Col, Display5 } from '../../../../components/layout';
import { SocialLoginButton } from '../../../../components/signup';
import styled from 'styled-components';
import Image from 'next/image';
import { COLOR_V4 } from '../../../styles/colors';

interface LoginModalProps {
    close: () => void;
    includingTypes: {
        KAKAO: number;
        NAVER: number;
        GOOGLE: number;
        FACEBOOK: number;
        APPLE: number;
        EMAIL: number;
    };
    accountIdxList: number[];
    onClicks?: {
        KAKAO?: () => void;
        NAVER?: () => void;
        GOOGLE?: () => void;
        FACEBOOK?: () => void;
        APPLE?: () => void;
        EMAIL?: () => void;
    };
}

const TopFixed = styled.div`
    position: fixed;
    top: 16px;
    right: 16px;
    height: 24px;
    width: 24px;
    text-align: center;
    z-index: 30000;
`;

const CloseButton = styled.button``;

export default function SignupChooseModal(props: LoginModalProps) {
    return (
        <LoginModal {...props} width={'max-content'} height={'max-content'}>
            <TopFixed onClick={() => props.close()}>
                <CloseButton>
                    <Image
                        src={`//cdn.ggumim.co.kr/storage/20220324133254mUujKE3TL6.png`}
                        unoptimized
                        width={'24px'}
                        height={'24px'}
                    />
                </CloseButton>
            </TopFixed>
            <Col padding={'80px'}>
                <Display5 fontWeight={'bold'} color={COLOR_V4.GRAY900}>
                    집꾸미기 회원가입
                </Display5>
                <Col margin={'16px'} />
                <SocialLoginButton.KAKAO
                    isDisplayNone={props.includingTypes.KAKAO === 0}
                    margin={'0 0 10px'}
                    imgSrc={'https://cdn.ggumim.co.kr/storage/20230825162852ZVxBT8CdNV.png'}
                    imgWidth={'330px'}
                    imgHeight={'52px'}
                    onClick={props.onClicks?.KAKAO}
                />
                <SocialLoginButton.NAVER
                    isDisplayNone={props.includingTypes.NAVER === 0}
                    margin={'0 0 10px'}
                    imgSrc={'https://cdn.ggumim.co.kr/storage/20230825162906LPy4Mbso1P.png'}
                    imgWidth={'330px'}
                    imgHeight={'52px'}
                    onClick={props.onClicks?.NAVER}
                />
                {/*<SocialLoginButton.GOOGLE
                    isDisplayNone={props.includingTypes.GOOGLE === 0}
                    margin={'0 0 10px'}
                    imgSrc={'//cdn.ggumim.co.kr/storage/20221209162343QpNjbtRzYI.png'}
                    imgWidth={'330px'}
                    imgHeight={'52px'}
                    onClick={props.onClicks?.GOOGLE}
        />*/}
                <SocialLoginButton.FACEBOOK
                    isDisplayNone={props.includingTypes.FACEBOOK === 0}
                    margin={'0 0 10px'}
                    imgSrc={'https://cdn.ggumim.co.kr/storage/20230825162922kWvwDbVpl0.png'}
                    imgWidth={'330px'}
                    imgHeight={'52px'}
                    onClick={props.onClicks?.FACEBOOK}
                />
                <SocialLoginButton.APPLE
                    isDisplayNone={props.includingTypes.APPLE === 0}
                    margin={'0 0 10px'}
                    imgSrc={'https://cdn.ggumim.co.kr/storage/20230825162937pKoAQOuiZj.png'}
                    imgWidth={'330px'}
                    imgHeight={'52px'}
                    onClick={props.onClicks?.APPLE}
                />
                <SocialLoginButton.EMAIL
                    isDisplayNone={props.includingTypes.EMAIL === 0}
                    margin={'0'}
                    imgSrc={'https://cdn.ggumim.co.kr/storage/2023082516295344WsxqWwM2.png'}
                    imgWidth={'330px'}
                    imgHeight={'52px'}
                    onClick={props.onClicks?.EMAIL}
                />
            </Col>
        </LoginModal>
    );
}
