import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { IComment } from './comments';
import { IProperty } from '../../api/content';

export interface IStoryFurnitureTag {
    idx?: number;
    tag_idx?: number;
    tag_type: number;
    point_x: number;
    point_y: number;
    furniture_name: string;
    price_discount: number;
    price_original: number;
    furniture_idx: number;
    outlink?: string;
    description?: string;
}
export interface IStoryImageInfo {
    uuid: string;
    image_url: string;
    uploadCompleted: boolean;
}
export interface IStoryPropertyInfo {
    property_idx: number;
    category: number;
    name: string;
}
export interface IStoryProperty extends IStoryPropertyInfo {
    idx: number;
    type: number;
    item_idx: number;
    created_date_time: string;
    status: 'A' | 'D';
}
export interface IStory {
    idx: number;
    memberIdx: number;
    content: string;
    storyLike: number;
    bookmarkCount: number;
    shareCount: number;
    commentCount: number;
    createdDateTime: string;
    name: string;
    recentProfile: string;
    nickname: string;
    imageList: IStoryViewImage[];
    property: IStoryProperty[];
    selectedProperty: number[];
    isLike: boolean;
    isBookmark: boolean;
    summary: string;
}
export interface IStoryWriteInformation {
    area: string;
    constructor_name: string;
    interior_price_min: number;
    interior_price_max: number;
    product_price_min: number;
    product_price_max: number;
    [key: string]: string | number;
}
export interface IStoryArticle {
    idx?: number;
    uuid?: string;
    image_url: string;
    html: string;
    uploadCompleted: boolean;
    tags: IStoryFurnitureTag[];
    keywords: string[];
    property: number[];
    width?: number;
    height?: number;
    filename?: string;
    ratio?: number;
}
export interface IStoryDraftImageResponse {
    idx?: number;
    html: string;
    image: string;
    keyword: string;
    filename: string;
    property: number[];
    tags: IStoryFurnitureTag[];
    width: number;
    height: number;
    ratio: number;
    uploadCompleted?: boolean;
}
export interface IStoryParsedDraft {
    data: IStoryDraftImageResponse[];
    property: number[];
    information?: IStoryWriteInformation;
}
export interface IStoryWriteRequestData {
    data: {
        filename?: string;
        html: string;
        keyword: string;
        idx: string;
        image: string;
        property: number[];
        width?: number;
        height?: number;
        ratio?: number;
        tags: IStoryFurnitureTag[];
    }[];
    property: number[];
    information: IStoryWriteInformation;
}
export interface IStoryViewFurniture {
    idx: number;
    storyIdx: number;
    storyImageIdx: number;
    furnitureIdx: number;
    furnitureName: string;
    type: number;
    pointX: number;
    pointY: number;
    outlink: string;
    description: string;
    onTimesale: number;
    priceOriginal: number;
    priceDiscount: number;
    discountRate: number;
    imageUrl: string;
    isOutside: number;
    tagUIType: number;
    maxDiscountCouponPrice: number;
}
export interface IStoryViewKeyword {
    idx: number;
    keyword: string;
}
export interface IStoryViewImage {
    idx: number;
    filename: string;
    width: number;
    height: number;
    content: string;
    sortIdx: number;
    type: number;
    bookmarkFlag: boolean;
    originalFilename: string;
    property: IProperty[];
    furnitureList: IStoryViewFurniture[];
    keyword: IStoryViewKeyword[];
}
export interface IStoryView {
    idx: number;
    area: string;
    memberIdx: number;
    name: string;
    nickname: string;
    recentProfile: string;
    storyLike: number;
    bookmarkCount: number;
    shareCount: number;
    commentCount: number;
    viewCount: number;
    content: string;
    constructorName: string;
    createdDateTime: string;
    interiorPriceMin: number;
    interiorPriceMax: number;
    productPriceMin: number;
    productPriceMax: number;
    isBest: number;
    isLike: boolean;
    isBookmark: boolean;
    selectedProperty: number[];
    property: IProperty[];
    imageList: IStoryViewImage[];
    otherStoryList: IOtherStory[];
    commentList: IComment[];
    similarStoryList: ISimilarStory[];
}
export interface IOtherStory {
    idx: number;
    content: string;
    storyLike: number;
    bookmarkCount: number;
    shareCount: number;
    commentCount: number;
    tagCount: number;
    createdDateTime: string;
    imageUrl: string;
}
export interface ISimilarStory {
    idx: number;
    memberIdx: number;
    content: string;
    storyLike: number;
    bookmarkCount: number;
    shareCount: number;
    commentCount: number;
    createdDateTime: string;
    name: string;
    recentProfile: string;
    nickname: string;
    imageList: IStoryViewImage[];
    property: IProperty;
    selectedProperty: number[];
    summary: string;
    isLike: boolean;
}
/* 신규 api 태그 */
export interface IProductTag {
    idx?: number;
    tagType: number;
    furnitureIdx: number;
    furnitureName: string;
    pointX: number;
    pointY: number;
    outLink?: string;
    description?: string;
}
export interface IStoryImageData {
    idx?: number;
    uuid?: string;
    fileName: string;
    src: string;
    previewSrc?: string;
    html: string;
    keywords: string[];
    width: number;
    height: number;
    ratio: number;
    tags: IProductTag[];
    properties: IProperty[];
    uploadCompleted?: boolean;
    displayError?: boolean;
}
export interface IStoryFormData {
    information: {
        area: string;
        interiorPriceMin: number;
        interiorPriceMax: number;
        productPriceMin: number;
        productPriceMax: number;
        constructorName: string;
    };
    images: IStoryImageData[];
    properties: IProperty[];
}
export interface IStoryWriteData {
    articles: IStoryArticle[];
    property: number[];
    information: IStoryWriteInformation;
}
export interface IStoryState {
    isMobile: boolean;
    isPC: boolean;
    storyList: IStory[];
    storyView: IStoryView | null;
    isModify: boolean;
    isChange: boolean; // 초기 form 데이터에서 변한 것이 있는지
    isRequired: boolean;
    displayValidationError: boolean;
    uploadCurrent: number;
    uploadTotal: number;
    propertyList: IProperty[];
    writeCurrentData: IStoryWriteData;
    writeLoadData: IStoryWriteData;
    files: File[];
}

export const formInitialData = {
    articles: [],
    property: [],
    information: {
        area: '',
        constructor_name: '',
        interior_price_min: 0,
        interior_price_max: 0,
        product_price_min: 0,
        product_price_max: 0,
    },
};
const initialState: IStoryState = {
    isMobile: false,
    isPC: false,
    storyList: [],
    storyView: null,
    isModify: false,
    isChange: false,
    isRequired: false,
    displayValidationError: false,
    uploadCurrent: 0,
    uploadTotal: 0,
    propertyList: [],
    writeCurrentData: formInitialData,
    writeLoadData: formInitialData,
    files: [],
};

const storySlice = createSlice({
    name: 'story',
    initialState,
    reducers: {
        setStoryView: (state: IStoryState, action) => {
            state.storyView = action.payload;
        },
        resetFormData: (state: IStoryState, _action: AnyAction) => {
            state.writeCurrentData = { ...formInitialData };
            state.writeLoadData = { ...formInitialData };
            state.isRequired = false;
            state.isChange = false;
            state.displayValidationError = false;
            state.uploadCurrent = 0;
            state.uploadTotal = 0;
        },
        isWriteChange: (state: IStoryState, action) => {
            state.isChange = action.payload;
        },
        isWriteRequired: (state: IStoryState, action) => {
            state.isRequired = action.payload;
        },
        setDisplayValidationError: (state: IStoryState, action) => {
            state.displayValidationError = action.payload;
        },
        changeModifyFlag: (state: IStoryState, action) => {
            state.isModify = action.payload;
        },
        updateStoryList: (state: IStoryState, action) => {
            state.storyList = action.payload;
        },
        pushStoryList: (state: IStoryState, action) => {
            const { list } = action.payload;
            state.storyList = list;
            (list || []).forEach((it: IStory) => state.storyList.push(it));
        },
        getStoryWriteInit: (state: IStoryState, action: PayloadAction<IStoryWriteData>) => {
            state.writeLoadData = action.payload;
            state.writeCurrentData = action.payload;
            state.isChange = false;
        },
        setType: (state: IStoryState, action) => {
            state.isPC = !(state.isMobile = action.payload === 'mobile');
        },
        increaseUploadCurrent: (state: IStoryState, action) => {
            state.uploadCurrent += action.payload;
        },
        increaseUploadTotal: (state: IStoryState, action) => {
            state.uploadTotal += action.payload;
        },

        // legacy 작성폼
        setProperty: (state: IStoryState, action) => {
            const { currentIndex, typeValue } = action.payload;

            if (currentIndex === -1) {
                state.writeCurrentData.property = [
                    ...state.writeCurrentData.property,
                    Number(typeValue),
                ];
            } else {
                state.writeCurrentData.property[currentIndex] = Number(typeValue);
            }
        },
        setValue: (state: IStoryState, action) => {
            const {
                value,
                valueType,
            }: { value: string | number; valueType: keyof IStoryWriteInformation } = action.payload;
            state.writeCurrentData.information[valueType] = value;
        },
        setPrice: (state: IStoryState, action) => {
            const { minValue, maxValue, priceType } = action.payload;
            state.writeCurrentData.information[`${priceType}_price_min`] = minValue;
            state.writeCurrentData.information[`${priceType}_price_max`] = maxValue;
        },
        setArea: (state: IStoryState, action) => {
            const { areaType, typeValue } = action.payload;
            const arr = [];
            if (areaType === 'area') {
                arr[0] = typeValue;
            } else {
                arr[0] = state.writeCurrentData.information.area.split(' ')[0];
                arr[1] = typeValue;
            }
            state.writeCurrentData.information.area = arr.join(' ');
        },
        deleteArticleItem: (state: IStoryState, action) => {
            const { index } = action.payload;
            state.writeCurrentData.articles.splice(index, 1);
        },
        setArticleProperty: (state: IStoryState, action) => {
            const { currentIndex, articleIndex, value } = action.payload;

            if (typeof currentIndex === 'number' && currentIndex !== -1) {
                state.writeCurrentData.articles[articleIndex].property[currentIndex] = value;
            } else {
                state.writeCurrentData.articles[articleIndex].property = [
                    ...state.writeCurrentData.articles[articleIndex].property,
                    value,
                ];
            }
        },
        editArticleImage: (state: IStoryState, action) => {
            const { articleIndex, imageInfo } = action.payload;

            state.writeCurrentData.articles[articleIndex].image_url = imageInfo.image_url;
            state.writeCurrentData.articles[articleIndex].uploadCompleted =
                imageInfo.uploadCompleted;
            state.writeCurrentData.articles[articleIndex].uuid = imageInfo.uuid;
        },
        addArticleKeyword: (state: IStoryState, action) => {
            const { index, value } = action.payload;
            state.writeCurrentData.articles[index].keywords = value;
        },
        deleteArticleKeyword: (state: IStoryState, action) => {
            const { articleIndex, keywordIndex } = action.payload;
            state.writeCurrentData.articles[articleIndex].keywords.splice(keywordIndex, 1);
        },
        setArticleContent: (state: IStoryState, action) => {
            const { index, value } = action.payload;
            state.writeCurrentData.articles[index].html = value;
        },
        addArticleItem: (state: IStoryState, action) => {
            const { article } = action.payload;
            state.writeCurrentData.articles.push(article);
        },
        moveArticleItem: (state: IStoryState, action) => {
            const { articles } = action.payload;
            state.writeCurrentData.articles = articles;
        },
        imageUploadCompleted: (
            state: IStoryState,
            action: PayloadAction<{
                index: number;
                data: { width: number; height: number; filename: string; image_url: string };
            }>,
        ) => {
            const { index, data } = action.payload;

            state.writeCurrentData.articles[index].uploadCompleted = true;
            state.writeCurrentData.articles[index].width = data.width;
            state.writeCurrentData.articles[index].height = data.height;
            state.writeCurrentData.articles[index].filename = data.filename;
            state.writeCurrentData.articles[index].image_url = data.image_url;
            state.writeCurrentData.articles[index].ratio = 1;
        },
        addFile: (state: IStoryState, action) => {
            const { files }: { files: File[] } = action.payload;
            state.files = state.files.concat(files);
        },
        addPreviewImage: (state: IStoryState, action) => {
            const { index, preview } = action.payload;
            state.writeCurrentData.articles[index].image_url = preview;
        },
        setTags: (state: IStoryState, action) => {
            const { articleIndex, tagList } = action.payload;
            state.writeCurrentData.articles[articleIndex].tags = tagList;
        },
    },
    extraReducers: {
        [HYDRATE]: (state: IStoryState, action) => {
            return {
                ...state,
                ...action.payload.story,
            };
        },
    },
});

export const storyActions = storySlice.actions;
export default storySlice;
