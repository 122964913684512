import React from 'react';
import styled from 'styled-components';

const PageContainerStyle = styled.div`
    position: relative;
    padding: 80px 0 140px;
    width: 400px;
    min-height: 65vh;
`;

interface PageContainerProps {
    children: JSX.Element | JSX.Element[];
}

export const PageContainer = ({ children }: PageContainerProps) => {
    return <PageContainerStyle>{children}</PageContainerStyle>;
};
