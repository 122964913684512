import { LoginModal } from '.';
import { Col, Row, Text } from '../../../../components/layout';
import COLOR from '../../../styles/colors';
import { LoginButton } from '../../../../components/signup';
import styled from 'styled-components';
import Image from 'next/image';
import { useEffect } from 'react';
import Amplitude from 'amplitude-js';

interface CouponModalProps {
    close: () => void;
}

const CouponContainer = styled.div`
    border-radius: 7px;
    width: 375px;
    height: 446px;
    background: #fafafa;
`;

const ImageContainer = styled.div`
    border-radius: 7px 7px 0 0;
    overflow: hidden;
    height: 283px;
`;

const TopFixed = styled.div`
    position: fixed;
    top: 16px;
    right: 16px;
    height: 24px;
    width: 24px;
    text-align: center;
    z-index: 30000;
`;

const CloseButton = styled.button``;

export default function CouponModal(props: CouponModalProps) {
    useEffect(() => {
        Amplitude.getInstance().logEvent('Popup', {
            Title: '웰컴 쿠폰',
            Script: '',
        });
    }, []);

    return (
        <LoginModal {...props} width={'375px'} height={`446px`}>
            <CouponContainer>
                <TopFixed onClick={() => props.close()}>
                    <CloseButton>
                        <Image
                            src={`//cdn.ggumim.co.kr/storage/202203211205382q3dSz2lOl.png`}
                            unoptimized
                            width={'24px'}
                            height={'24px'}
                        />
                    </CloseButton>
                </TopFixed>
                <ImageContainer>
                    <Image
                        src={'//cdn.ggumim.co.kr/storage/20220907115843wU30OZ5AXc.png'}
                        unoptimized
                        width={'375px'}
                        height={'283px'}
                    />
                </ImageContainer>
                <Col align={'center'} margin={'16px 0 0 0'}>
                    <Text
                        fontWeight={400}
                        fontSize={'18px'}
                        lineHeight={'22px'}
                        color={COLOR.GREY12}
                    >
                        집꾸미기와 함께
                    </Text>
                    <Text
                        fontWeight={400}
                        fontSize={'18px'}
                        lineHeight={'22px'}
                        color={COLOR.GREY12}
                        margin={'0 0 0 0'}
                    >
                        <Text fontWeight={700}> 쉽고 편한 인테리어 </Text> 시작해보세요
                    </Text>
                    <Text
                        fontWeight={500}
                        fontSize={'11px'}
                        lineHeight={'11px'}
                        letterSpacing={'-0.2px'}
                        margin={'4px 0 0 0'}
                        color={COLOR.GREY5}
                    >
                        쿠폰을 확인 및 사용하시려면 APP을 설치해 주세요.
                    </Text>
                </Col>
                <Row align={'center'} justify={'center'} margin={'28px 0 12px 0'}>
                    <LoginButton
                        width="348px"
                        height="48px"
                        margin="0 0 0 0"
                        background={COLOR.GREY13}
                        borderRadius="6px"
                        onClick={() => props.close()}
                        fontWeight="700"
                        fontSize="14px"
                        lineHeight="16px"
                        color={COLOR.BG}
                        title={'바로 쇼핑하기'}
                    />
                </Row>
            </CouponContainer>
        </LoginModal>
    );
}
