import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLOR_V4 } from '../../lib/styles/colors';
import { Row, Button, Body2 } from '../layout';
import { STICKY_HEADER_HEIGHT } from '../template/Header';

const Wrapper = styled(Row)`
    width: 100%;
    min-width: 1440px;
    z-index: 50;
    background: ${COLOR_V4.GRAY50};
    border-bottom: 1px solid ${COLOR_V4.GRAY200};
`;

const Container = styled.div`
    width: 1000px;
    margin: 0 auto;
`;

const SearchResultNavItem = styled(Row)`
    padding: 18px 8px;
    cursor: pointer;

    &:hover {
        background: ${COLOR_V4.GRAY200};
    }
`;

interface SearchResultNavProps {
    onTabClick: (type: string) => void;
    selected: string;
}

export const SearchResultNav = (props: SearchResultNavProps) => {
    const { onTabClick, selected } = props;

    const [top, setTop] = useState<number>(0);
    const [prevScrollPos, setPrevScrollPos] = useState<number>(0);

    const onScroll = (_e: Event) => {
        const currentScrollPos = window.pageYOffset;

        if (currentScrollPos < prevScrollPos) {
            setTop(STICKY_HEADER_HEIGHT);
        } else {
            setTop(-STICKY_HEADER_HEIGHT);
        }

        setPrevScrollPos(window.pageYOffset);
    };

    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [prevScrollPos]);

    const menus = [
        { name: '통합', isActive: selected === 'all', onClick: () => onTabClick('all') },
        {
            name: '스토어',
            isActive: selected === 'furniture',
            onClick: () => onTabClick('furniture'),
        },
        { name: '집들이', isActive: selected === 'star', onClick: () => onTabClick('star') },
        { name: '사진', isActive: selected === 'picture', onClick: () => onTabClick('picture') },
    ];

    return (
        <Wrapper
            id={'search-nav-wrapper'}
            style={{
                top,
                position: 'sticky',
                transition: 'top 0.3s',
            }}
        >
            <Container>
                <Row height={'56px'} align={'center'}>
                    {menus.map((menu) => (
                        <SearchResultNavItem key={menu.name}>
                            <Button onClick={menu.onClick} background={'none'}>
                                <Body2
                                    fontWeight={'bold'}
                                    color={menu.isActive ? COLOR_V4.PINK500 : COLOR_V4.GRAY900}
                                >
                                    {menu.name}
                                </Body2>
                            </Button>
                        </SearchResultNavItem>
                    ))}
                </Row>
            </Container>
        </Wrapper>
    );
};
