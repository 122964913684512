import styled from 'styled-components';
import { Display2 } from '../../components/layout';

interface TitleWithBarProps {
    text: string;
}

const Title = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
`;

const TitleBar = styled.div`
    border: 0.5px solid #f1f2f6;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: 748px;
`;

export const TitleWithBar = (props: TitleWithBarProps) => {
    return (
        <>
            <Title>
                <Display2 fontWeight={'bold'}>{props.text}</Display2>
            </Title>
            <TitleBar />
        </>
    );
};
