import styled from 'styled-components';
import { default as NextImage } from 'next/image';
import { Col } from '../layout';

interface PictureCardRootProps {
    href: string;
    children?: React.ReactNode | React.ReactNode[];
}

function PictureCardRoot(props: PictureCardRootProps) {
    const { href, children } = props;
    return (
        <a href={href}>
            <Col>{children}</Col>
        </a>
    );
}

/* 게시글 사진 */
interface PictureCardImageProps {
    src: string;
    width?: number;
    height?: number;
}

const PictureCardImageStyle = styled.div`
    border-radius: 6px;
    background: #c4c4c4;
    overflow: hidden;

    width: 100%;
    padding-top: 100%;

    position: relative;
`;

function PictureCardImage(props: PictureCardImageProps) {
    const { src } = props;

    return (
        <PictureCardImageStyle>
            <NextImage unoptimized src={src} alt={''} layout="fill" objectFit={'cover'} />
        </PictureCardImageStyle>
    );
}

/* 사진 올린이 프로필 */
/*interface PictureCardProfileProps {
    src: string;
    children: string;
    margin?: string;
    pictureMargin?: string;
}

const ProfileContainer = styled.div<{ margin: string; width: number; height: number }>`
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${(props) => props.margin};
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
`;

function PictureCardProfile(props: PictureCardProfileProps) {
    const { src, children, margin, pictureMargin = '0 8px 0 0' } = props;
    return (
        <Row margin={margin} align={'center'}>
            <ProfileContainer margin={pictureMargin} width={28} height={28}>
                <NextImage unoptimized src={src} alt={''} width={28} height={28} />
            </ProfileContainer>
            <Text
                fontWeight={400}
                fontSize={'16px'}
                lineHeight={'28px'}
                color={COLOR_V4.GRAY900}
                letterSpacing={'-0.02em'}
            >
                {children}
            </Text>
        </Row>
    );
}*/

const Root = PictureCardRoot;
const Image = PictureCardImage;
// const Profile = PictureCardProfile;

export { Root, Image /*, Profile*/ };
