export const COLOR = {
    // Coral
    CORAL1: '#ffecec',
    CORAL2: '#f6a3a6',
    CORAL3: '#f28882',
    CORAL4: '#ff7473',
    CORAL5: '#ff585d',
    CORAL6: '#ee6d6d',
    CORAL7: '#d11e23',
    // Blue
    BLUE1: '#e0e6ff',
    BLUE2: '#7385d8',
    BLUE3: '#5066ce',
    // Green
    GREEN1: '#cde8ec',
    GREEN2: '#5cb0b5',
    GREEN3: '#1999A1',
    // Grey
    GREY1: '#f7f8f9',
    GREY2: '#f1f3f6',
    GREY3: '#e9ebee',
    GREY4: '#c5c8ce',
    GREY5: '#aaafb9',
    GREY6: '#90a4ae',
    GREY7: '#898f94',
    GREY8: '#646f7c',
    GREY9: '#535b63',
    GREY10: '#40464c',
    GREY11: '#333c45',
    GREY12: '#28323c',
    GREY13: '#181d1f',
    GREY14: '#000000',

    // Gradient
    GRADIENT1: 'linear-gradient(180deg, #F46A2F -18.74%, #FF64A5 118.76%);',
    // Semantic
    DIM1: 'rgba(0, 0, 0, 0.6)',
    DIM2: 'rgba(0, 0, 0, 0.2)',
    BG: '#ffffff',
    PRESSED: '#f7f8f9',
    HOVER: '#fcfcfc',
    DISABLE: '#c5c8ce',
};

// 디자인시스템 ver.4 (2023.10 ~)
export const COLOR_V4 = {
    // Pink
    PINK50: '#FFECEC',
    PINK100: '#FFCECE',
    PINK200: '#FFB9B9',
    PINK300: '#FF9595',
    PINK400: '#FF7478',
    PINK500: '#FF6569', //Primary
    PINK600: '#FF585D',
    PINK700: '#E44449',
    PINK800: '#C13135',
    PINK900: '#B42529',
    // Gray
    WHITE: '#FFFFFF',
    GRAY50: '#F7F8F9',
    GRAY100: '#F1F3F6',
    GRAY200: '#E8EAEE', //Border
    GRAY300: '#B3BAC1',
    GRAY400: '#878F96',
    GRAY500: '#505863',
    GRAY600: '#333C45',
    GRAY700: '#293137',
    GRAY800: '#1F2528',
    GRAY900: '#181d1f',
    BLACK: '#000000',
    // Dim
    DIM1: 'rgba(24, 29, 31, 0.5)',
    DIM2: 'rgba(24, 29, 31, 0.8)',
    // Gradient
    GRADIENT1: 'linear-gradient(180deg, #F46A2F 0%, #FF64A5 100%);',
    GRADIENT2: 'linear-gradient(180deg, #FFB100 0%, #FFC800 100%);',
    GRADIENT3: 'linear-gradient(180deg, #5AC3CD 0%, #58CD8E 100%);',
    GRADIENT4: 'linear-gradient(180deg, #8796FF 0%, #50AFFF 100%);',
    GRADIENT5: 'linear-gradient(180deg, #544B60 0%, #606060 100%);',
    // BoxShadow
    SHADOW: '0px 2px 8px 0px rgba(100, 111, 124, 0.10);',
    // System
    RED: '#FF3B30',
    ORANGE: '#FF9500',
    GREEN: '#34C759',
    BLUE: '#007AFF',
    YELLOW: '#FFC800',
};

export default COLOR;
