import styled from 'styled-components';

interface IWrapperProps {
    height?: string;
    padding?: string;
    margin?: string;
    background?: string;
    borderTop?: string;
    overscroll?: string;
}

export const Wrapper = styled.div<IWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 1440px;
    height: ${({ height }) => (height ? `${height}` : 'auto')};
    ${({ padding }) => padding && `padding: ${padding}`};
    ${({ margin }) => margin && `margin: ${margin}`};
    background: ${({ background }) => (background ? `${background}` : '#ffffff')};
    ${({ overscroll }) => overscroll && `overscroll-behavior: ${overscroll};`}

    @media (max-width: 1000px) {
        width: 1000px;
    }
`;

export const GridWrapper = styled.div<IWrapperProps>`
    min-width: 1000px;
    width: 1000px;
    height: ${({ height }) => (height ? `${height}` : 'auto')};
    ${({ padding }) => (padding ? `padding: ${padding};` : '')}
    ${({ borderTop }) => (borderTop ? `border-top: ${borderTop};` : '')}
    background: ${({ background }) => (background ? `${background}` : 'none')};
    z-index: 50;
`;
