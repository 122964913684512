import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import Image from 'next/image';
import styled from 'styled-components';
import { COLOR_V4 } from '../../lib/styles/colors';
import { Product, getRecentView } from '../../api/search';
import { Col, Body3, Caption2, Row } from '../layout';
import useChannelTalk from '../../lib/hooks/useChannelTalk';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
SwiperCore.use([Pagination, Navigation]);

import Amplitude from 'amplitude-js';
import { _getCartCount } from './Header';
import { ProductCard } from '../product';

const FloatingContainer = styled(Col)`
    position: sticky;
    padding: 0 8px;
    margin: 0 20px 60px 0;
    width: 88px;
    height: max-content;
    background: ${COLOR_V4.WHITE};
    border: 1px solid ${COLOR_V4.GRAY200};
    border-radius: 4px;

    @media (max-width: 1340px) {
        display: none;
    }
`;

const RecentProductsWrapper = styled(Col)`
    .swiper-wrapper {
        margin: 0 0 26px;
    }

    .swiper-container {
        display: flex;
        flex-direction: column;
        width: 72px;
    }

    .swiper-pagination {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        bottom: 0px;
        z-index: 100;

        .swiper-pagination-button {
            margin: 0 0 -1px;
            font-size: 13px;
            line-height: 18px;
            color: ${COLOR_V4.GRAY300};
        }

        .swiper-pagination-current {
            color: ${COLOR_V4.GRAY900};
            font-weight: bold;
        }

        .swiper-pagination-total {
            color: ${COLOR_V4.GRAY300};
            font-weight: 500;
        }
    }

    .swiper-button-prev {
        background-image: url('//cdn.ggumim.co.kr/storage/20231110091847ChRbGIvjuw.png');
        transform: rotate(180deg);
    }

    .swiper-button-next {
        background-image: url('//cdn.ggumim.co.kr/storage/20231110091847ChRbGIvjuw.png');
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute !important;
        top: unset !important;
        bottom: 0px !important;
        width: 16px !important;
        height: 16px !important;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        z-index: 200;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        display: none;
    }

    .swiper-button-prev {
        left: 0px !important;
    }

    .swiper-button-next {
        right: 0px !important;
    }
`;

const CartCount = styled(Row)`
    position: absolute;
    top: 14px;
    left: 37px;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    width: max-content;
    min-width: 14px;
    height: 14px;
    border-radius: 80px;
    background-color: ${COLOR_V4.PINK500};
`;

type TPagination = {
    type?: 'bullets' | 'fraction' | 'progressbar' | 'custom';
    renderFraction?: (currentClass: string, totalClass: string) => void;
};
type TMenu = { idx: number; name: string; render: () => ReactNode; handleClick?: () => void };
type TPage = { pathname: string; top: number };

const FloatingButton = () => {
    const router = useRouter();

    const { data: cartCount } = useSWR('/purchase/v2/cart/count', _getCartCount);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [recentProductGroup, setRecentProductGroup] = useState<Product[][]>([]);
    const getRecentProducts = async () => {
        const recentProductsResponse: Product[] = await getRecentView({
            pageSize: 9,
        });
        if (recentProductsResponse) {
            const productGroup: Product[][] = recentProductsResponse.reduce<Product[][]>(
                (acc, curr, index) => {
                    const subArrayIndex = Math.floor(index / 3);

                    if (!acc[subArrayIndex]) {
                        acc[subArrayIndex] = [];
                    }

                    acc[subArrayIndex].push(curr);

                    return acc;
                },
                [],
            );

            setRecentProductGroup(productGroup);
        } else {
            setRecentProductGroup([]);
        }

        setIsLoading(false);
    };

    const targetPages: TPage[] = useMemo(
        () => [
            { pathname: '/', top: 460 },
            { pathname: '/home/promotion', top: 50 },
            { pathname: '/furniture', top: 520 },
            { pathname: '/furniture/view/[idx]', top: 90 },
            { pathname: '/furniture/more/[depth]', top: 80 },
            { pathname: '/furniture/best', top: 30 },
            { pathname: '/furniture/best/total', top: 30 },
            { pathname: '/furniture/best/category', top: 30 },
            { pathname: '/furniture/brand/[idx]', top: 30 },
            { pathname: '/commerce/rel_more/[idx]', top: 100 },
            { pathname: '/commerce/promotion/view/[idx]', top: 30 },
            { pathname: '/search/results', top: 100 },
        ],
        [],
    );
    const [isShow, setIsShow] = useState<boolean>(true);
    const [top, setTop] = useState<number>(0);
    const [scrollTop, setScrollTop] = useState<number>(90);
    const onScroll = () => {
        const navBar: HTMLElement | null =
            document.getElementById('store-nav-wrapper') ||
            document.getElementById('search-nav-wrapper');

        let floatingTop: number = 0;
        if (navBar) {
            const navTop = window.getComputedStyle(navBar).top;
            floatingTop = parseFloat(navTop);
        }
        setScrollTop(floatingTop > 0 ? 80 + floatingTop : 90);
    };
    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        const targetPageIndex = targetPages.findIndex((page) => page.pathname === router.pathname);
        if (targetPageIndex < 0) {
            setIsShow(false);
            return;
        }
        getRecentProducts();
        setIsShow(true);
        setTop(targetPages[targetPageIndex].top);
    }, [router.pathname]);

    const handleClickScrollUp = useCallback(() => window.scrollTo({ top: 0 }), []);

    const { openChannelTalk } = useChannelTalk();

    const pagination: TPagination = {
        type: 'fraction',
        renderFraction: () => {
            return `
                <button class="swiper-pagination-button">
                    <span class="swiper-pagination-current"></span>
                        /
                    <span class="swiper-pagination-total"></span>
                </button>
            `;
        },
    };

    const menus: TMenu[] = useMemo(
        () => [
            {
                idx: 1,
                name: '장바구니',
                render: () => (
                    <Col
                        position={'relative'}
                        align={'center'}
                        justify={'center'}
                        padding={'14px 0'}
                        width={'100%'}
                        borderBottom={`1px solid ${COLOR_V4.GRAY200}`}
                    >
                        <Image
                            width={24}
                            height={24}
                            src={'https://cdn.ggumim.co.kr/storage/202311131534285hNKEBZYG6.png'}
                            alt={'장바구니'}
                        />
                        {cartCount && cartCount > 0 ? (
                            <CartCount>
                                <Caption2
                                    padding={'1px 0 0'}
                                    color={COLOR_V4.WHITE}
                                    fontWeight={'bold'}
                                >
                                    {cartCount > 99 ? '99+' : cartCount}
                                </Caption2>
                            </CartCount>
                        ) : (
                            <></>
                        )}
                        <Body3 margin={'4px 0 0'} fontWeight={'bold'} color={COLOR_V4.GRAY900}>
                            장바구니
                        </Body3>
                    </Col>
                ),
                handleClick: () => {
                    location.href = '/commerce/cart';
                },
            },
            {
                idx: 2,
                name: '1:1 문의',
                render: () => (
                    <Col
                        align={'center'}
                        justify={'center'}
                        padding={'14px 0'}
                        width={'100%'}
                        borderBottom={`1px solid ${COLOR_V4.GRAY200}`}
                    >
                        <Image
                            width={24}
                            height={24}
                            src={'https://cdn.ggumim.co.kr/storage/20231113153450XbkEAeIcfC.png'}
                            alt={'1:1 문의'}
                        />
                        <Body3 margin={'4px 0 0'} fontWeight={'bold'} color={COLOR_V4.GRAY900}>
                            1:1 문의
                        </Body3>
                    </Col>
                ),
                handleClick: () => openChannelTalk('showMessenger'),
            },
            {
                idx: 3,
                name: '최근 본 상품',
                render: () =>
                    recentProductGroup.length > 0 && (
                        <Col
                            align={'center'}
                            justify={'center'}
                            padding={'14px 0'}
                            width={'100%'}
                            borderBottom={`1px solid ${COLOR_V4.GRAY200}`}
                        >
                            <Body3 margin={'0 0 2px'} fontWeight={'bold'} color={COLOR_V4.GRAY900}>
                                최근 본 상품
                            </Body3>

                            <RecentProductsWrapper>
                                <Swiper
                                    initialSlide={0}
                                    spaceBetween={14}
                                    slidesPerView={1}
                                    speed={300}
                                    loop={true}
                                    navigation={true}
                                    pagination={pagination}
                                >
                                    {recentProductGroup.map((group, idx) => (
                                        <SwiperSlide key={`group-${idx}`}>
                                            {group.map((product) => (
                                                <Col key={product.idx} margin={'6px 0 0'}>
                                                    <ProductCard.Root
                                                        key={product.idx}
                                                        href={`/furniture/view/${product.idx}`}
                                                        useNextLink={false}
                                                        handleClickProductCard={() => {
                                                            Amplitude.getInstance().logEvent(
                                                                'Main_Floating',
                                                                {
                                                                    BtnName: '최근 본 상품',
                                                                    ProductId: product.idx,
                                                                    ProductName: product.name,
                                                                },
                                                            );
                                                        }}
                                                    >
                                                        <ProductCard.Image
                                                            src={product.filename}
                                                            width={'72px'}
                                                        />
                                                    </ProductCard.Root>
                                                </Col>
                                            ))}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </RecentProductsWrapper>
                        </Col>
                    ),
            },
            {
                idx: 4,
                name: 'scroll_up',
                render: () => (
                    <Col
                        align={'center'}
                        justify={'center'}
                        padding={'16px 0'}
                        width={'100%'}
                        cursor={'pointer'}
                    >
                        <Image
                            width={24}
                            height={24}
                            src={'https://cdn.ggumim.co.kr/storage/20231110092833BUFE8Za9wN.png'}
                            alt={'scroll_up'}
                        />
                    </Col>
                ),
                handleClick: () => handleClickScrollUp(),
            },
        ],
        [cartCount, recentProductGroup],
    );

    return isLoading || !isShow ? (
        <></>
    ) : (
        <Col
            position={'absolute'}
            top={`${top}px`}
            right={'calc(50% - 720px)'}
            bottom={'0px'}
            zIndex={999}
        >
            <FloatingContainer top={`${scrollTop}px`} boxShadow={COLOR_V4.SHADOW}>
                {menus.map((menu: TMenu) => (
                    <Col
                        key={`menu-${menu.idx}`}
                        cursor={menu.handleClick ? 'pointer' : 'default'}
                        onClick={() => {
                            if (menu.handleClick) {
                                Amplitude.getInstance().logEvent('Main_Floating', {
                                    BtnName: menu.name,
                                });
                                menu.handleClick();
                            }
                        }}
                    >
                        {menu.render()}
                    </Col>
                ))}
            </FloatingContainer>
        </Col>
    );
};

export default FloatingButton;
