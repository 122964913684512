import React from 'react';
import { IconProps } from '.';

interface SearchProps extends IconProps {}

const Search = ({ color, width, height, style, ...args }: SearchProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <path
                d="M19.4837 18.4856L15.4671 14.5569C17.4866 11.989 17.2847 8.27984 14.8612 5.93141C13.5373 4.63649 11.8319 4 10.1041 4C8.37628 4 6.6709 4.63649 5.34698 5.93141C2.72158 8.49931 2.72158 12.6694 5.34698 15.2373C6.6709 16.5322 8.37628 17.1687 10.1041 17.1687C11.5402 17.1687 12.9763 16.7298 14.1656 15.8299L18.2047 19.7366C18.3842 19.9122 18.6086 20 18.8554 20C19.0798 20 19.3267 19.9122 19.5062 19.7366C19.8428 19.4074 19.8428 18.8368 19.4837 18.4856ZM10.1265 15.3909C8.80263 15.3909 7.59091 14.8861 6.64846 13.9863C4.74112 12.1207 4.74112 9.06996 6.64846 7.18244C7.56847 6.28258 8.80263 5.77778 10.1265 5.77778C11.4505 5.77778 12.6622 6.28258 13.6046 7.18244C14.5471 8.0823 15.0408 9.28944 15.0408 10.5844C15.0408 11.8793 14.5247 13.0645 13.6046 13.9863C12.6846 14.9081 11.428 15.3909 10.1265 15.3909Z"
                fill="#959A9E"
            />
        </svg>
    );
};

export default Search;
