import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import styled from 'styled-components';
import { COLOR_V4 } from '../../lib/styles/colors';
import { go } from '../../lib/utils';
import { getStoreHomeFeed } from '../../api/commerce';
import { Body2, Row } from '../layout';
import { STICKY_HEADER_HEIGHT } from '../template/Header';

import SwiperClass from 'swiper/types/swiper-class';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel } from 'swiper/core';
SwiperCore.use([Mousewheel]);

export const STORE_NAV_HEIGHT = 56;

interface ISwiperButtonProps {
    direction: string;
    disabled: boolean;
}

interface MenuProps {
    title: string;
    onClick: () => void;
    iconType?: string | number;
    imageUrl?: string;
    isActive?: boolean;
}

const StoreNavWrap = styled(Row)`
    position: relative;
    align-items: center;
    justify-content: center;
    height: ${STORE_NAV_HEIGHT}px;
    min-width: 1440px;
    background: ${COLOR_V4.GRAY50};
    border-bottom: 1px solid ${COLOR_V4.GRAY200};

    .swiper-container {
        width: 1130px;
        padding: 0 60px;
    }
    .swiper-slide {
        flex-shrink: unset !important;
        width: max-content;
    }
`;

const StoreNavItem = styled.li`
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 18px 8px;
    cursor: pointer;

    &:hover {
        background: ${COLOR_V4.GRAY200};
    }
`;

const StoreNavCenterWrap = styled.ul`
    position: relative;
    display: flex;
    width: 1000px;
    list-style: none;
    overflow-x: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`;

const StoreNavItemText = styled(Body2)`
    width: max-content;
    font-weight: medium;
    color: ${COLOR_V4.GRAY900};

    &.active {
        font-weight: bold;
        color: ${COLOR_V4.PINK500};
    }
`;

const SwiperButton = styled.div<ISwiperButtonProps>`
    position: absolute !important;
    display: flex;
    align-items: center;
    width: 120px;
    height: ${STORE_NAV_HEIGHT - 1}px;
    margin-top: -${STORE_NAV_HEIGHT}px;
    z-index: 200;

    .icon {
        width: 16px !important;
        height: 16px !important;
        background-position: center;
        background-size: cover;
        ${({ disabled }) => (disabled ? `opacity: 0` : 'cursor: pointer')};
    }

    ${({ direction }) =>
        direction === 'right' &&
        `
        right: calc(50% - 620px);
        background: linear-gradient(90deg, transparent 0%, ${COLOR_V4.GRAY50} 5%);

        .right-icon {
            margin: 0 0 0 5px;
            background-image: url("//cdn.ggumim.co.kr/storage/20231110100036RrBXGXkMo9.png");
        }
    `}

    ${({ direction }) =>
        direction === 'left' &&
        `
        left: calc(50% - 620px);
        justify-content: flex-end;
        background: linear-gradient(270deg, transparent 0%, ${COLOR_V4.GRAY50} 5%);

        .left-icon {
            margin: 0 5px 0 0;
            background-image: url("//cdn.ggumim.co.kr/storage/20231110100036RrBXGXkMo9.png");
            transform: rotate(180deg);
        }
    `}
`;

const StoreNav: React.FC<any> = () => {
    const router = useRouter();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [menus, setMenus] = useState<MenuProps[]>([]);
    const [top, setTop] = useState<number>(0);
    const [prevScrollPos, setPrevScrollPos] = useState<number>(0);

    const [swiperRef, setSwiperRef] = useState<any>(null);
    const [swiperIndex, setSwiperIndex] = useState(0);
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

    const checkSamePath = (path: string) => {
        const activePath = router.pathname.split('/').slice(1, 3).join('/');
        return path === activePath;
    };

    const checkSamePrevPath = (path: string) => {
        return checkSamePath(path) || router.query?.prev_path === path;
    };

    useEffect(() => {
        const feeds: MenuProps[] = [];
        const asyncMethod = async () => {
            await getStoreHomeFeed()
                .then((res) => {
                    res.data.map((feed: any) => {
                        feeds.push({
                            title: feed.name,
                            onClick: () => {
                                if (!!feed.commercePromotionIdx) {
                                    go(
                                        `/commerce/promotion/view/${feed.commercePromotionIdx}?show_store_nav=true&prev_path=promotion/view/${feed.itemIdx}`,
                                    );
                                } else {
                                    switch (feed.itemType) {
                                        case 'promotion':
                                            go(`/promotion/view/${feed.itemIdx}?show_menu=true`);
                                            break;
                                    }
                                }
                            },
                            iconType: feed.iconType,
                            imageUrl: feed.imageUrl,
                            isActive: checkSamePrevPath(`promotion/view/${feed.itemIdx}`),
                        });
                    });
                })
                .catch((_err) => []);

            await setMenus([
                {
                    title: '스토어홈',
                    onClick: () => go('/furniture'),
                    isActive: checkSamePath('furniture'),
                },
                {
                    title: '베스트',
                    onClick: () => go('/furniture/best?app_target=self&show_store_nav=true'),
                    isActive: checkSamePath('furniture/best'),
                },
                ...feeds,
            ]);

            setIsLoading(false);
        };

        asyncMethod();
    }, []);

    useEffect(() => {
        if (menus) {
            const index = menus.findIndex((menu) => menu.isActive);
            if (index > 0) {
                setActiveSlideIndex(index < 7 ? 0 : index);
            }
        }
    }, [menus]);

    useEffect(() => {
        if (typeof window === undefined) {
            return;
        }

        const onScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const promotionTab: HTMLElement | null = document.querySelector('.promotion-tab');
            if (promotionTab) {
                const styles = window.getComputedStyle(promotionTab);
                if (styles.top !== '0px') {
                    setTop(-STORE_NAV_HEIGHT);
                    return;
                }
            }

            setTimeout(() => {
                if (prevScrollPos !== 0 && currentScrollPos < prevScrollPos) {
                    setTop(STICKY_HEADER_HEIGHT);
                } else {
                    setTop(-STORE_NAV_HEIGHT);
                }
            }, 0);

            setPrevScrollPos(window.pageYOffset);
        };

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [prevScrollPos]);

    const onClickSwiper = (index: number) => {
        setSwiperIndex(index);
        swiperRef ? swiperRef?.slideTo(index, 400) : undefined;
    };

    return !isLoading && menus ? (
        <StoreNavWrap
            id={'store-nav-wrapper'}
            style={{
                top,
                position: 'sticky',
                transition: 'top 0.3s',
            }}
            zIndex={999}
        >
            <Swiper
                slidesPerView={'auto'}
                initialSlide={activeSlideIndex}
                watchSlidesVisibility={true}
                mousewheel={true}
                loop={false}
                onSwiper={setSwiperRef}
                onActiveIndexChange={(swiper: SwiperClass) => {
                    setSwiperIndex(swiper.realIndex);
                }}
            >
                <SwiperButton direction={'left'} disabled={swiperIndex <= 0}>
                    <div className={'icon left-icon'} onClick={() => onClickSwiper(0)}></div>
                </SwiperButton>
                <SwiperButton direction={'right'} disabled={swiperIndex + 12 >= menus.length}>
                    <div
                        className={'icon right-icon'}
                        onClick={() => {
                            onClickSwiper(menus.length);
                        }}
                    ></div>
                </SwiperButton>

                <StoreNavCenterWrap>
                    {menus.map((menu, index) => (
                        <SwiperSlide key={index}>
                            <StoreNavItem onClick={menu.onClick}>
                                <StoreNavItemText className={menu.isActive ? 'active' : ''}>
                                    {menu.title}
                                </StoreNavItemText>
                                {menu.iconType === 1 && (
                                    <Row width={'16px'}>
                                        <Image
                                            alt={'new'}
                                            src={
                                                'https://cdn.ggumim.co.kr/storage/2021032716063213QZsNrzKG.png'
                                            }
                                            width={16}
                                            height={16}
                                        />
                                    </Row>
                                )}
                            </StoreNavItem>
                        </SwiperSlide>
                    ))}
                </StoreNavCenterWrap>
            </Swiper>
        </StoreNavWrap>
    ) : (
        <></>
    );
};

export default StoreNav;
