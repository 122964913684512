import { useEffect } from 'react';
import ReactModal from 'react-modal';
import css from 'styled-jsx/css';

// language=LESS
const style = css.global`
    .react-modal-overlay-alert {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 2000;
    }

    .react-modal-alert {
        z-index: 2000;
        background: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        min-width: 288px;
        border-radius: 7px;
        box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.13);
        top: 50% !important;
        left: 50% !important;
    }
`;

export default function LoginModal(props: {
    close: () => void;
    width?: string;
    height?: string;
    children?: React.ReactNode | React.ReactNode[];
}) {
    useEffect(() => {
        if (typeof window !== 'object') {
            return;
        }
    }, []);

    return (
        <ReactModal
            shouldCloseOnOverlayClick
            ariaHideApp={false}
            isOpen
            onRequestClose={() => props.close()}
            style={{
                overlay: {
                    zIndex: 10000,
                },
                content: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    padding: 0,
                    border: 'none',
                    width: props.width,
                    height: props.height,
                },
            }}
            className="react-modal-alert"
            overlayClassName="react-modal-overlay-alert"
        >
            {props.children}

            <style jsx>{style}</style>
        </ReactModal>
    );
}
