import styled from 'styled-components';
import { go } from '../../lib/utils';
import Image from 'next/image';
import Amplitude from 'amplitude-js';
import getConfig from 'next/config';
import { Button } from '../../components/layout';
import COLOR from '../../lib/styles/colors';

const nextConfig = getConfig();
const { publicRuntimeConfig } = nextConfig || {};
const {
    KAKAO_LINK,
    KAKAO_CLIENT_ID,
    KAKAO_REDIRECT_URL,
    KAKAO_RESPONSE_TYPE,

    NAVER_LINK,
    NAVER_CLIENT_ID,
    NAVER_REDIRECT_URL,
    NAVER_RESPONSE_TYPE,

    GOOGLE_LINK,
    GOOGLE_CLIENT_ID,
    GOOGLE_REDIRECT_URL,
    GOOGLE_RESPONSE_TYPE,

    FACEBOOK_LINK,
    FACEBOOK_CLIENT_ID,
    FACEBOOK_REDIRECT_URL,
    FACEBOOK_RESPONSE_TYPE,

    APPLE_LINK,
    APPLE_CLIENT_ID,
    APPLE_REDIRECT_URL,
    APPLE_RESPONSE_TYPE,
    APPLE_RESPONSE_MODE,
} = publicRuntimeConfig;

interface SocialLoginButtonProps {
    isDisplayNone?: boolean;
    margin: string;
    imgSrc: string;
    imgWidth: string;
    imgHeight: string;
    state?: number[];
    onClick?: () => void;
}

const ButtonWrapper = styled.button<{ isDisplayNone: boolean; margin: string; height: string }>`
    display: ${(props) => props.isDisplayNone && 'none'};
    margin: ${(props) => (props.margin ? props.margin : '0 0 0 0')};
    height: ${(props) => (props.margin ? props.height : '52px')};
`;

const generateRandomString = () => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 3) | 8;

        return v.toString(16);
    });
};

function KakaoButton(props: SocialLoginButtonProps) {
    return (
        <ButtonWrapper
            isDisplayNone={props.isDisplayNone === undefined ? false : props.isDisplayNone}
            margin={props.margin}
            height={props.imgHeight}
            onClick={() => {
                Amplitude.getInstance().logEvent('SignBtn', {
                    Type: 'Kakao',
                });

                if (props.onClick) {
                    props.onClick();
                } else {
                    window.open(
                        `${KAKAO_LINK}?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URL}&response_type=${KAKAO_RESPONSE_TYPE}${
                            props.state !== undefined ? `&state=${props.state}` : ''
                        }`,
                        'newwindow',
                        `width=602,height=709,top=100,left=600`,
                    );
                }
            }}
        >
            <Image src={props.imgSrc} unoptimized width={props.imgWidth} height={props.imgHeight} />
        </ButtonWrapper>
    );
}

function NaverButton(props: SocialLoginButtonProps) {
    return (
        <ButtonWrapper
            isDisplayNone={props.isDisplayNone === undefined ? false : props.isDisplayNone}
            margin={props.margin}
            height={props.imgHeight}
            onClick={() => {
                Amplitude.getInstance().logEvent('SignBtn', {
                    Type: 'Naver',
                });

                if (props.onClick) {
                    props.onClick();
                } else {
                    window.open(
                        `${NAVER_LINK}?response_type=${NAVER_RESPONSE_TYPE}&client_id=${NAVER_CLIENT_ID}&redirect_uri=${NAVER_REDIRECT_URL}${
                            props.state !== undefined
                                ? `&state=${props.state}`
                                : `&state=${encodeURIComponent(generateRandomString())}`
                        }`,
                        'newwindow',
                        `width=636,height=942,top=100,left=600`,
                    );
                }
            }}
        >
            <Image src={props.imgSrc} unoptimized width={props.imgWidth} height={props.imgHeight} />
        </ButtonWrapper>
    );
}

function GoogleButton(props: SocialLoginButtonProps) {
    return (
        <ButtonWrapper
            isDisplayNone={props.isDisplayNone === undefined ? false : props.isDisplayNone}
            margin={props.margin}
            height={props.imgHeight}
            onClick={() => {
                Amplitude.getInstance().logEvent('SignBtn', {
                    Type: 'Google',
                });

                if (props.onClick) {
                    props.onClick();
                } else {
                    window.open(
                        `${GOOGLE_LINK}?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_REDIRECT_URL}&response_type=${GOOGLE_RESPONSE_TYPE}&scope=email profile openid${
                            props.state !== undefined ? `&state=${props.state}` : ''
                        }`,
                        'newwindow',
                        `width=658,height=788,top=100,left=600`,
                    );
                }
            }}
        >
            <Image src={props.imgSrc} unoptimized width={props.imgWidth} height={props.imgHeight} />
        </ButtonWrapper>
    );
}

function FacebookButton(props: SocialLoginButtonProps) {
    return (
        <ButtonWrapper
            isDisplayNone={props.isDisplayNone === undefined ? false : props.isDisplayNone}
            margin={props.margin}
            height={props.imgHeight}
            onClick={() => {
                Amplitude.getInstance().logEvent('SignBtn', {
                    Type: 'Facebook',
                });

                if (props.onClick) {
                    props.onClick();
                } else {
                    window.open(
                        `${FACEBOOK_LINK}?client_id=${FACEBOOK_CLIENT_ID}&redirect_uri=${FACEBOOK_REDIRECT_URL}&response_type=${FACEBOOK_RESPONSE_TYPE}${
                            props.state !== undefined
                                ? `&state=${props.state}`
                                : `&state=${encodeURIComponent(generateRandomString())}`
                        }`,
                        'newwindow',
                        `width=603,height=736,top=100,left=600`,
                    );
                }
            }}
        >
            <Image src={props.imgSrc} unoptimized width={props.imgWidth} height={props.imgHeight} />
        </ButtonWrapper>
    );
}

function AppleButton(props: SocialLoginButtonProps) {
    return (
        <ButtonWrapper
            isDisplayNone={props.isDisplayNone === undefined ? false : props.isDisplayNone}
            margin={props.margin}
            height={props.imgHeight}
            onClick={async () => {
                if (props.onClick) {
                    props.onClick();
                } else {
                    const popup = window.open(
                        '',
                        'newwindow',
                        `width=702,height=735,top=100,left=600`,
                    );

                    if (popup)
                        popup.location.href = `${APPLE_LINK}?client_id=${APPLE_CLIENT_ID}&redirect_uri=${APPLE_REDIRECT_URL}&response_type=${APPLE_RESPONSE_TYPE}&response_mode=${APPLE_RESPONSE_MODE}${
                            props.state !== undefined ? `&state=${props.state}` : ''
                        }`;
                }
                await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();

                Amplitude.getInstance().logEvent('SignBtn', {
                    Type: 'Apple',
                });
            }}
        >
            <Image src={props.imgSrc} unoptimized width={props.imgWidth} height={props.imgHeight} />
        </ButtonWrapper>
    );
}

function EmailButton(props: SocialLoginButtonProps) {
    return (
        <ButtonWrapper
            isDisplayNone={props.isDisplayNone === undefined ? false : props.isDisplayNone}
            margin={props.margin}
            height={props.imgHeight}
            onClick={() => {
                Amplitude.getInstance().logEvent('SignBtn', {
                    Type: 'Email',
                });

                if (props.onClick) {
                    props.onClick();
                } else {
                    go('/login/email_login');
                }
            }}
        >
            <Image src={props.imgSrc} unoptimized width={props.imgWidth} height={props.imgHeight} />
        </ButtonWrapper>
    );
}

function PhoneButton(props: SocialLoginButtonProps) {
    return (
        <Button
            position={'relative'}
            width={'330px'}
            height={'52px'}
            margin={'10px 0 0'}
            border={`1px solid ${COLOR.GREY3}`}
            borderRadius={'10px'}
            onClick={() =>
                window.open('https://sepay.org/spm/join?regSiteCode=FF&ctgCode=1&subCode=1')
            }
        >
            <Image src={props.imgSrc} unoptimized width={props.imgWidth} height={props.imgHeight} />
        </Button>
    );
}

const KAKAO = KakaoButton;
const NAVER = NaverButton;
const GOOGLE = GoogleButton;
const FACEBOOK = FacebookButton;
const APPLE = AppleButton;
const EMAIL = EmailButton;
const PHONE = PhoneButton;

export { KAKAO, NAVER, GOOGLE, FACEBOOK, APPLE, EMAIL, PHONE };
