import styled from 'styled-components';
import { ProgressBarProps } from '../../lib/hooks/useProgressBar';

const Container = styled.div`
    width: 100%;
    position: relative;
    z-index: 1;
`;

const LastStep = styled.li<{ filled: boolean }>`
    float: left;
    width: 0%;
    position: relative;
`;

const OuterCircle = styled.div<{ filled: boolean }>`
    position: relative;
    top: -6px;
    width: 15px;
    height: 15px;
    border: 1.5px solid ${(props) => (props.filled ? 'black' : '#f1f2f6')};
    display: block;
    border-radius: 50%;
    background: white;
    transition: border 0.1s;
`;

const InnerCircle = styled.div<{ filled: boolean }>`
    position: relative;
    width: 9px;
    height: 9px;
    display: block;
    border-radius: 50%;
    background: linear-gradient(to left, #f1f2f6 50%, black 50%) right;
    background-size: 200%;
    background-position: ${(props) => props.filled && 'left'};
    transition: background 0.1s;
    top: 50%;
    left: 50%;
    margin: -4.5px 0px 0px -4.5px;
`;

const Line = styled.li<{ width: string; filled: boolean }>`
    float: left;
    width: ${(props) => (props.width ? props.width : '0%')};
    position: relative;

    height: 3px;
    background: linear-gradient(to left, #f1f2f6 50%, black 50%) right;
    background-size: 200%;
    background-position: ${(props) => props.filled && 'left'};
    z-index: -1;
    transition: background 1s ease 0s;
`;

export const ProgressBarCircle = (props: ProgressBarProps) => {
    return (
        <Container>
            {props.stepList.slice(1, -1).map((_, index) => (
                <Line
                    key={index}
                    width={`${100 / (props.stepList.length - 2)}%`}
                    filled={index <= props.stepList.indexOf(props.step) - 1}
                >
                    <OuterCircle filled={index <= props.stepList.indexOf(props.step) - 1}>
                        <InnerCircle
                            filled={index <= props.stepList.indexOf(props.step) - 1}
                        ></InnerCircle>
                    </OuterCircle>
                </Line>
            ))}
            <LastStep filled={props.stepList.indexOf(props.step) === props.stepList.length - 1}>
                <OuterCircle
                    filled={props.stepList.indexOf(props.step) === props.stepList.length - 1}
                >
                    <InnerCircle
                        filled={props.stepList.indexOf(props.step) === props.stepList.length - 1}
                    ></InnerCircle>
                </OuterCircle>
            </LastStep>
        </Container>
    );
};
