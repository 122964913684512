import React from 'react';
import { IconProps } from '.';

interface BracketProps extends IconProps {}

const Bracket = ({ color, width, height, style, ...args }: BracketProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <path
                d="M5 3L8 6L5.06667 9"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Bracket;
