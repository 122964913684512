import { useEffect } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import css from 'styled-jsx/css';
import { LoginButton } from '../../../../components/signup/LoginButton';
import COLOR from '../../../styles/colors';
import Amplitude from 'amplitude-js';

// language=LESS
const style = css.global`
    .react-modal-overlay-alert {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 2000;
    }

    .react-modal-alert {
        z-index: 2000;
        background: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        min-width: 288px;
        border-radius: 11px;
        box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.13);
        top: 50% !important;
        left: 50% !important;
        width: 327px;
        height: 170px;
    }
`;

const ModalWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-justify-content: space-between;
`;

const ChildrenWrapper = styled.div`
    padding: 24px 24px 0 24px;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: row-reverse;
`;

export default function SmallModal(props: {
    buttonColor: string;
    buttonText: string;
    width?: string;
    height?: string;
    onClickButton: () => void;
    onClickOverlay: () => void;
    children?: React.ReactNode;
}) {
    useEffect(() => {
        if (typeof window !== 'object') {
            return;
        }
        if (props.buttonText === '확인') {
            Amplitude.getInstance().logEvent('Popup', {
                Title: '재설정 유효시간 만료',
                Script: `이메일을 다시 입력해 주세요. 비밀번호 재설정 링크의 유효시간은 1시간입니다.`,
            });
        } else if (props.buttonText === '로그인') {
            Amplitude.getInstance().logEvent('Popup', {
                Title: '비밀번호 재설정 완료',
                Script: `새 비밀번호로 로그인 해주세요.`,
            });
        }
    }, []);

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen
            onRequestClose={props.onClickOverlay}
            style={{
                overlay: {
                    zIndex: 10000,
                },
                content: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    padding: 0,
                    border: 'none',
                    width: props.width,
                    height: props.height,
                },
            }}
            className="react-modal-alert"
            overlayClassName="react-modal-overlay-alert"
        >
            <ModalWrapper>
                <ChildrenWrapper>{props.children}</ChildrenWrapper>
                <ButtonWrapper>
                    <LoginButton
                        width="104px"
                        height="48px"
                        background={props.buttonColor}
                        borderRadius="10px"
                        onClick={props.onClickButton}
                        fontWeight="700"
                        fontSize="14px"
                        lineHeight="20px"
                        color={COLOR.BG}
                        title={props.buttonText}
                    />
                </ButtonWrapper>
            </ModalWrapper>

            <style jsx>{style}</style>
        </ReactModal>
    );
}
