import React, { useMemo, useState } from 'react';
import { createContext } from 'react';

interface GlobalModalContext {
    open: (Component: React.ReactNode, props: any) => void;
    close: (Component: React.ReactNode) => void;
}

export const GlobalModalDispatchContext = createContext<GlobalModalContext>({
    open: () => {},
    close: () => {},
});
export const GlobalModalStateContext = createContext<any>([]);

interface GlobalModalProviderProps {
    children: React.ReactNode | React.ReactNode[];
}

export const GlobalModalProvider = ({ children }: GlobalModalProviderProps) => {
    const [openedModals, setOpenedModals] = useState<any[]>([]);
    const open = (Component: React.ReactNode, props: any) => {
        setOpenedModals((modals: any[]) => {
            return [...modals, { Component, props }];
        });
    };
    const close = (Component: React.ReactNode) => {
        setOpenedModals((modals) => {
            return modals.filter((modal) => {
                return modal.Component !== Component;
            });
        });
    };
    const dispatch = useMemo(() => ({ open, close }), []);
    return (
        <GlobalModalStateContext.Provider value={openedModals}>
            <GlobalModalDispatchContext.Provider value={dispatch}>
                {children}
                {openedModals.map((modal: any, index: number) => {
                    const { Component, props } = modal;
                    return <Component key={index} {...props} />;
                })}
            </GlobalModalDispatchContext.Provider>
        </GlobalModalStateContext.Provider>
    );
};
