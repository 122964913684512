import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import nookies, { setCookie } from 'nookies';
import { useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/swiper-bundle.min.css';
// lib
import GlobalStyle from '../lib/styles/GlobalStyle';
import Fonts from '../lib/styles/Fonts';
import '../lib/styles/icon.css';
// api
import { getNonMemberToken, getUserInfo } from '../api/user';
import { getCategoryTree } from '../api/purchase';
// stores
import wrapper, { AppState } from '../stores';
import { storyActions } from '../stores/story/story';
import { commonActions } from '../stores/common/common';
// components
import Header from '../components/template/Header';
// global modal
import { GlobalModalProvider } from '../lib/hooks/GlobalModal';
// amplitude
import Amplitude, { AmplitudeClient } from 'amplitude-js';
import { parseCookies, destroyCookie } from 'nookies';
import { useLayoutEffect } from 'react';
import { AMPLITUDE_API_KEY, CookieKeys } from '../lib/values';
import { IMember } from '../stores/common/common';
import { NextRouter, useRouter } from 'next/router';
import FloatingButton from '../components/template/FloatingButton';
import { Col } from '../components/layout';
import { Footer } from '../components/template';
import { IFunitureViewAmplitudeData } from '../stores/furniture/furniture';

const theme = {
    colors: {
        primary: '#0070f3',
    },
};

function getAmplitudeIdentify(member: IMember | null) {
    const identify = new Amplitude.Identify();
    identify.set('platform_type', 'Pc Web');
    identify.set('AppVersion', 'Pc Web');
    if (member) {
        identify.set('member_is_confirm', member.isConfirm);
        identify.set('ID', member.idx.toString());
        identify.set('Email', '(none)');
        identify.set('SocialLogin', '(none)');
        identify.set('Level', member.gradeName);
        // Gender (성별)
        // Birthday (생년월일)
        identify.set('Mobile', '(none)');
        // BuyCount
        // FirstBuyDate
        // CartItemCount
        // RegDate (가입일 UTC 시간으로)
        // VisitorIdx
        // Parity
        identify.set('MemberParity', Number(member.idx) % 2);
        identify.set('NotiCount', member.notiCount);
        identify.set('Point', member.point ? member.point.toString() : '');
        // BookmarkCount (보관함 개수)
        // OrderCount (주문 개수)
        identify.set('CouponCount', member.couponCount);
    }

    return identify;
}

interface CustomEventData {
    event?: string;
    [key: string]: any;
}

declare global {
    interface Window {
        dataLayer: any[];
    }
}

interface IPageViewNames {
    [key: string]:
        | {
              eventName: string;
              pageKoreanName: string;
          }
        | undefined;
}

function logAmplitudePageView(
    amplitudeInstance: AmplitudeClient,
    router: NextRouter,
    Referrer: string = '',
    amplitudeData: null | IFunitureViewAmplitudeData,
) {
    try {
        const PageViewNames: IPageViewNames = {
            /* 홈 */
            '/': {
                eventName: 'PageView',
                pageKoreanName: '홈',
            },
            /* 상품상세*/
            '/furniture/view/[idx]': {
                eventName: 'PageView',
                pageKoreanName: '상품상세',
            },
            /* 장바구니 */
            '/commerce/cart': {
                eventName: 'PageView',
                pageKoreanName: '장바구니',
            },
            /* 스토어홈 */
            '/furniture': {
                eventName: 'PageView',
                pageKoreanName: '스토어홈',
            },
            /* 프로모션홈 */
            '/home/promotion': {
                eventName: 'PageView',
                pageKoreanName: '프로모션홈',
            },
        };

        const pageViewEvent = PageViewNames[router.route];
        if (pageViewEvent) {
            sendAmplitudeEvent(amplitudeInstance, 'PageView', {
                Url: router.asPath,
                Referrer: Referrer || window.location.href,
                Page: pageViewEvent.pageKoreanName,
                PageObject: amplitudeData,
                FullAgent: navigator.userAgent,
            });
        }
    } catch (_e) {}
}

function sendAmplitudeEvent(
    amplitudeInstance: AmplitudeClient,
    eventName: string,
    eventObject: any,
) {
    amplitudeInstance.logEvent(eventName, eventObject);
}

const pushToDataLayer = (data: CustomEventData) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
};

const BackCheck = () => {
    const router = useRouter();
    useEffect(() => {
        sessionStorage.getItem('isBackMove') === 'true' &&
            sessionStorage.setItem('isBackMove', 'false');
        router.beforePopState(() => {
            sessionStorage.setItem('isBackMove', 'true');
            return true;
        });
    }, [router]);
    return null;
};

function App({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const referer = useSelector((state: AppState) => state.common.referer);
    const memberIdx = useSelector((state: AppState) => state.common.member.idx);
    const member = useSelector((state: AppState) => state.common.member);

    const amplitudeData = useSelector((state: AppState) => {
        switch (router.pathname) {
            case '/furniture/view/[idx]':
                return state.furniture.amplitudeData;
            default:
                return null;
        }
    });

    typeof window !== 'undefined' &&
        useLayoutEffect(() => {
            if (typeof window !== 'object') {
                return;
            }

            const amplitudeInstance = Amplitude.getInstance();
            amplitudeInstance.init(AMPLITUDE_API_KEY, member?.idx.toString() || 'null', {
                includeUtm: true,
                includeReferrer: true,
                saveParamsReferrerOncePerSession: true,
                includeGclid: true,
            });

            const cookies = parseCookies();
            const isSignin = cookies[`is_login`];
            const isSignup = cookies[`is_signup`];
            destroyCookie(null, 'is_login');
            destroyCookie(null, 'is_signup');

            amplitudeInstance.identify(getAmplitudeIdentify(member));
            logAmplitudePageView(amplitudeInstance, router, referer, amplitudeData);

            if (isSignin || isSignup) {
                if (isSignin) {
                    Amplitude.getInstance().logEvent('Sign-in', {
                        Type: member.accountType
                            .toLowerCase()
                            .replace(/\b[a-z]/g, (char) => char.toUpperCase()),
                    });
                } else if (isSignup) {
                    Amplitude.getInstance().logEvent('Sign-up', {
                        Type: member.accountType
                            .toLowerCase()
                            .replace(/\b[a-z]/g, (char) => char.toUpperCase()),
                    });
                }
            }
        }, [router.pathname]);

    typeof window !== 'undefined' &&
        useLayoutEffect(() => {
            if (typeof window !== 'object') {
                return;
            }
            pushToDataLayer({
                user_id: member?.idx || '',
            });
        }, [member]);

    return (
        <GlobalModalProvider>
            <Head>
                <title>인테리어의 시작, 집꾸미기</title>
                <meta name="viewport" content="width=1000" />
                <script
                    dangerouslySetInnerHTML={{ __html: `window.memberIdx = ${memberIdx || "''"};` }}
                />
            </Head>
            <GlobalStyle />
            <Fonts />
            <ThemeProvider theme={theme}>
                <Header />
                <Col position={'relative'}>
                    <Component {...pageProps} />
                    <FloatingButton />
                </Col>
                <Footer />
                <BackCheck />
            </ThemeProvider>
        </GlobalModalProvider>
    );
}

App.getInitialProps = wrapper.getInitialAppProps(
    (store) =>
        async ({ Component, ctx }: AppContext) => {
            let pageProps = {};
            const { req } = ctx;

            const cookies = nookies.get(ctx);

            if (req) {
                // 클라이언트 사이드
                const phpSessionId =
                    cookies['PHPSESSID'] === undefined ? null : cookies['PHPSESSID'];
                store.dispatch(commonActions.setPhpSessionId(phpSessionId));

                //추가
                const authToken =
                    cookies['AUTH-TOKEN'] === undefined ? null : cookies['AUTH-TOKEN'];
                store.dispatch(commonActions.setAuthToken(authToken));
            }

            // ToDo: state.story.isMobile 쓰는 곳 리팩토링 필요
            store.dispatch(storyActions.setType('pc'));

            const userInfo = await getUserInfo();

            if (
                store.getState().common.phpSessionId !== null &&
                store.getState().common.authToken !== null &&
                userInfo
            ) {
                store.dispatch(commonActions.setMember(userInfo));
            }

            // 비회원인경우
            if (!userInfo) {
                let nonmemberToken = cookies[CookieKeys.nonMemberToken];
                if (!nonmemberToken) {
                    // 쿠키에 없으면 새로 발급받은 후 쿠키 및 스토어에 저장
                    const nonmemberTokenRes = await getNonMemberToken();
                    if (nonmemberTokenRes) {
                        nonmemberToken = nonmemberTokenRes.key;
                        setCookie(ctx, CookieKeys.nonMemberToken, nonmemberToken, {
                            maxAge: 90 * 24 * 60 * 60, //90일 동안 저장
                            path: '/',
                        });
                    }
                }
                store.dispatch(commonActions.setNonmemberToken(nonmemberToken));
            } else {
                destroyCookie(ctx, CookieKeys.nonMemberToken, {
                    path: '/',
                });
                store.dispatch(commonActions.setNonmemberToken(''));

                setCookie(ctx, CookieKeys.accountType, userInfo.accountType, {
                    path: '/',
                });
            }

            const categoryTree = await getCategoryTree();
            store.dispatch(commonActions.setCategoryTree(categoryTree));

            if (Component.getInitialProps) {
                pageProps = await Component.getInitialProps(ctx);
            }

            return {
                pageProps,
            };
        },
);

export default wrapper.withRedux(App);
