import { createGlobalStyle } from 'styled-components';
import {
    $BASE_COLOR,
    $BASE_FONT_SIZE,
    $BASE_LETTER_SPACING,
    $BASE_LINE_HEIGHT,
    $FONT_SANS,
} from '../values';
import COLOR from './colors';

export default createGlobalStyle`
html, body {
  line-height: 1.3em;
  -webkit-tap-highlight-color: transparent;
}
  
// font
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, main, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {margin:0; padding:0; border:0; word-break: keep-all; outline: none;}

//
//
//
//
//
* {
  box-sizing : border-box;
}
ol,
ul,
li,
dl{
  list-style: none;
}


hr{
    width:100%;
    height:1px;
    margin:0;
    padding:0;
    border:none;
    background-color: #000000;
}
em{
    font-style: normal;
}
mark{
    background-color: transparent;
    font-style: normal;
}
button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-tap-highlight-color: transparent;
}
button {
    border:none;
    margin:0;
    padding:0;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;    
}
button:focus {
    outline: none;
}

object{
    width:100%;
    vertical-align:top;
}

html {
    font-family: ${$FONT_SANS} !important;
    font-size: ${$BASE_FONT_SIZE}px;
    line-height: ${$BASE_LINE_HEIGHT};
    letter-spacing:${$BASE_LETTER_SPACING}px;
    -webkit-font-smoothing: antialiased;

    overflow-y: scroll;
}
body {
    font-family: ${$FONT_SANS} !important;
    background-color:#fff;
    color:${$BASE_COLOR};
    font-weight:400;
}

:lang(ko){ font-family: ${$FONT_SANS}; }
:lang(en){ font-family: ${$FONT_SANS}; }

//
//
//
//
//
//
a{
    color:inherit !important;
    text-decoration:none !important;
}

a:hover{ 
    text-decoration: none;
}
//
//
//
//
//

img{
    max-width:100%;
    border: none;
}
//
//
//
//
//

table{
    border-spacing: 0;
    border-collapse: collapse;
}
caption{font:0/0 a;}
th,
td{}
th{
    font-weight:400;
    text-align:left;
}
td{}

select:focus,
a:focus {
    outline: 0 none; 
}
input:focus, 
textarea:focus { 
//  box-shadow: 0 0 0px 1px #dd;
outline: 0 none; 
}

//
//
//
//
//

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {height:auto;-webkit-appearance:none; margin:0;}
input[type='search'] {-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}
input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {-webkit-appearance:none;}
input[type="text"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="date"],
input[type="number"],
input[type="email"]{
  box-sizing:border-box;
  width:100%;
  margin:0;
  height:40px; 
  font-size: 14px; 
  padding: 0 10px; 
  border: 1px solid #E4E1DE;
    border-radius: 4px;
}

input[type="text"], textarea{

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}
textarea {
  box-sizing:border-box;
  width:100%;
  padding: 10px;
  border: 1px solid #E4E1DE;
    border-radius: 4px;
    height: 155px;
    font-size: 14px;
    resize: none;
}

  [contenteditable=true]:empty:before {
            content: attr(placeholder);
            display: block;
            color: #C6C6C6;
            }

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #C6C6C6;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #C6C6C6;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #C6C6C6;
}

.ReactModalPortal .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}

@keyframes modalSlide {
  from {
    bottom: -100%;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

 .tag-info-tooltip{
       box-shadow: 3px 3px 8px 0 rgba(0,0,0,0.18);
    color: #2F2F2F !important;
    background-color: #FFFFFF !important;
    border-radius: 8px;
    padding: 8px 12px;
    max-width: 164px;
  }
 .tag-info-tooltip.free{
  width: 255px;
 
 }
  .tag-info-tooltip.place-bottom:before{
    border-bottom: 10px solid #FFF !important;;
    border-left: 10px solid transparent !important;
    border-right: 0 none !important;
    top: -8px;
    left: 50%;
    margin-left: -10px;
   
  }
  .tag-info-tooltip.place-bottom:after{
    display: none;
  };
  
  .tag-info-tooltip.place-right:before{
    border-right: 10px solid #FFF !important;;
    border-bottom: 10px solid transparent !important;
    border-top: 0 none !important;
  }
  .tag-info-tooltip.place-right:after{
    display: none;
  };
  
  .tag-info-tooltip.place-top:before{
    border-top: 10px solid #FFF !important;;
    border-left: 10px solid transparent !important;
    border-right: 0 none !important;
  }
  .tag-info-tooltip.place-top:after{
    display: none;
  };
   .tag-info-tooltip.place-left:before{
    border-left: 10px solid #FFF !important;;
    border-bottom: 10px solid transparent !important;
    border-top: 0 none !important;
  }
  .tag-info-tooltip.place-left:after{
    display: none;
  };

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-disabled{
  display: none !important; 
}
.slick-arrow{
  z-index: 1;
  position: absolute;
  top: 0; 
  font-size: 0;
}
.slick-prev{
  left: 0;
  width: 50px;
  height: 105px;
  background: -moz-linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,0))); /* safari4+,chrome */
  background: -webkit-linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* ie10+ */
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* ie6-9 */
}
.slick-next{
  right: 0;
  width: 50px;
  height: 105px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
}
.slick-list,
.slick-slider,
.slick-track {
    position: relative;
    display: block
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
    visibility: hidden
}

.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    top: 0;
    left: 0
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: ''
}

.slick-track:after {
    clear: both
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.bookmark-widget .bookmark-widget-list-anim-in {
    margin-bottom: 0px !important;
}

.navigation-bar .navigation-bar-container .navigation-right-button div.btn-brown {
    color: #F15657;
}

.react-primary-text {
    color: #F15657 !important;
}

.react-container {
    width: 100%;
    max-width: 620px;
    margin: auto;
    padding-bottom: calc(env(safe-area-inset-bottom) + 57px);
}

@supports (padding-bottom: constant(save-area-insert-bottom)) {
    .react-container {
        padding-top: constant(safe-area-inset-top);
        padding-bottom: constant(safe-area-inset-bottom);
    }
}

@supports (padding-bottom: env(save-area-insert-bottom)) {
    .react-container {
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
    }
}

.react-tag-primary-text {
    color: #F15657 !important;
    position: relative;
    z-index: 1;
    font-weight: 700;
}

body.ReactModal__Body--open {
    /* overflow: hidden; */
    -webkit-overflow-scrolling: none;
    overscroll-behavior: none;
}

.hashtag-text {
    color: ${COLOR.BLUE3};
}

/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); 
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); 
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); 
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); 
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px); 
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); 
  } 
  }

@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); 
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); 
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); 
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); 
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px); 
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); 
  } 
}
.hvr-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent; 
}
.hvr-wobble-vertical:hover, .hvr-wobble-vertical:focus, .hvr-wobble-vertical:active {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1; 
}
.scrollTopButton-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in-out;
}
.scrollTopButton-exit-active {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}

`;
