import React from 'react';
import { IconProps } from '.';

interface CheckSelectedIconProps extends IconProps {}

const CheckSelectedIcon = ({ color, width, height, style, ...args }: CheckSelectedIconProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <path
                d="M8.14675 0.460343C8.44488 0.0626186 9.00898 -0.018121 9.4067 0.280006C9.77383 0.555201 9.87087 1.05702 9.64914 1.44542L9.58704 1.53996L5.08955 7.53996C4.74074 8.00529 4.05975 8.01639 3.69174 7.5928L3.61791 7.49539L1.11541 3.69799C0.841893 3.28296 0.956622 2.72478 1.37166 2.45126C1.75477 2.19879 2.25985 2.27713 2.55041 2.61711L2.61839 2.70752L4.41661 5.43615L8.14675 0.460343Z"
                fill="white"
            />
        </svg>
    );
};

export default CheckSelectedIcon;
