import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { api } from '../lib/request';
import { searchApi } from '../lib/request';
import { IProduct } from './commerce';

export interface IPopularKeyword {
    color: string;
    created_datetime: string;
    hit: number;
    icon_img: boolean;
    idx: number;
    keyword: string;
    rank: number;
    rank_before: number;
    rk: number;
    theday: null;
    up_down_rank: number | null;
    up_down_text?: string;
}
export interface IRecentKeyword {
    idx: number;
    member_idx: number;
    search_text: string;
    search_type: number;
}
export interface ISearchPopupKeywordsData {
    popular_keyword: IPopularKeyword[];
    recent_keyword: IRecentKeyword[];
}
export interface ISearchPopupKeywordsResponse {
    code: number;
    data: ISearchPopupKeywordsData;
}

export async function getSearchPopupKeywords(
    req?: AxiosRequestConfig,
): Promise<ISearchPopupKeywordsData | null> {
    try {
        const response: AxiosResponse<ISearchPopupKeywordsResponse | null> = await api.get(
            '/search/getSearchPopupKeywords',
            {
                params: req?.params,
            },
        );

        if (response.data && response.data.code === 200) {
            return response.data?.data;
        }

        return null;
    } catch (e) {
        return null;
    }
}

export async function removeRecentKeyword(req?: AxiosRequestConfig): Promise<boolean> {
    try {
        const response: AxiosResponse<{ code: string; data: string } | null> = await api.post(
            '/member/removeRecentKeyword',
            req?.data,
        );

        return response.data?.code === '200' || false;
    } catch (e) {
        return false;
    }
}

export type TRecentKeyword = {
    idx: number;
    searchText: string;
    searchType: number;
    memberIdx: number;
};

export type TPopularKeyword = {
    keyword: string;
    rankBefore: number;
    rank: number;
    hit: number;
    theday: null;
    createdDatetime: string;
    color: string;
    iconImg: boolean;
    iconImgUrl: string;
    upDownRank: null | number;
    upDownText: null | string;
};

export interface ISearchKeywordResponse {
    recentKeywords: TRecentKeyword[];
    popularKeywords: TPopularKeyword[];
}

export async function getKeywordSearch(req: AxiosRequestConfig) {
    try {
        const response: AxiosResponse<ISearchKeywordResponse> = await searchApi.get(
            `/search/v1/keyword`,
            {
                params: req?.params,
            },
        );
        return response.data;
    } catch (e) {
        return null;
    }
}

export async function deleteRecentKeyword(req: AxiosRequestConfig) {
    try {
        const response: AxiosResponse = await searchApi.delete('/search/v1/keyword', req);
        return response.data;
    } catch (e) {
        return false;
    }
}

export type TSearchCategory = {
    idx: number;
    depth1Idx: number;
    depth2Idx: number;
    depth3Idx: number;
    depth4Idx: number;
    depth1Name: string;
    depth2Name: string;
    depth3Name: string;
    depth4Name: string;
};

export type TSearchSuggestion = {
    type: number;
    subType: number;
    suggestion: string;
};

export interface ISearchSuggestResponse {
    categories: TSearchCategory[];
    suggestions: TSearchSuggestion[];
}

export async function getSearchSuggest(req: AxiosRequestConfig) {
    try {
        const response: AxiosResponse<ISearchSuggestResponse> = await searchApi.get(
            '/search/v1/suggest',
            {
                params: req?.params,
            },
        );
        return response.data;
    } catch (e) {
        return null;
    }
}

export const DEFAULT_SEARCH_PRODUCT_SIZE = 20;
export const DEFAULT_SORT_VALUE = 'Popular';

interface SearchResultByTypeParams {
    searchType: string;
    searchText: string | number;
    offset?: number;
    size?: number;
    useCategoryNavigation?: boolean;
    navigationCategoryIdx?: number;
    minPrice?: number;
    maxPrice?: number;
    categoryFilter?: number[];
    brands?: number[];
    freeDelivery?: boolean;
    hopeDelivery?: boolean;
    sort?: string;
    memberIdx?: number;
}

export interface ProductResultResponse {
    totalCount: number;
    minPrice: number;
    maxPrice: number;
    brands: Brand[];
    consonants: Consonant[];
    categories: SearchResultCategory[];
    products: Product[];
    currentCategory: CurrentCategory | null;
    currentBrands: CurrentBrands[];
}

export interface ArticleResultResponse {
    totalCount: number;
    articles: ArticleType[];
}

export interface PhotoResultResponse {
    totalCount: number;
    photos: PhotoType[];
}

export interface Brand {
    idx: number;
    name: string;
    count: number;
    consonant: string;
    active: boolean;
}

export interface Category {
    idx: number;
    name: string;
    count: number;
    parentIdx: number;
    hasChildren: boolean;
}

export interface Consonant {
    consonant: string;
    brands: Brand[];
}

export interface Product extends IProduct {
    bookmarkCount: number;
    brandIdx: number;
    brandName: string;
    createdDateTime: string;
    deliveryCondition: string;
    deliveryPayment: number;
    deliveryPrice: number;
    deliveryType: number;
    description: string;
    dimensionDepth: number;
    dimensionHeight: number;
    dimensionRadius: number;
    dimensionWidth: number;
    itemType: number;
    lastedAddedDateTime: string;
    outsideLink: string;
    reviewScore: number;
    selectedDeliveryPresetIdx: number;
    shareCount: number;
    sortIdx: number;
    timesaleEndTime: string | null;
    type: number;
    unitCount: number;
    unitCountType: number;
    useDescriptionOnly: number;
    visibility: number;
    promotionTagList: string[];
    unit: Unit[] | null;
    partnerIdx: number;
    partnerName: string;
}

export type CurrentCategory = Omit<Category, 'hasChildren'>;

export interface SearchResultCategory extends CurrentCategory {
    hasChildren: boolean;
}

export interface CurrentBrands {
    idx: number;
    name: string;
}

export interface ArticleType {
    authorName: string;
    averagePriceForFurniture: number;
    averagePriceForInterior: number;
    bannerList: {
        idx: number;
        filename: string;
        content: string;
        sortIdx: number;
    }[];
    bookmarkCount: number;
    commentCount: number;
    description: string;
    dewy: string[];
    filename: string;
    idx: number;
    itemType: number;
    livingstatus: number;
    releaseDateTime: string;
    shareCount: number;
    sortIdx: number;
    space: number;
    style: number;
    title: string;
    titleShort: string;
    visibility: number;
}

export interface PhotoType {
    itemType: number;
    type: string;
    idx: number;
    starIdx: number;
    storyIdx: number;
    bookmarkCount: number;
    commentCount: number;
    location: number;
    shareCount: number;
    showFurniture: number;
    visibility: number;
    filename: string;
}

export interface Unit {
    count: number;
    count_type: number;
    furniture_idx: number;
    idx: number;
    is_discontinued: number;
    is_package: string;
    name: string;
    price: number;
    visibility: number;
}

export interface ProductTagList {
    type: string;
    tag: string;
    color: string;
    backgroundColor: string;
}

export interface RelatedKeywordsRespose {
    result: number;
    message: string;
    data: {
        count: number;
        id: string;
        item: string;
        keywords: string[];
    };
}

export const getSearchResultByType = async (
    req: AxiosRequestConfig & {
        params: SearchResultByTypeParams;
    },
) => {
    try {
        const response: AxiosResponse<ProductResultResponse> = await searchApi.get(
            `/search/v1/product/type/${req.params.searchType}`,
            req,
        );

        const { data } = response;

        return data;
    } catch (e) {
        return null;
    }
};

export const getSearchResultCountByType = async (
    req: AxiosRequestConfig & {
        params: SearchResultByTypeParams;
    },
) => {
    try {
        const response: AxiosResponse<ProductResultResponse> = await searchApi.get(
            `/search/v1/product/type/${req.params.searchType}/count`,
            req,
        );
        const { data } = response;
        return data;
    } catch (e) {
        return null;
    }
};

export async function getArticleResultList(
    req: AxiosRequestConfig & { params: { keyword: string; offset: number; size: number } },
) {
    try {
        const response: AxiosResponse<ArticleResultResponse> = await searchApi.get(
            `/search/v1/article/keyword`,
            req,
        );
        const { data } = response;

        return data;
    } catch (e) {
        return null;
    }
}

export async function getPhotoResultList(
    req: AxiosRequestConfig & { params: { keyword: string; offset: number; size: number } },
) {
    try {
        const response: AxiosResponse<PhotoResultResponse> = await searchApi.get(
            `/search/v1/content/photo/keyword`,
            req,
        );
        const { data } = response;

        return data;
    } catch (e) {
        return null;
    }
}

export async function getRelatedKeywords(
    req: AxiosRequestConfig & { params: { keyword: string } },
) {
    try {
        const response: AxiosResponse<RelatedKeywordsRespose> = await searchApi.get(
            `/search/v1/keyword/related`,
            req,
        );
        const { status, data } = response;
        if (status === 200) {
            return data;
        } else {
            throw '데이터를 불러오는데 실패하였습니다.';
        }
    } catch (error) {
        return null;
    }
}

export async function getProductListByCategory(req: AxiosRequestConfig & { params: any }) {
    try {
        const response: AxiosResponse<ProductResultResponse> = await searchApi.get(
            `/search/v1/product/category`,
            req,
        );
        return response.data;
    } catch (error) {
        return null;
    }
}

export async function getRecentView({
    pageNumber = 0,
    pageSize = 10,
}: {
    pageNumber?: number;
    pageSize?: number;
}) {
    try {
        const response: AxiosResponse = await searchApi.get('/search/v1/recentView/product', {
            params: {
                pageNumber,
                pageSize,
            },
        });
        return response.data.products;
    } catch (e) {
        return false;
    }
}

export async function getProductData(productIdx: number[]) {
    try {
        const response: AxiosResponse<{
            totalCount: number;
            products: Product[];
        }> = await searchApi.get(`/search/v1/product`, {
            params: { productIdxList: productIdx },
        });

        return response.data.products;
    } catch (e) {
        return [];
    }
}

export type TBrandCategory = {
    idx: number;
    depth: number;
    name: string;
    children: { [key: number]: TBrandCategory } | null;
};

interface IBrandCategoriesRes {
    categories: TBrandCategory[];
}

export async function getBrandCategories(brandIdx: number) {
    try {
        const response: AxiosResponse<IBrandCategoriesRes> = await searchApi.get(
            `/search/v1/product/brand/${brandIdx}`,
        );
        return response.data.categories;
    } catch (e) {
        return [];
    }
}
