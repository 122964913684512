import styled from 'styled-components';
import { GridBox } from './GridBox';
import { Col, Row } from './FlexBox';
import { COLOR_V4 } from '../../lib/styles/colors';

interface IStarListProps {
    reviewScore?: number;
    width?: number | string;
    backgroundColor?: string;
    selectedBackgroundColor?: string;
}

interface IStarRatingWrapperProps {
    width: number | string;
    margin?: string;
}

interface IStarIconProps {
    width?: string | number;
    fill: string | undefined;
}

const StarRatingWrapper = styled(Row)<IStarRatingWrapperProps>`
    align-items: center;
    justify-content: center;
    position: relative;
    width: max-content;

    & span {
        margin: 0 ${(props) => props.margin} 0 0;
    }

    & > .star-ratings-fill {
        position: absolute;
        display: flex;
        width: ${(props) => props.width}%;

        z-index: 1;
        top: 0;
        left: 0;
        overflow: hidden;
    }

    & > .star-ratings-base {
        z-index: 0;
    }
`;

export const StarIcon = (props: IStarIconProps) => {
    const { width, fill } = props;

    return (
        <svg
            width={width}
            height={width}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0044 16.1035L5.96933 18.2162C5.23906 18.5986 4.38367 17.9917 4.50051 17.1741L5.17441 12.4585L1.93605 8.94335C1.3873 8.34769 1.72374 7.37928 2.52287 7.25426L6.9741 6.55785L9.09379 2.2333C9.46356 1.4789 10.5359 1.47762 10.9075 2.23113L13.0411 6.55785L17.4776 7.25398C18.276 7.37924 18.6124 8.34642 18.0648 8.94217L14.8326 12.4585L15.4995 17.1752C15.6151 17.9927 14.7594 18.5983 14.0297 18.2154L10.0044 16.1035Z"
                fill={fill}
            />
        </svg>
    );
};

export default function StarList(props: IStarListProps) {
    const { width, backgroundColor, selectedBackgroundColor, reviewScore } = props;

    const wrapperWidth = (Number(reviewScore) * 20).toFixed(1);
    const tempList = [1, 2, 3, 4, 5];

    return (
        <StarRatingWrapper width={wrapperWidth}>
            <GridBox className={'star-ratings-fill'} templateColumns={'repeat(5, 1fr)'} gap={'0px'}>
                {tempList.map((keyName: number) => {
                    return (
                        <Col key={`star-rating-icon-${keyName}`} align={'center'}>
                            <StarIcon
                                width={width ? width : '10px'}
                                fill={selectedBackgroundColor ? selectedBackgroundColor : '#ffc800'}
                            />
                        </Col>
                    );
                })}
            </GridBox>
            <GridBox templateColumns={'repeat(5, 1fr)'} gap={'0px'}>
                {tempList.map((keyName: number) => {
                    return (
                        <Col key={`star-rating-icon-${keyName}`} align={'center'}>
                            <StarIcon
                                width={width ? width : '10px'}
                                fill={backgroundColor ? backgroundColor : COLOR_V4.GRAY200}
                            />
                        </Col>
                    );
                })}
            </GridBox>
        </StarRatingWrapper>
    );
}
