import React from 'react';
import { IconProps } from '.';

interface DeleteProps extends IconProps {}

const Delete = ({ color, width, height, style, ...args }: DeleteProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <path
                d="M5.19825 18.9225C4.96895 19.1702 4.97021 19.5704 5.20107 19.8164C5.43193 20.0624 5.80496 20.061 6.03427 19.8134L12.8559 12.4454C13.0842 12.1989 13.0842 11.8011 12.8559 11.5546L6.03427 4.18664C5.80496 3.93897 5.43193 3.93762 5.20107 4.18361C4.97021 4.4296 4.96895 4.82979 5.19825 5.07745L11.6075 12L5.19825 18.9225Z"
                fill="#C5C8CE"
                stroke="#C5C8CE"
                strokeWidth="0.5"
            />
            <path
                d="M13.3926 12L19.802 5.07748C20.0313 4.82983 20.03 4.42964 19.7992 4.18364C19.5684 3.93763 19.1954 3.93896 18.9661 4.18661L12.1443 11.5546C11.9161 11.8011 11.9161 12.1989 12.1443 12.4454L18.9661 19.8134C19.1954 20.061 19.5684 20.0624 19.7992 19.8164C20.03 19.5704 20.0313 19.1702 19.802 18.9225L13.3926 12Z"
                fill="#C5C8CE"
                stroke="#C5C8CE"
                strokeWidth="0.5"
            />
        </svg>
    );
};

export default Delete;
