import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export interface IMember {
    idx: number | string;
    name: string;
    nickname: string;
    recentProfile: string;
    recentShout: string;
    recentCover: string;
    website: string;
    email: string;
    gradeIdx: number;
    specialGradeIdx: number;
    gradeName: string;
    point: number;
    accountType: string;
    phoneNumber: string;
    checkTerm: number;
    isConfirm: number;
    customsCode: string;
    pointEarningRatio: number;
    discountRatio: number;
    couponCount: number;
    notiCount: number;
}
export interface ICategoryItem {
    idx: number;
    value: string;
    filename: string | null;
    image_url: string | null;
    visibility: number;
    depth: number;
    sub: ICategoryItem[];
}
export interface ICategoryTree {
    sub: ICategoryItem[];
}
export interface ICommonState {
    member: IMember;
    referer: string;
    userAgent: string;
    categoryTree: ICategoryTree | null;
    phpSessionId: string;
    authToken: string; // 추가
    nonmemberToken: string;
}

const initialState: ICommonState = {
    member: {
        idx: '',
        name: '',
        nickname: '',
        recentProfile: '',
        recentShout: '',
        recentCover: '',
        website: '',
        email: '',
        gradeIdx: 0,
        specialGradeIdx: 0,
        gradeName: '',
        point: 0,
        accountType: '',
        phoneNumber: '',
        checkTerm: 0,
        isConfirm: 0,
        customsCode: '',
        pointEarningRatio: 0,
        discountRatio: 0,
        couponCount: 0,
        notiCount: 0,
    },
    referer: '',
    userAgent: '',
    categoryTree: null,
    phpSessionId: '',
    authToken: '',
    nonmemberToken: '',
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setMember: (state: ICommonState, action) => {
            state.member = action.payload;
        },
        setReferer: (state: ICommonState, action) => {
            state.referer = action.payload;
        },
        setUserAgent: (state: ICommonState, action) => {
            state.userAgent = action.payload;
        },
        setCategoryTree: (state: ICommonState, action) => {
            state.categoryTree = action.payload;
        },
        setPhpSessionId: (state: ICommonState, action) => {
            state.phpSessionId = action.payload;
        },
        setAuthToken: (state: ICommonState, action) => {
            state.authToken = action.payload;
        },
        setNonmemberToken: (state: ICommonState, action) => {
            state.nonmemberToken = action.payload;
        },
    },
    extraReducers: {
        [HYDRATE]: (state: ICommonState, action) => {
            return {
                ...state,
                ...action.payload.common,
                phpSessionId: '', // 클라이언트 사이드에 넘기지 않음
            };
        },
    },
});

export const commonActions = commonSlice.actions;
export default commonSlice;
