import React from 'react';
import styled from 'styled-components';
import { Row, Button, Body4, Body2 } from '../layout';
import { Spread } from '../../lib/Icons';
import { COLOR_V4 } from '../../lib/styles/colors';

interface SelectRootProps {
    children?: React.ReactNode | React.ReactNode[];
    buttonText: string;
    open?: boolean;
    margin?: string;
    onClick: (event?: any) => void;
}

const SelectRootStyle = styled.div`
    position: relative;
`;

const Options = styled.div<{ open: boolean }>`
    display: ${(props) => (props.open ? 'block' : 'none')};
    position: absolute;

    width: 92px;
    max-height: 221px;
    box-sizing: border-box;
    border: 1px solid ${COLOR_V4.GRAY200};
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    left: 50%;
    transform: translate(-50%);

    margin: 3px 0 0;
    list-style: none;
    background: #fff;

    overflow: hidden;
    z-index: 100;
`;

const SelectRoot = React.forwardRef<HTMLDivElement, SelectRootProps>((props, ref) => {
    const { children, buttonText, open = false, margin = '0 10px 0 0', onClick } = props;

    return (
        <SelectRootStyle ref={ref}>
            <Button border={`none`} margin={margin} onClick={onClick}>
                <Row align="center" justify="center">
                    <Body2 fontWeight={'medium'} margin={'0 4px 0 0'} color={COLOR_V4.GRAY900}>
                        {buttonText}
                    </Body2>
                    <Spread color={COLOR_V4.GRAY400} />
                </Row>
            </Button>
            <Options open={open}>{children}</Options>
        </SelectRootStyle>
    );
});

interface SelectOptionProps {
    children?: React.ReactNode | React.ReactNode[];
    selected: boolean;
    onClick: (event?: any) => void;
}

const SelectOptionStyle = styled.div`
    padding: 10px 11px;
    cursor: pointer;
    border-bottom: 1px solid ${COLOR_V4.GRAY200};
`;

const SelectOption = (props: SelectOptionProps) => {
    const { children, selected, ...rest } = props;
    return (
        <SelectOptionStyle {...rest}>
            <Row justify={'space-between'} align={'center'}>
                <Row>
                    <Body4 fontWeight={selected ? 'medium' : 'regular'} color={COLOR_V4.GRAY900}>
                        {children}
                    </Body4>
                </Row>
            </Row>
        </SelectOptionStyle>
    );
};

const Root = SelectRoot;
const Option = SelectOption;

export { Root, Option };
