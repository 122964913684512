import React from 'react';
import { IconProps } from '.';

interface ExclamationMarkProps extends IconProps {}

const ExclamationMark = ({ color, width, height, style, ...args }: ExclamationMarkProps) => {
    const styleObj = {
        width,
        height,
        ...style,
    };

    return (
        <svg
            width="4"
            height="24"
            viewBox="0 0 4 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={styleObj}
            {...args}
        >
            <path
                d="M0.546874 16.9062L0.546876 -4.76727e-07L3.45313 -2.22655e-07L3.45312 16.9062L0.546874 16.9062ZM3.67187 21.3906C3.67187 21.8594 3.53125 22.2552 3.25 22.5781C2.95833 22.901 2.53125 23.0625 1.96875 23.0625C1.41667 23.0625 0.99479 22.901 0.703124 22.5781C0.40104 22.2552 0.249999 21.8594 0.249999 21.3906C0.249999 20.9427 0.401041 20.5573 0.703124 20.2344C0.994791 19.9219 1.41667 19.7656 1.96875 19.7656C2.53125 19.7656 2.95833 19.9219 3.25 20.2344C3.53125 20.5573 3.67187 20.9427 3.67187 21.3906Z"
                fill={color}
            />
        </svg>
    );
};

export default ExclamationMark;
